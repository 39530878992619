import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { Box, Skeleton, Divider } from '@mui/material';
import BackButton from '@rdv-fo/components/common/BackButton';
import ROUTES from '@rdv-fo/common/routes';
import { RdvTab, RdvTabs } from '@rdv-fo/components/common/tabs/RdvTabs';
import { useEffect, useState } from 'react';
import { RDV_NEUTRAL_20 } from '@rdv-fo/styles/theme';
import { useAppDispatch, useAppSelector } from '@rdv-fo/store/configureStore';
import { selectPlatformDisplayName, selectStripeIntegration } from '@rdv-fo/store/slices/platformSlice';
import {
	linkParticipantAccountToStripeConnectAccount,
	selectConnectToStripeAccountStatus,
	selectCurrentUser,
} from '@rdv-fo/store/slices/authSlice';
import { LoadingButtonAdvance } from '@rdv-fo/components/common/LoadingButton';
import { selectMySupplyTypes } from '@rdv-fo/store/slices/supplySlice';
import {
	loadMyPayments,
	loadMyTransfers,
	selectLoading,
	selectPayments,
	selectTransfers,
	requestPayout,
	requestStripeDashboardLink,
	selectRequestingMerchantAccountExternalUrl,
	selectPaymentsPageInfo,
	selectTransfersPageInfo,
} from '@rdv-fo/store/slices/paymentSlice';
import TransfersTable from '@rdv-fo/components/transfers/TransfersTable';
import TransferDetailsDrawer from '@rdv-fo/components/transfers/TransferDetailsDrawer';
import PaymentsTable from '@rdv-fo/components/payments/PaymentsTable';
import { MerchantAccount, IntegrationProviders, } from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { DEFAULT_PAGE_SIZE, PaginationOperationKind } from '@rdv-fo/app/lib/tableHelpers';

const MyPaymentsPayoutsPage = () => {
	const dispatch = useAppDispatch();

	const currentUserSupplyType = useAppSelector(selectMySupplyTypes);
	const currentUser = useAppSelector(selectCurrentUser) as any;
	// FIXME: THIS EVALUATION SHOULD HAPPEN ONCE ON PLATFORM INIT
	const isProvider = currentUserSupplyType?.length >= 1;
	const transfers = useAppSelector(selectTransfers);
	const payments = useAppSelector(selectPayments);
	const paymentsPageInfo = useAppSelector(selectPaymentsPageInfo);
	const transfersPageInfo = useAppSelector(selectTransfersPageInfo);
	const loading = useAppSelector(selectLoading);
	const requestingMerchantAccountExternalUrl = useAppSelector(selectRequestingMerchantAccountExternalUrl);

	const [isTransferDetailsDrawerOpen, setTransferDetailsDrawerOpen] = useState<boolean>(false);
	const [selectedTransferId, setSelectedTransfer] = useState<string | null>(null);

	const [selectedTab, setSelectedTab] = useState<'payments' | 'transfers'>('payments');



	const transfersPageStartCursor = transfersPageInfo?.start_cursor ?? undefined;
	const transfersPageEndCursor = transfersPageInfo?.end_cursor ?? undefined;

	const [currentTransfersPageIndex, setCurrentTransfersPageIndex] = useState<number>(0);
	const [paginationCursorTransfers, setPaginationCursorTransfers] = useState<string | undefined>(undefined);
	const [paginationOperationTransfers, setPaginationOperationTransfers] = useState<PaginationOperationKind>(
		PaginationOperationKind.after
	);

	const handleNextTransfersPage = () => {
		setPaginationCursorTransfers(transfersPageEndCursor);
		setPaginationOperationTransfers(PaginationOperationKind.after);
		setCurrentTransfersPageIndex((previous) => previous + 1);
	};
	const handlePreviousTransfersPage = () => {
		setPaginationCursorTransfers(transfersPageStartCursor);
		setPaginationOperationTransfers(PaginationOperationKind.before);
		setCurrentTransfersPageIndex((previous) => previous - 1);
	};


	const paymentsPageStartCursor = paymentsPageInfo?.start_cursor ?? undefined;
	const paymentsPageEndCursor = paymentsPageInfo?.end_cursor ?? undefined;

	const [currentPaymentsPageIndex, setCurrentPaymentsPageIndex] = useState<number>(0);
	const [paginationCursorPayments, setPaginationCursorPayments] = useState<string | undefined>(undefined);
	const [paginationOperationPayments, setPaginationOperationPayments] = useState<PaginationOperationKind>(
		PaginationOperationKind.after
	);



	const handleNextPaymentsPage = () => {
		setPaginationCursorPayments(paymentsPageEndCursor);
		setPaginationOperationPayments(PaginationOperationKind.after);
		setCurrentPaymentsPageIndex((previous) => previous + 1);
	};
	const handlePreviousPaymentsPage = () => {
		setPaginationCursorPayments(paymentsPageStartCursor);
		setPaginationOperationPayments(PaginationOperationKind.before);
		setCurrentPaymentsPageIndex((previous) => previous - 1);
	};



	const handleOpenTransferDrawer = (id: string | null) => {
		setSelectedTransfer(id);
		setTransferDetailsDrawerOpen(true);
	};
	const handleCloseTransferDrawer = () => {
		setSelectedTransfer(null);
		setTransferDetailsDrawerOpen(false);
	};

	const stripeIntegration = useAppSelector(selectStripeIntegration);
	const connectingToStripe = useAppSelector(selectConnectToStripeAccountStatus);

	const platformDisplayName = useAppSelector(selectPlatformDisplayName);
	const currentTransfer = transfers?.find((transfer) => transfer.id === selectedTransferId);


	useEffect(() => {
		if (isProvider) setSelectedTab('transfers');
		else setSelectedTab('payments');
	}, [isProvider]);

	useEffect(() => {
		if (selectedTab === 'transfers') dispatch(loadMyTransfers({
			...(paginationOperationTransfers == PaginationOperationKind.before && {
				before: paginationCursorTransfers,
				last: DEFAULT_PAGE_SIZE,
			}),
			...(paginationOperationTransfers == PaginationOperationKind.after && {
				after: paginationCursorTransfers,
				first: DEFAULT_PAGE_SIZE,
			}),
		}));
		else if (selectedTab === 'payments') dispatch(loadMyPayments({
			...(paginationOperationPayments == PaginationOperationKind.before && {
				before: paginationCursorPayments,
				last: DEFAULT_PAGE_SIZE,
			}),
			...(paginationOperationPayments == PaginationOperationKind.after && {
				after: paginationCursorPayments,
				first: DEFAULT_PAGE_SIZE,
			}),
		}));
	}, [selectedTab, dispatch, paginationCursorPayments, paginationOperationPayments, paginationCursorTransfers, paginationOperationTransfers]);

	const loadingPayments = loading;
	const paymentsFound = payments?.length;
	const loadingTransfers = loading;
	const transfersFound = transfers?.length;

	const isStripeIntegrationEnabled = stripeIntegration?.is_active;

	const stripeMerchantAccount: MerchantAccount | undefined = currentUser?.account?.merchant_accounts?.find(
		(mac: MerchantAccount) => mac.payment_provider_kind === IntegrationProviders.Stripe
	);

	const requiresFurtherStripeOnboarding =
		isStripeIntegrationEnabled && isProvider && stripeMerchantAccount?.data?.details_required;
	const merchantAccountAtStripeOpened =
		isStripeIntegrationEnabled && isProvider && stripeMerchantAccount?.data?.approved;

	const promptToOpenMerchantAccountAtStripe =
		isStripeIntegrationEnabled &&
		isProvider &&
		!merchantAccountAtStripeOpened &&
		!stripeMerchantAccount &&
		!requiresFurtherStripeOnboarding;

	const handleConnectToStripeConnect = async () => {
		console.log('Handle connect to Stripe Connect Account ..');
		const currentUrl = window.location.href;

		await dispatch(
			linkParticipantAccountToStripeConnectAccount({
				id_integration_provider: stripeIntegration?.id,
				refresh_url: currentUrl,
				return_url: currentUrl,
			})
		);
	};
	const handleRequestPayout = async (id_merchant_account: string) => {
		console.log('Handle request merchant account payout');

		await dispatch(
			requestPayout({
				id_merchant_account,
			})
		);
	};

	const handleGoToStripeDashboard = async (id_merchant_account: string) => {
		console.log('Handle go to Stripe dashboard');

		await dispatch(
			requestStripeDashboardLink({
				id_merchant_account,
			})
		);
	};

	return (
		<>
			<Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
				<BackButton label="Account" to={ROUTES.MY_ACCOUNT} />
				<Typography component="h1" variant="h1">
					Payments & Transfers
				</Typography>
			</Stack>
			{selectedTransferId && currentTransfer && (
				<TransferDetailsDrawer
					grossEarnings={currentTransfer?.order?.gross_value}
					netEarnings={currentTransfer?.order?.net_value}
					platformFees={currentTransfer?.order?.order_items}
					orderId={currentTransfer?.order?.id}
					id={selectedTransferId}
					status={currentTransfer.status}
					currency={currentTransfer?.order?.currency}
					open={isTransferDetailsDrawerOpen}
					onClose={handleCloseTransferDrawer}
				/>
			)}
			<Box sx={{ mt: 5 }}>
				<Box sx={{ mb: 5 }}>
					{promptToOpenMerchantAccountAtStripe && (
						<Alert
							severity="info"
							action={
								<LoadingButtonAdvance
									loading={connectingToStripe === 'LOADING'}
									disabled={connectingToStripe === 'LOADING'}
									color="inherit"
									size="small"
									variant="text"
									onClick={handleConnectToStripeConnect}
								>
									Link accounts
								</LoadingButtonAdvance>
							}
						>
							<AlertTitle>Please link your merchant account with Stripe</AlertTitle>
							For you to receive the money from our side, you must link your {platformDisplayName}{' '}
							merchant account with a merchant account at Stripe.
						</Alert>
					)}
					{requiresFurtherStripeOnboarding && (
						<Alert
							severity="error"
							action={
								<LoadingButtonAdvance
									loading={connectingToStripe === 'LOADING'}
									disabled={connectingToStripe === 'LOADING'}
									color="inherit"
									size="small"
									variant="text"
									onClick={handleConnectToStripeConnect}
								>
									Go to Stripe
								</LoadingButtonAdvance>
							}
						>
							<AlertTitle>Additional information required for your Stripe account</AlertTitle>
							Stripe cannot finish onboarding you because it requires more information from you. Please go
							to Stripe to wrap up connecting merchant account with your {platformDisplayName} account.
						</Alert>
					)}

					{merchantAccountAtStripeOpened && (
						<>
							<Typography variant="h4" sx={{ mb: 2 }}>
								Merchant account
							</Typography>
							<Stack
								direction={{ xs: 'column', md: 'row' }}
								spacing={2}
								width="100%"
								justifyContent="space-between"
								alignItems="center"
							>
								<Stack direction="column" width="100%">
									<Typography variant="body1">Stripe</Typography>
									<Typography variant="body2" color="textSecondary">{`${stripeMerchantAccount?.data?.email_connected_account ?? ''
										} ${stripeMerchantAccount?.id_psp_account}`}</Typography>
									<Typography variant="body2" color="textSecondary">
										{stripeMerchantAccount?.data?.name_connected_account ?? ''}
									</Typography>
								</Stack>

								<Stack
									direction="row"
									width="100%"
									spacing={2}
									justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
								>
									<LoadingButtonAdvance
										color="primary"
										size="small"
										variant="text"
										loading={stripeMerchantAccount?.id === requestingMerchantAccountExternalUrl}
										disabled={stripeMerchantAccount?.id === requestingMerchantAccountExternalUrl}
										onClick={() => handleGoToStripeDashboard(stripeMerchantAccount?.id ?? '')}
									>
										View account details
									</LoadingButtonAdvance>
									<LoadingButtonAdvance
										color="primary"
										size="small"
										variant="contained"
										loading={loading}
										onClick={() => handleRequestPayout(stripeMerchantAccount?.id ?? '')}
									>
										Request payout
									</LoadingButtonAdvance>
								</Stack>
							</Stack>
						</>
					)}
				</Box>
				<Stack direction="row">
					<RdvTabs value={selectedTab}>
						{(!isProvider || (payments?.length ?? 0) > 0) && (
							<RdvTab
								value="payments"
								label={'Payments'}
								onClick={() => {
									setSelectedTab('payments');
								}}
							/>
						)}
						{isProvider && (
							<RdvTab
								value="transfers"
								label={'Transfers'}
								onClick={() => {
									setSelectedTab('transfers');
								}}
							/>
						)}
					</RdvTabs>
				</Stack>
				<Divider sx={{ color: RDV_NEUTRAL_20, mb: 2 }} />

				{selectedTab === 'payments' && (
					<>
						{loadingPayments ? (
							<Stack direction="column" sx={{ pt: 4 }}>
								<Skeleton width="100%" height={50} />
								<Skeleton width="100%" height={50} />
								<Skeleton width="100%" height={50} />
								<Skeleton width="100%" height={50} />
							</Stack>
						) : (
							<>
								{paymentsFound ? (
									<PaymentsTable
										payments={payments}
										loading={false}
										disabled={false}
										onNextPage={handleNextPaymentsPage}
										onPreviousPage={handlePreviousPaymentsPage}
										hasNextPage={Boolean(paymentsPageInfo?.has_next_page)}
										hasPreviousPage={Boolean(paymentsPageInfo?.has_previous_page)}
									/>
								) : (
									<Stack
										direction="column"
										justifyContent="center"
										alignItems="center"
										spacing={2}
										sx={{ mt: 6 }}
									>
										<Typography variant="subtitle1">No payments yet</Typography>
										<Typography variant="body2">Your payments will appear hear</Typography>
									</Stack>
								)}
							</>
						)}
					</>
				)}
				{selectedTab === 'transfers' && (
					<>
						{loadingTransfers ? (
							<Stack direction="column" sx={{ pt: 4 }}>
								<Skeleton width="100%" height={50} />
								<Skeleton width="100%" height={50} />
								<Skeleton width="100%" height={50} />
								<Skeleton width="100%" height={50} />
							</Stack>
						) : (
							<>
								{transfersFound ? (
									<TransfersTable
										onOpenDrawer={handleOpenTransferDrawer}
										transfers={transfers}
										loading={loading}
										disabled={loading}
										onNextPage={handleNextTransfersPage}
										onPreviousPage={handlePreviousTransfersPage}
										hasNextPage={Boolean(transfersPageInfo?.has_next_page)}
										hasPreviousPage={Boolean(transfersPageInfo?.has_previous_page)}
									/>
								) : (
									<Stack
										direction="column"
										justifyContent="center"
										alignItems="center"
										spacing={2}
										sx={{ mt: 6 }}
									>
										<Typography variant="subtitle1">No transfers yet</Typography>
										<Typography variant="body2">
											Transfers to your merchant account will appear here
										</Typography>
									</Stack>
								)}
							</>
						)}
					</>
				)}
			</Box>
		</>
	);
};

export default MyPaymentsPayoutsPage;
