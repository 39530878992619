import { Divider, Paper, Typography, Stack, Skeleton } from '@mui/material';

import {
	CurrencyKind,
	CostItemKind,
	Chargeable,
	ChargeableItem,
} from '@rdv-fo/services/randevuApi/types/generatedTypes';
import { formatPriceAmount } from '../supply/supplyHelpers';

const calculateItemsTotal = (items: ChargeableItem[]) => {
	return items?.reduce(function (partial_sum, item) {
		if (item?.type === CostItemKind.PriceWithQty) return partial_sum + item.value;

		return partial_sum;
	}, 0);
};

const calculateTotalConsumerFees = (items: ChargeableItem[]) => {
	return items?.reduce(function (partial_sum, item) {
		if (item?.type === CostItemKind.PlatformFee) return partial_sum + item.value;

		return partial_sum;
	}, 0);
};

interface SummaryLineItemProps {
	label: string;
	price: any;
	loading: boolean;
}
const SummaryLineItem = ({ loading, label, price }: SummaryLineItemProps) => {
	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
			<Typography variant="subtitle2" sx={{ fontWeight: 550, fontSize: '0.875rem' }}>
				{label}
			</Typography>
			{!loading && <Typography sx={{ fontWeight: 550, fontSize: '0.875rem' }}>{price}</Typography>}
			{loading && <Skeleton width={80} height={20} />}
		</Stack>
	);
};

interface CheckoutSummaryProps {
	chargeable?: Chargeable;
	loading: boolean;
}
const CheckoutSummary = ({ loading, chargeable }: CheckoutSummaryProps) => {
	const paymentCurrency = chargeable?.currency ?? CurrencyKind.Eur;
	const chargeableItems = chargeable?.chargeable_items;

	const itemsTotal = calculateItemsTotal(chargeableItems ?? []);
	const formattedItemsTotal = formatPriceAmount({
		currency: paymentCurrency,
		amount: itemsTotal,
	});

	const consumerFeesTotal = calculateTotalConsumerFees(chargeableItems ?? []);
	const formattedConsumerFeesTotal = formatPriceAmount({
		currency: paymentCurrency,
		amount: consumerFeesTotal ?? 0,
	});

	const formattedTotalBeforeTax = formatPriceAmount({
		currency: paymentCurrency,
		amount: chargeable?.total_before_tax ?? 0,
	});
	const formattedTotalTaxes = formatPriceAmount({
		currency: paymentCurrency,
		amount: chargeable?.total_taxes ?? 0,
	});
	const formattedTotalAfterTax = formatPriceAmount({
		currency: paymentCurrency,
		amount: chargeable?.total_after_tax ?? 0,
	});
	const formattedTotalChargeable = formatPriceAmount({
		currency: paymentCurrency,
		amount: chargeable?.total_to_charge ?? 0,
	});

	return (
		<Paper sx={{ padding: 3, borderRadius: 8, border: '1px solid rgb(230, 232, 240)' }} elevation={0}>
			<Typography variant="h6" sx={{ fontWeight: 700, marginBottom: 3 }}>
				Summary
			</Typography>
			<SummaryLineItem loading={loading} label="Item(s) total" price={formattedItemsTotal} />
			{chargeableItems
				?.filter((item) => item.type === CostItemKind.AddOn)
				.map((item) => (
					<SummaryLineItem
						loading={loading}
						label={item.description}
						price={formatPriceAmount({
							currency: paymentCurrency,
							amount: item.value,
						})}
					/>
				))}
			{chargeableItems
				?.filter((item) => item.type === CostItemKind.Discount)
				.map((item) => (
					<SummaryLineItem
						loading={loading}
						label={item.description}
						price={
							'-' +
							formatPriceAmount({
								currency: paymentCurrency,
								amount: item.value,
							})
						}
					/>
				))}
			<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
			<SummaryLineItem loading={loading} label="Total before tax" price={formattedTotalBeforeTax} />
			{/** Missing Part is VAT -> huge difference in B2B vc B2C marketplaces */}
			<SummaryLineItem loading={loading} label="Taxes" price={formattedTotalTaxes} />
			{/** Missing Part is VAT -> huge difference in B2B vc B2C marketplaces */}
			<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
			<SummaryLineItem loading={loading} label="Total" price={formattedTotalAfterTax} />
			<SummaryLineItem loading={loading} label={'Fee(s)'} price={formattedConsumerFeesTotal} />
			<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
			<SummaryLineItem loading={loading} label="Adjusted total" price={formattedTotalChargeable} />
		</Paper>
	);
};

export default CheckoutSummary;
