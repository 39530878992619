export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CountryCode: any;
  Date: any;
  DateTimeISO: any;
  HttpURL: any;
  JSON: any;
  JSONObject: any;
  Latitude: any;
  Longitude: any;
};

export type ApiKey = {
  __typename?: 'APIKey';
  created_at: Scalars['DateTimeISO'];
  token: Scalars['String'];
  type: ApiKeyKind;
};

export enum ApiKeyKind {
  Public = 'PUBLIC',
  Secret = 'SECRET'
}

export type ApiUser = {
  __typename?: 'APIUser';
  created_at: Scalars['DateTimeISO'];
  id: Scalars['ID'];
  username: Scalars['String'];
};

export type AboutSectionConfig = {
  __typename?: 'AboutSectionConfig';
  contact_button_enabled?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AboutSectionConfigInput = {
  contact_button_enabled?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Every participant user belongs to an account. */
export type Account = {
  __typename?: 'Account';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  contact_phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  id_owner: Scalars['ID'];
  legal_business_name?: Maybe<Scalars['String']>;
  merchant_accounts?: Maybe<Array<MerchantAccount>>;
  place?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  support_email?: Maybe<Scalars['String']>;
  support_phone?: Maybe<Scalars['String']>;
  type: AccountKind;
};

export enum AccountKind {
  Business = 'BUSINESS',
  Personal = 'PERSONAL'
}

export enum ActionKind {
  AddConstToField = 'ADD_CONST_TO_FIELD',
  AddConsumerToVar = 'ADD_CONSUMER_TO_VAR',
  AddFieldToField = 'ADD_FIELD_TO_FIELD',
  AddMatchToVar = 'ADD_MATCH_TO_VAR',
  AddProviderToVar = 'ADD_PROVIDER_TO_VAR',
  AddSupplyToVar = 'ADD_SUPPLY_TO_VAR',
  ApproveObject = 'APPROVE_OBJECT',
  CancelTimeEvent = 'CANCEL_TIME_EVENT',
  CopyFieldValue = 'COPY_FIELD_VALUE',
  CreateOrder = 'CREATE_ORDER',
  DeleteFieldValue = 'DELETE_FIELD_VALUE',
  ForceTransition = 'FORCE_TRANSITION',
  LockMatchPriceOverride = 'LOCK_MATCH_PRICE_OVERRIDE',
  LockObject = 'LOCK_OBJECT',
  OnboardObject = 'ONBOARD_OBJECT',
  RejectObject = 'REJECT_OBJECT',
  RequestTimeEvent = 'REQUEST_TIME_EVENT',
  SetTimestampField = 'SET_TIMESTAMP_FIELD',
  SetVar = 'SET_VAR',
  SubConstFromField = 'SUB_CONST_FROM_FIELD',
  SubFieldFromField = 'SUB_FIELD_FROM_FIELD',
  UnlockMatchPriceOverride = 'UNLOCK_MATCH_PRICE_OVERRIDE',
  UnlockObject = 'UNLOCK_OBJECT',
  UpdateFieldsAccesses = 'UPDATE_FIELDS_ACCESSES',
  UpdateFieldAccess = 'UPDATE_FIELD_ACCESS'
}

export type ActionType = {
  __typename?: 'ActionType';
  params?: Maybe<Scalars['JSONObject']>;
  type: ActionKind;
};

export type ActionTypeInput = {
  params?: InputMaybe<Scalars['JSONObject']>;
  type: ActionKind;
};

export type AddOn = {
  __typename?: 'AddOn';
  description: Scalars['String'];
  guard_expression: Scalars['String'];
  is_merchant_level?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  value_expression: Scalars['String'];
};

export type AddOnInput = {
  description: Scalars['String'];
  guard_expression: Scalars['String'];
  is_merchant_level?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  value_expression: Scalars['String'];
};

export type AddonItem = {
  __typename?: 'AddonItem';
  description: Scalars['String'];
  value: Scalars['Int'];
};

export type AssetConnection = {
  __typename?: 'AssetConnection';
  edges: Array<AssetConnectionEdge>;
  page_info: PageInfo;
  total_count: Scalars['Int'];
};

export type AssetConnectionEdge = {
  __typename?: 'AssetConnectionEdge';
  cursor: Scalars['String'];
  node: AssetForBo;
};

/** Asset from platform operator's perspective. */
export type AssetForBo = {
  __typename?: 'AssetForBo';
  /** Timestamp indicates when the file was originally uploaded to the platform. */
  created_at: Scalars['DateTimeISO'];
  /**
   * Description of the asset provided by the user.
   * A detailed description of the file's contents or purpose, such as "High-resolution front view of the product for the online store".
   */
  description?: Maybe<Scalars['String']>;
  /**
   * File extension indicating the file format.
   * Specifies the file format, such as "jpg", "png", "pdf", etc.
   */
  extension: Scalars['String'];
  /**
   * Name of the file that is uploaded.
   * This is the original file name as it was when uploaded to the platform, such as "Screenshot 2024-08-23 at 19.39.16.png".
   */
  file_name: Scalars['String'];
  /** ID of the file. */
  id: Scalars['ID'];
  /**
   * Indicates if the asset is private or public.
   * Private assets require a signed URL to grant access, ensuring that only authorized users can view or download the file.
   */
  is_public: Scalars['Boolean'];
  /**
   * Name given to the file by the user.
   * A user-defined name for easy identification of the file within the system, such as "Product image - front side".
   */
  name?: Maybe<Scalars['String']>;
  /** Size of the file in kilobytes. */
  size_kb: Scalars['Float'];
  /**
   * Type of the file.
   * Indicates the kind of file, which could be an image, document, video, etc. This helps in categorizing and managing assets.
   */
  type: FileKind;
  /**
   * URL to access the asset.
   * The direct link to view or download the file.
   */
  url: Scalars['String'];
};

export type AssetsFilterForBo = {
  /** Filter assets by their privacy. */
  is_public?: InputMaybe<BooleanFilter>;
  /** Filter assets by their size. */
  size_kb?: InputMaybe<DecimalFilter>;
};

export enum AvailabilityManagementKind {
  Default = 'DEFAULT',
  External = 'EXTERNAL',
  None = 'NONE'
}

export type AvailabilityParameterMapping = {
  __typename?: 'AvailabilityParameterMapping';
  name: Scalars['String'];
  source_parameter: Scalars['String'];
};

export type AvailabilityRecord = {
  __typename?: 'AvailabilityRecord';
  end_date?: Maybe<Scalars['DateTimeISO']>;
  end_datetime?: Maybe<Scalars['DateTimeISO']>;
  quantity?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['DateTimeISO']>;
  start_datetime?: Maybe<Scalars['DateTimeISO']>;
};

export type AvailabilityRecordInput = {
  end_date?: InputMaybe<Scalars['DateTimeISO']>;
  end_datetime?: InputMaybe<Scalars['DateTimeISO']>;
  quantity?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['DateTimeISO']>;
  start_datetime?: InputMaybe<Scalars['DateTimeISO']>;
};

export enum AvailabilityTrackingKind {
  SimpleDayCalendar = 'SIMPLE_DAY_CALENDAR',
  SimpleStock = 'SIMPLE_STOCK',
  StockCalendar = 'STOCK_CALENDAR'
}

export type AvailabilityTrackingScheme = {
  __typename?: 'AvailabilityTrackingScheme';
  guard_expression: Scalars['String'];
  name: Scalars['String'];
  parameter_mappings: Array<AvailabilityParameterMapping>;
};

export type AvailableIntegrationProvider = {
  __typename?: 'AvailableIntegrationProvider';
  is_default: Scalars['Boolean'];
  name: Scalars['String'];
  provider: Scalars['String'];
  type: IntegrationProviderKind;
};

/** Users that are part of your platform team and have access to your randevu.tech account. */
export type BackofficeUser = {
  __typename?: 'BackofficeUser';
  created_at: Scalars['DateTimeISO'];
  email: Scalars['String'];
  email_verified_at?: Maybe<Scalars['DateTimeISO']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invited_by?: Maybe<BackofficeUser>;
  last_logged_in_at?: Maybe<Scalars['DateTimeISO']>;
  last_name?: Maybe<Scalars['String']>;
  platform_display_name: Scalars['String'];
  platform_name: Scalars['String'];
  platform_onboarding?: Maybe<PlatformOwnerOnboardingProgress>;
  profile_image?: Maybe<Scalars['String']>;
  role: PlatformTeamRole;
};

export type BackofficeUserFilter = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Array<PlatformTeamRole>>;
};

/** Filter operators for a boolean value. */
export type BooleanFilter = {
  /** Checks if the value is equal against provided value. */
  equals?: InputMaybe<Scalars['Boolean']>;
  /** Checks value against null. If value is set, then it is not null. */
  is_set?: InputMaybe<Scalars['Boolean']>;
};

export type Chargeable = {
  __typename?: 'Chargeable';
  chargeable_items: Array<ChargeableItem>;
  currency: CurrencyKind;
  total_after_tax: Scalars['Int'];
  total_before_tax: Scalars['Int'];
  total_fees: Scalars['Int'];
  total_taxes: Scalars['Int'];
  total_to_charge: Scalars['Int'];
};

export type ChargeableItem = {
  __typename?: 'ChargeableItem';
  data: Scalars['JSONObject'];
  description: Scalars['String'];
  is_adjustment: Scalars['Boolean'];
  type: CostItemKind;
  value: Scalars['Int'];
};

export type ChooseNewPasswordPageConfig = {
  __typename?: 'ChooseNewPasswordPageConfig';
  form?: Maybe<ChooseNewPasswordPageFormConfig>;
  title?: Maybe<Scalars['String']>;
};

export type ChooseNewPasswordPageConfigInput = {
  form?: InputMaybe<ChooseNewPasswordPageFormConfigInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type ChooseNewPasswordPageFormConfig = {
  __typename?: 'ChooseNewPasswordPageFormConfig';
  button_submit?: Maybe<FrontofficeFormFieldConfig>;
  field_password?: Maybe<FrontofficeFormFieldConfig>;
};

export type ChooseNewPasswordPageFormConfigInput = {
  button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_password?: InputMaybe<FrontofficeFormFieldConfigInput>;
};

export type Collection = {
  __typename?: 'Collection';
  created_at: Scalars['DateTimeISO'];
  id: Scalars['ID'];
  name: Scalars['String'];
  owner_participant: Participant;
  supplies_count: Scalars['Int'];
};

export type ConditionInput = {
  payload: Scalars['JSONObject'];
  type: FieldConditionKind;
};

export type ConfigFile = {
  __typename?: 'ConfigFile';
  file_name: Scalars['String'];
  id: Scalars['ID'];
  is_public: Scalars['Boolean'];
  name: Scalars['String'];
  size_kb: Scalars['Float'];
  type: FileKind;
  url: Scalars['String'];
};

export type ConnectedParticipant = {
  __typename?: 'ConnectedParticipant';
  created_at: Scalars['DateTimeISO'];
  fields: Array<Field>;
  id: Scalars['ID'];
};

export type ConnectedParticipantPageUiConfig = {
  __typename?: 'ConnectedParticipantPageUiConfig';
  title?: Maybe<Scalars['String']>;
};

export type ConnectedParticipantPageUiConfigInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type Connection = {
  __typename?: 'Connection';
  accepted_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_approved_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_rejected_at?: Maybe<Scalars['DateTimeISO']>;
  created_at?: Maybe<Scalars['DateTimeISO']>;
  declined_at?: Maybe<Scalars['DateTimeISO']>;
  established_at?: Maybe<Scalars['DateTimeISO']>;
  fields: Array<Field>;
  id: Scalars['ID'];
  participant: ConnectedParticipant;
  participant_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  participant_declined_at?: Maybe<Scalars['DateTimeISO']>;
  status: ConnectionStatusKind;
  type: ConnectionType;
};

export type ConnectionDiscoveryFilter = {
  fields?: InputMaybe<Array<FieldFilterInput>>;
  ids_participants?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ConnectionFieldAccessClientKind {
  End1 = 'END1',
  End2 = 'END2'
}

export type ConnectionFilter = {
  connection_tech_name: Scalars['String'];
  ids_participants?: InputMaybe<Array<Scalars['ID']>>;
};

export type ConnectionFilterForBo = {
  id_connection_type: Scalars['ID'];
  statuses?: InputMaybe<Array<ConnectionStatusKind>>;
};

export type ConnectionForBo = {
  __typename?: 'ConnectionForBO';
  backoffice_approved_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_rejected_at?: Maybe<Scalars['DateTimeISO']>;
  created_at?: Maybe<Scalars['DateTimeISO']>;
  end1: Participant;
  end1_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  end1_declined_at?: Maybe<Scalars['DateTimeISO']>;
  end2: Participant;
  end2_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  end2_declined_at?: Maybe<Scalars['DateTimeISO']>;
  established_at?: Maybe<Scalars['DateTimeISO']>;
  fields: Array<Field>;
  id: Scalars['ID'];
  status: ConnectionStatusKind;
  type: ConnectionType;
};

export enum ConnectionStatusKind {
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Established = 'ESTABLISHED',
  Establishing = 'ESTABLISHING',
  Rejected = 'REJECTED'
}

export type ConnectionType = {
  __typename?: 'ConnectionType';
  auto_backoffice_approval: Scalars['Boolean'];
  auto_end1_acceptance: Scalars['Boolean'];
  auto_end2_acceptance: Scalars['Boolean'];
  end1_discovery_enabled: Scalars['Boolean'];
  end1_name: Scalars['String'];
  end1_participant_type: ParticipantType;
  end2_discovery_enabled: Scalars['Boolean'];
  end2_name: Scalars['String'];
  end2_participant_type: ParticipantType;
  fields: Array<FieldType>;
  id: Scalars['ID'];
  initial_connection_visibility: ConnectionVisibilityInfo;
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  participant_discovery_enabled: Scalars['Boolean'];
  participant_type: ParticipantType;
  tech_name: Scalars['String'];
  type_visibility: ConnectionTypeVisibilityInfo;
};

export type ConnectionTypeVisibilityInfo = {
  __typename?: 'ConnectionTypeVisibilityInfo';
  connection_fields: Array<Scalars['ID']>;
  end1_fields: Array<Scalars['ID']>;
  end1_fields_out_for_end2: Array<Scalars['ID']>;
  end2_fields: Array<Scalars['ID']>;
  end2_fields_out_for_end1: Array<Scalars['ID']>;
};

export type ConnectionTypeVisibilityInfoInput = {
  end1_fields?: InputMaybe<Array<Scalars['ID']>>;
  end1_fields_out_for_end2?: InputMaybe<Array<Scalars['ID']>>;
  end2_fields?: InputMaybe<Array<Scalars['ID']>>;
  end2_fields_out_for_end1?: InputMaybe<Array<Scalars['ID']>>;
};

export type ConnectionVisibilityInfo = {
  __typename?: 'ConnectionVisibilityInfo';
  connection_by_end1: Scalars['Boolean'];
  connection_by_end2: Scalars['Boolean'];
  end1_fields: Array<Scalars['ID']>;
  end2_fields: Array<Scalars['ID']>;
};

export type ConnectionVisibilityInfoInput = {
  connection_by_end1?: InputMaybe<Scalars['Boolean']>;
  connection_by_end2?: InputMaybe<Scalars['Boolean']>;
  connection_fields?: InputMaybe<Array<Scalars['ID']>>;
  end1_fields?: InputMaybe<Array<Scalars['ID']>>;
  end2_fields?: InputMaybe<Array<Scalars['ID']>>;
};

export type ConnectionsPageUiConfig = {
  __typename?: 'ConnectionsPageUiConfig';
  title: Array<Maybe<PageTitleUiConfig>>;
};

export type ConnectionsPageUiConfigInput = {
  title: Array<InputMaybe<PageTitleUiConfigInput>>;
};

export type ContactUsFormConfig = {
  __typename?: 'ContactUsFormConfig';
  button_submit?: Maybe<FrontofficeFormFieldConfig>;
  field_email?: Maybe<FrontofficeFormFieldConfig>;
  field_first_name?: Maybe<FrontofficeFormFieldConfig>;
  field_last_name?: Maybe<FrontofficeFormFieldConfig>;
  field_message?: Maybe<FrontofficeFormFieldConfig>;
  legal_text?: Maybe<ContactUsFormLegalTextConfig>;
};

export type ContactUsFormConfigInput = {
  button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_email?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_first_name?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_last_name?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_message?: InputMaybe<FrontofficeFormFieldConfigInput>;
  legal_text?: InputMaybe<ContactUsFormLegalTextConfigInput>;
};

export type ContactUsFormLegalLinkConfig = {
  __typename?: 'ContactUsFormLegalLinkConfig';
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ContactUsFormLegalLinkConfigInput = {
  label?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ContactUsFormLegalTextConfig = {
  __typename?: 'ContactUsFormLegalTextConfig';
  links?: Maybe<Array<Maybe<ContactUsFormLegalLinkConfig>>>;
  text?: Maybe<Scalars['String']>;
};

export type ContactUsFormLegalTextConfigInput = {
  links?: InputMaybe<Array<InputMaybe<ContactUsFormLegalLinkConfigInput>>>;
  text?: InputMaybe<Scalars['String']>;
};

export type ContactUsPageAbputSectionConfig = {
  __typename?: 'ContactUsPageAbputSectionConfig';
  text?: Maybe<Scalars['String']>;
};

export type ContactUsPageAbputSectionConfigInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type ContactUsPageConfig = {
  __typename?: 'ContactUsPageConfig';
  additional_info?: Maybe<ContactUsPageAbputSectionConfig>;
  description?: Maybe<Scalars['String']>;
  eyebrow?: Maybe<Scalars['String']>;
  form?: Maybe<ContactUsFormConfig>;
  form_submitted_view?: Maybe<ContactUsPageFormSubmittedViewConfig>;
  title?: Maybe<Scalars['String']>;
};

export type ContactUsPageConfigInput = {
  additional_info?: InputMaybe<ContactUsPageAbputSectionConfigInput>;
  description?: InputMaybe<Scalars['String']>;
  eyebrow?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<ContactUsFormConfigInput>;
  form_submitted_view?: InputMaybe<ContactUsPageFormSubmittedViewConfigInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type ContactUsPageFormSubmittedViewConfig = {
  __typename?: 'ContactUsPageFormSubmittedViewConfig';
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ContactUsPageFormSubmittedViewConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CostAdjustment = {
  __typename?: 'CostAdjustment';
  amount: Scalars['Int'];
  description: Scalars['String'];
  is_consumer_fee?: Maybe<Scalars['Boolean']>;
  type: CostItemKind;
};

export type CostAdjustmentInput = {
  amount: Scalars['Int'];
  description: Scalars['String'];
  is_consumer_fee?: InputMaybe<Scalars['Boolean']>;
  type: CostItemKind;
};

export enum CostItemKind {
  AddOn = 'ADD_ON',
  Discount = 'DISCOUNT',
  PlatformFee = 'PLATFORM_FEE',
  PriceWithQty = 'PRICE_WITH_QTY',
  ProviderTax = 'PROVIDER_TAX',
  Tax = 'TAX'
}

/** Enumeration of supported currencies */
export enum CurrencyKind {
  /** The Australian Dollar is the currency of Australia */
  Aud = 'AUD',
  /** The euro is the official currency of multiple European Union countries which collectively make up the euro area, also known as the eurozone */
  Eur = 'EUR',
  /** The British pound sterling, the official currency of the United Kingdom and its territories */
  Gbp = 'GBP',
  /** The United States dollar is the official currency of the United States and several other countries. */
  Usd = 'USD'
}

export type CustomerFrontendUrl = {
  __typename?: 'CustomerFrontendUrl';
  is_favourite?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CustomerFrontendUrlInput = {
  is_favourite?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type DashboardPageUiConfig = {
  __typename?: 'DashboardPageUiConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  tiles: Array<DashboardTileUiConfig>;
  title: Scalars['String'];
};

export type DashboardPageUiConfigInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  tiles: Array<DashboardTileUiConfigInput>;
  title: Scalars['String'];
};

export type DashboardTileUiConfig = {
  __typename?: 'DashboardTileUiConfig';
  ids_participant_type: Array<Maybe<Scalars['ID']>>;
  target_route: Scalars['String'];
  title: Scalars['String'];
};

export type DashboardTileUiConfigInput = {
  ids_participant_type: Array<InputMaybe<Scalars['ID']>>;
  target_route: Scalars['String'];
  title: Scalars['String'];
};

export type DataFormatOption = {
  __typename?: 'DataFormatOption';
  date_format: Scalars['String'];
  decimal_separator: Scalars['String'];
  delimiter: Scalars['String'];
};

export type DataFormatOptionInput = {
  date_format: Scalars['String'];
  decimal_separator: Scalars['String'];
  delimiter: Scalars['String'];
};

export type DataImportJobStatusReport = {
  __typename?: 'DataImportJobStatusReport';
  data: File;
  executed_by_backoffice: Scalars['Boolean'];
  finished_at?: Maybe<Scalars['DateTimeISO']>;
  has_errors?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  records_processed_successfully?: Maybe<Scalars['Int']>;
  records_with_errors?: Maybe<Scalars['Int']>;
  report?: Maybe<File>;
  requested_at: Scalars['DateTimeISO'];
  requested_by: User;
  started_at?: Maybe<Scalars['DateTimeISO']>;
  target_object: DataImportTargetType;
  template: ConfigFile;
  total_number_of_records?: Maybe<Scalars['Int']>;
};

export type DataImportJobStatusReportForBo = {
  __typename?: 'DataImportJobStatusReportForBO';
  data: File;
  executed_by_backoffice: Scalars['Boolean'];
  finished_at?: Maybe<Scalars['DateTimeISO']>;
  has_errors?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  records_processed_successfully?: Maybe<Scalars['Int']>;
  records_with_errors?: Maybe<Scalars['Int']>;
  report?: Maybe<File>;
  requested_at: Scalars['DateTimeISO'];
  requested_by: User;
  requested_by_participant: Participant;
  started_at?: Maybe<Scalars['DateTimeISO']>;
  target_object: DataImportTargetType;
  template: ConfigFile;
  total_number_of_records?: Maybe<Scalars['Int']>;
};

export type DataImportTargetType = {
  __typename?: 'DataImportTargetType';
  id_parent_type: Scalars['ID'];
  name_parent_type: Scalars['String'];
};

/** Filter operators for dates. Among others, used by InputKind.Date */
export type DateFilter = {
  /** Checks if the value is equal against provided date. */
  equals?: InputMaybe<Scalars['Date']>;
  /** Checks if the value is greater than provided date, i.e. the value is after the provided date. */
  gt?: InputMaybe<Scalars['Date']>;
  /** Checks if the value is greater than or equal to provided date, i.e. the value is after or equal to the provided date. */
  gte?: InputMaybe<Scalars['Date']>;
  /** Checks value against null. If value is set, then it is not null. */
  is_set?: InputMaybe<Scalars['Boolean']>;
  /** Checks if the value is less than provided date, i.e. the value is before the provided date. */
  lt?: InputMaybe<Scalars['Date']>;
  /** Checks if the value is less or equal than provided date, i.e. the value is before or equal to the provided date. */
  lte?: InputMaybe<Scalars['Date']>;
  /** Checks if the value is not equal against provided date. */
  not?: InputMaybe<Scalars['Date']>;
};

/** Filter operators for InputKind.DateSet */
export type DateSetFilter = {
  /** Checks if the value is equal against provided values. All values have to match and no additional value is allowed. */
  equals?: InputMaybe<Array<Scalars['Date']>>;
  /** Checks if the value contains one or more of provided values. The in operator is a shorthand for multiple OR logical operators. */
  in?: InputMaybe<Array<Scalars['Date']>>;
  /** Checks value against null and empty array. If value is set, then it is neither null nor empty array. It is an array containing at least one value. */
  is_set?: InputMaybe<Scalars['Boolean']>;
};

/** Filter operators for dates. Among others, used by InputKind.Datetime */
export type DateTimeFilter = {
  /** Checks if the value is greater than provided timestamp, i.e. the value is after the provided timestamp. */
  gt?: InputMaybe<Scalars['DateTimeISO']>;
  /** Checks value against null. If value is set, then it is not null. */
  is_set?: InputMaybe<Scalars['Boolean']>;
  /** Checks if the value is less than provided timestamp, i.e. the value is before the provided timestamp. */
  lt?: InputMaybe<Scalars['DateTimeISO']>;
};

/** Filter operators for an integer value. Among others, used by InputKind.Decimal */
export type DecimalFilter = {
  /** Checks if the value is equal against provided value. */
  equals?: InputMaybe<Scalars['Float']>;
  /** Checks if the value is greater than provided value */
  gt?: InputMaybe<Scalars['Float']>;
  /** Checks if the value is greater or equal than provided value */
  gte?: InputMaybe<Scalars['Float']>;
  /** Checks value against null. If value is set, then it is not null. */
  is_set?: InputMaybe<Scalars['Boolean']>;
  /** Checks if the value is less than provided value */
  lt?: InputMaybe<Scalars['Float']>;
  /** Checks if the value is less than or equal than provided value */
  lte?: InputMaybe<Scalars['Float']>;
  /** Checks if the value is not equal against provided value. */
  not?: InputMaybe<Scalars['Float']>;
};

export type Deployment = {
  __typename?: 'Deployment';
  canceled_at?: Maybe<Scalars['DateTimeISO']>;
  canceled_by?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  executed_by?: Maybe<Scalars['ID']>;
  execution_ended_at?: Maybe<Scalars['DateTimeISO']>;
  execution_started_at?: Maybe<Scalars['DateTimeISO']>;
  flow_migrations: Array<FlowMigration>;
  id: Scalars['ID'];
  interrupted_at?: Maybe<Scalars['DateTimeISO']>;
  prepared_at: Scalars['DateTimeISO'];
  prepared_by: Scalars['ID'];
  rolledback_at?: Maybe<Scalars['DateTimeISO']>;
  status: DeploymentStatusKind;
  status_message?: Maybe<Scalars['String']>;
};

export enum DeploymentStatusKind {
  Canceled = 'CANCELED',
  Executed = 'EXECUTED',
  Interrupted = 'INTERRUPTED',
  InExecution = 'IN_EXECUTION',
  NotReady = 'NOT_READY',
  Ready = 'READY'
}

export type DirectMatchForBo = {
  __typename?: 'DirectMatchForBO';
  backoffice_approved_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_rejected_at?: Maybe<Scalars['DateTimeISO']>;
  canceled_at?: Maybe<Scalars['DateTimeISO']>;
  closed_at?: Maybe<Scalars['DateTimeISO']>;
  consumer: Participant;
  consumer_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_declined_at?: Maybe<Scalars['DateTimeISO']>;
  created_at: Scalars['DateTimeISO'];
  fields: Array<Field>;
  id: Scalars['ID'];
  paid_at?: Maybe<Scalars['DateTimeISO']>;
  price?: Maybe<SupplyPrice>;
  provider: Participant;
  provider_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  provider_declined_at?: Maybe<Scalars['DateTimeISO']>;
  status: MatchStatusKind;
  supply: Supply;
};

export type DirectMatchForConsumer = {
  __typename?: 'DirectMatchForConsumer';
  backoffice_approved_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_rejected_at?: Maybe<Scalars['DateTimeISO']>;
  canceled_at?: Maybe<Scalars['DateTimeISO']>;
  closed_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_declined_at?: Maybe<Scalars['DateTimeISO']>;
  created_at: Scalars['DateTimeISO'];
  fields: Array<Field>;
  id: Scalars['ID'];
  id_provider?: Maybe<Scalars['ID']>;
  id_supply: Scalars['ID'];
  paid_at?: Maybe<Scalars['DateTimeISO']>;
  price?: Maybe<SupplyPrice>;
  provider_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  provider_declined_at?: Maybe<Scalars['DateTimeISO']>;
  provider_fields: Array<Field>;
  status: MatchStatusKind;
  supply_fields: Array<Field>;
};

export type DirectMatchForProvider = {
  __typename?: 'DirectMatchForProvider';
  backoffice_approved_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_rejected_at?: Maybe<Scalars['DateTimeISO']>;
  canceled_at?: Maybe<Scalars['DateTimeISO']>;
  closed_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_declined_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_fields: Array<Field>;
  created_at: Scalars['DateTimeISO'];
  fields: Array<Field>;
  id: Scalars['ID'];
  id_consumer?: Maybe<Scalars['ID']>;
  paid_at?: Maybe<Scalars['DateTimeISO']>;
  price?: Maybe<SupplyPrice>;
  provider_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  provider_declined_at?: Maybe<Scalars['DateTimeISO']>;
  status: MatchStatusKind;
  supply: Supply;
};

export type DirectMatchRequest = {
  __typename?: 'DirectMatchRequest';
  fields: Array<Field>;
  id_supply?: Maybe<Scalars['ID']>;
  requester: User;
  requester_participant: Participant;
};

export type DirectMatchRequestForProvider = {
  __typename?: 'DirectMatchRequestForProvider';
  fields: Array<Field>;
  id_supply: Scalars['ID'];
  requester: User;
};

export type Discount = {
  __typename?: 'Discount';
  description: Scalars['String'];
  guard_expression: Scalars['String'];
  is_merchant_level?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  value_expression: Scalars['String'];
};

export type DiscountInput = {
  description: Scalars['String'];
  guard_expression: Scalars['String'];
  is_merchant_level?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  value_expression: Scalars['String'];
};

export type DiscountItem = {
  __typename?: 'DiscountItem';
  description: Scalars['String'];
  value: Scalars['Int'];
};

export type DiscoveredConsumer = {
  __typename?: 'DiscoveredConsumer';
  fields: Array<Field>;
  id_participant: Scalars['ID'];
  type: ParticipantType;
};

export type DiscoveredSupply = {
  __typename?: 'DiscoveredSupply';
  availability?: Maybe<Scalars['JSONObject']>;
  fields: Array<Field>;
  id: Scalars['ID'];
  provider: Provider;
  type: SupplyType;
  variant_group?: Maybe<Scalars['String']>;
  variants: Array<DiscoveredSupplyVariant>;
};

export type DiscoveredSupplyConnection = {
  __typename?: 'DiscoveredSupplyConnection';
  edges: Array<DiscoveredSupplyEdge>;
  page_info: PageInfo;
  total_count: Scalars['Int'];
};

export type DiscoveredSupplyEdge = {
  __typename?: 'DiscoveredSupplyEdge';
  cursor: Scalars['String'];
  node: DiscoveredSupply;
};

export type DiscoveredSupplyVariant = {
  __typename?: 'DiscoveredSupplyVariant';
  availability?: Maybe<Scalars['JSONObject']>;
  created_at: Scalars['DateTimeISO'];
  fields: Array<Field>;
  id: Scalars['ID'];
  variant_group: Scalars['String'];
};

export type DynamicFieldTypeInput = {
  name: Scalars['String'];
  type: InputKind;
  value?: InputMaybe<Scalars['JSON']>;
};

export type EMailNotificationRecipient = {
  __typename?: 'EMailNotificationRecipient';
  data?: Maybe<Scalars['JSONObject']>;
  email_recipient_type: EMailRecipientKind;
  parent_type?: Maybe<NotificationFieldParentKind>;
  role_type?: Maybe<NotificationRecipientRoleKind>;
};

export type EMailNotificationRecipientInput = {
  data?: InputMaybe<Scalars['JSONObject']>;
  email_recipient_type: EMailRecipientKind;
  parent_type?: InputMaybe<NotificationFieldParentKind>;
  role_type?: InputMaybe<NotificationRecipientRoleKind>;
};

export enum EMailRecipientKind {
  Bcc = 'BCC',
  Cc = 'CC',
  To = 'TO'
}

export type EmailArguments = {
  __typename?: 'EmailArguments';
  parameter_name: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
};

export type EmailNotificationLog = {
  __typename?: 'EmailNotificationLog';
  bcc: Array<Scalars['String']>;
  cc: Array<Scalars['String']>;
  id: Scalars['ID'];
  id_source_event: Scalars['ID'];
  is_success: Scalars['Boolean'];
  notification_type: NotificationType;
  parameters: Array<EmailArguments>;
  replied_at?: Maybe<Scalars['DateTimeISO']>;
  response?: Maybe<Scalars['JSONObject']>;
  sent_at: Scalars['DateTimeISO'];
  to: Array<Scalars['String']>;
  trigger_event: RandevuEventKind;
};

export type EnvironmentVariable = {
  __typename?: 'EnvironmentVariable';
  id: Scalars['ID'];
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  code: RandevuEventKind;
  id: Scalars['ID'];
  payload: Scalars['JSONObject'];
  triggered_at: Scalars['DateTimeISO'];
};

export enum EventObjectKind {
  Match = 'MATCH',
  MatchingTool = 'MATCHING_TOOL',
  Payment = 'PAYMENT',
  Supply = 'SUPPLY',
  Transaction = 'TRANSACTION',
  User = 'USER'
}

export enum EventObjectTypeKind {
  MatchingToolType = 'MATCHING_TOOL_TYPE',
  MatchType = 'MATCH_TYPE',
  ParticipantType = 'PARTICIPANT_TYPE',
  PaymentType = 'PAYMENT_TYPE',
  SupplyType = 'SUPPLY_TYPE',
  TransactionType = 'TRANSACTION_TYPE',
  UserType = 'USER_TYPE'
}

export type EventSelectorRecord = {
  __typename?: 'EventSelectorRecord';
  event_aliases?: Maybe<Array<Scalars['String']>>;
  event_type: RandevuEventKind;
  ids_event_object_types: Array<Scalars['ID']>;
  ids_field_types?: Maybe<Array<Scalars['ID']>>;
  ids_state_types?: Maybe<Array<Scalars['ID']>>;
  ids_transition_types?: Maybe<Array<Scalars['ID']>>;
};

export type EventSelectorRecordInput = {
  event_aliases?: InputMaybe<Array<Scalars['String']>>;
  event_type: RandevuEventKind;
  ids_event_object_types: Array<Scalars['ID']>;
  ids_field_types?: InputMaybe<Array<Scalars['ID']>>;
  ids_state_types?: InputMaybe<Array<Scalars['ID']>>;
  ids_transition_types?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ExecutionSchedulingKind {
  OneTime = 'ONE_TIME',
  OnNewSupply = 'ON_NEW_SUPPLY'
}

export type FavouriteFieldType = {
  __typename?: 'FavouriteFieldType';
  input_options: Scalars['JSONObject'];
  input_type: InputKind;
  is_nullable: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Field = ObjectField | ObjectSetField | SimpleField;

export enum FieldAccessClientKind {
  Backoffice = 'BACKOFFICE',
  Consumer = 'CONSUMER',
  Creator = 'CREATOR',
  End1 = 'END1',
  End2 = 'END2',
  NoCreator = 'NO_CREATOR',
  Owner = 'OWNER',
  Provider = 'PROVIDER'
}

export type FieldAccessInfo = {
  __typename?: 'FieldAccessInfo';
  to_consumer?: Maybe<FieldAccessKind>;
  to_creator?: Maybe<FieldAccessKind>;
  to_end1?: Maybe<FieldAccessKind>;
  to_end2?: Maybe<FieldAccessKind>;
  to_guest?: Maybe<FieldAccessKind>;
  to_no_creator?: Maybe<FieldAccessKind>;
  to_owner?: Maybe<FieldAccessKind>;
  to_provider?: Maybe<FieldAccessKind>;
};

export type FieldAccessInfoInput = {
  to_consumer?: InputMaybe<FieldAccessKind>;
  to_creator?: InputMaybe<FieldAccessKind>;
  to_end1?: InputMaybe<FieldAccessKind>;
  to_end2?: InputMaybe<FieldAccessKind>;
  to_guest?: InputMaybe<FieldAccessKind>;
  to_no_creator?: InputMaybe<FieldAccessKind>;
  to_owner?: InputMaybe<FieldAccessKind>;
  to_provider?: InputMaybe<FieldAccessKind>;
};

export enum FieldAccessKind {
  None = 'NONE',
  Read = 'READ',
  Write = 'WRITE'
}

export enum FieldCategoryKind {
  BasePrice = 'BASE_PRICE',
  Description = 'DESCRIPTION',
  EndDate = 'END_DATE',
  EndDatetime = 'END_DATETIME',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  MainDatetime = 'MAIN_DATETIME',
  MainImage = 'MAIN_IMAGE',
  Name = 'NAME',
  ProfileImage = 'PROFILE_IMAGE',
  Quantity = 'QUANTITY',
  StartDate = 'START_DATE',
  StartDatetime = 'START_DATETIME'
}

export type FieldCondition = {
  __typename?: 'FieldCondition';
  payload: Scalars['JSONObject'];
  type: FieldConditionKind;
};

export enum FieldConditionKind {
  ValueEqualTo = 'VALUE_EQUAL_TO',
  ValueIsIn = 'VALUE_IS_IN',
  ValueIsNotIn = 'VALUE_IS_NOT_IN',
  ValueNotEqualTo = 'VALUE_NOT_EQUAL_TO',
  ValueProvided = 'VALUE_PROVIDED'
}

export enum FieldContainerKind {
  Connection = 'CONNECTION',
  Match = 'MATCH',
  MatchingTool = 'MATCHING_TOOL',
  Object = 'OBJECT',
  Participant = 'PARTICIPANT',
  ParticipantSegment = 'PARTICIPANT_SEGMENT',
  Supply = 'SUPPLY'
}

/** DEPRECATED: TO BE REMOVED AFTER RELEASING NEW RA TT */
export type FieldFilter = {
  __typename?: 'FieldFilter';
  tech_name: Scalars['String'];
  value?: Maybe<Scalars['JSON']>;
};

export type FieldFilterInput = {
  tech_name: Scalars['String'];
  value?: InputMaybe<Scalars['JSON']>;
};

/**
 * Filter operators for each field type's input kind.
 * Exactly one of the input fields must be provided as input, or otherwise the server returns a validation error.
 */
export type FieldFilterOperator = {
  boolean?: InputMaybe<BooleanFilter>;
  date?: InputMaybe<DateFilter>;
  date_set?: InputMaybe<DateSetFilter>;
  datetime?: InputMaybe<DateTimeFilter>;
  decimal?: InputMaybe<DecimalFilter>;
  document?: InputMaybe<FileFilter>;
  document_set?: InputMaybe<FileSetFilter>;
  image?: InputMaybe<FileFilter>;
  image_set?: InputMaybe<FileSetFilter>;
  integer?: InputMaybe<IntegerFilter>;
  location?: InputMaybe<LocationFilter>;
  monetary_value?: InputMaybe<IntegerFilter>;
  multi_select?: InputMaybe<MultiSelectFilter>;
  participant?: InputMaybe<ParticipantFieldFilter>;
  participant_set?: InputMaybe<ParticipantSetFieldFilter>;
  select?: InputMaybe<SelectFilter>;
  smart_text?: InputMaybe<TextFilter>;
  smart_text_set?: InputMaybe<TextSetFilter>;
  text?: InputMaybe<TextFilter>;
  text_set?: InputMaybe<TextSetFilter>;
};

/**
 * Filters for values persisted in fields (instance of a field type).
 * Different field types support different filters.
 * For more advanced queries, you might need to explicitly combine different filters.
 */
export type FieldFilters = {
  /** The logical operator AND accepts a list of sub-filters, and will only return those items which match all of these conditions. */
  AND?: InputMaybe<Array<NestedFieldFilter>>;
  /** The logical operator NOT accepts a list of sub-filters, and will only return those items which don't match the conditions. You'll generally only pass a single filter to NOT rather than a list but if you do a pass a list, they're AND-ed together. */
  NOT?: InputMaybe<Array<NestedFieldFilter>>;
  /** The logical operator OR accepts a list of sub-filters, and will only return those items which match at least one of these conditions. */
  OR?: InputMaybe<Array<NestedFieldFilter>>;
  /** Filter to be executed against one field. */
  field?: InputMaybe<NestedFieldFilter>;
};

export type FieldInput = {
  tech_name: Scalars['String'];
  value?: InputMaybe<Scalars['JSON']>;
};

export type FieldParameterInput = {
  tech_name: Scalars['String'];
  value?: InputMaybe<Scalars['JSON']>;
};

export type FieldParameterType = {
  __typename?: 'FieldParameterType';
  initial_value?: Maybe<Scalars['JSONObject']>;
  input_options: Scalars['JSONObject'];
  input_type: InputKind;
  is_availability_param: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  name: Scalars['String'];
  tech_name: Scalars['String'];
  ui_config?: Maybe<FieldTypeUiConfig>;
};

export type FieldParameterTypeInput = {
  initial_value?: InputMaybe<Scalars['JSONObject']>;
  input_options?: InputMaybe<Scalars['JSONObject']>;
  input_type: InputKind;
  is_availability_param?: InputMaybe<Scalars['Boolean']>;
  is_required?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  ui_config?: InputMaybe<FieldTypeUiConfigInput>;
};

export type FieldType = {
  __typename?: 'FieldType';
  category?: Maybe<FieldCategoryKind>;
  conditions?: Maybe<Array<FieldCondition>>;
  id: Scalars['ID'];
  initial_accesses: FieldAccessInfo;
  initial_value?: Maybe<Scalars['JSONObject']>;
  input_options: Scalars['JSONObject'];
  input_type: InputKind;
  is_availability_param: Scalars['Boolean'];
  is_deletable_type: Scalars['Boolean'];
  is_dynamic: Scalars['Boolean'];
  is_nullable: Scalars['Boolean'];
  is_required: Scalars['Boolean'];
  is_variant_identifier: Scalars['Boolean'];
  is_variant_specific: Scalars['Boolean'];
  matching_weight?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  object_fields?: Maybe<Array<Maybe<FieldType>>>;
  tech_name: Scalars['String'];
  ui_config: FieldTypeUiConfig;
  visible_to_guests: Scalars['Boolean'];
  visible_to_participants: Scalars['Boolean'];
};

export enum FieldTypeContainerKind {
  ConnectionType = 'CONNECTION_TYPE',
  MatchingToolType = 'MATCHING_TOOL_TYPE',
  MatchType = 'MATCH_TYPE',
  ObjectType = 'OBJECT_TYPE',
  ParticipantSegmentType = 'PARTICIPANT_SEGMENT_TYPE',
  ParticipantType = 'PARTICIPANT_TYPE',
  SupplyType = 'SUPPLY_TYPE'
}

export type FieldTypeSelectUiConfigOption = {
  __typename?: 'FieldTypeSelectUIConfigOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FieldTypeSelectUiConfigOptionInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type FieldTypeUiConfig = {
  __typename?: 'FieldTypeUIConfig';
  helper_text?: Maybe<Scalars['String']>;
  input_control?: Maybe<InputControlKind>;
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<FieldTypeSelectUiConfigOption>>;
};

export type FieldTypeUiConfigInput = {
  helper_text?: InputMaybe<Scalars['String']>;
  input_control: InputControlKind;
  label?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<FieldTypeSelectUiConfigOptionInput>>;
};

export type File = {
  __typename?: 'File';
  file_name: Scalars['String'];
  id: Scalars['ID'];
  is_public: Scalars['Boolean'];
  name: Scalars['String'];
  size_kb: Scalars['Float'];
  type: FileKind;
  url: Scalars['String'];
};

/** Filter operators for files. Among others, used by InputKind.Image and InputKind.Document */
export type FileFilter = {
  /** Checks value against null. If value is set, then it is not null. */
  is_set?: InputMaybe<Scalars['Boolean']>;
};

export enum FileKind {
  Document = 'DOCUMENT',
  Image = 'IMAGE'
}

/** Filter operators for set files. Among others, used by InputKind.ImageSet and InputKind.DocumentSet */
export type FileSetFilter = {
  /** Checks value against null and empty array. If value is set, then it is neither null nor empty array. It is an array containing at least one file. */
  is_set?: InputMaybe<Scalars['Boolean']>;
};

export type FileUpload = {
  __typename?: 'FileUpload';
  id: Scalars['ID'];
  upload_url: Scalars['String'];
};

export type Flow = {
  __typename?: 'Flow';
  current_state: Scalars['String'];
  id_flow_type: Scalars['ID'];
  initiated_at: Scalars['DateTimeISO'];
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
};

export type FlowCondition = {
  __typename?: 'FlowCondition';
  payload: Scalars['JSONObject'];
  type: FlowConditionKind;
};

export type FlowConditionInput = {
  payload: Scalars['JSONObject'];
  type: FlowConditionKind;
};

export enum FlowConditionKind {
  AwaitingPayment = 'AWAITING_PAYMENT',
  EventParInVar = 'EVENT_PAR_IN_VAR',
  FieldValueContains = 'FIELD_VALUE_CONTAINS',
  FieldValueEqualTo = 'FIELD_VALUE_EQUAL_TO',
  FieldValueEqualToFieldValue = 'FIELD_VALUE_EQUAL_TO_FIELD_VALUE',
  FieldValueGreaterThan = 'FIELD_VALUE_GREATER_THAN',
  FieldValueGreaterThanFieldValue = 'FIELD_VALUE_GREATER_THAN_FIELD_VALUE',
  FieldValueLessThan = 'FIELD_VALUE_LESS_THAN',
  FieldValueLessThanFieldValue = 'FIELD_VALUE_LESS_THAN_FIELD_VALUE',
  FieldValueNotContains = 'FIELD_VALUE_NOT_CONTAINS',
  FieldValueNotEqualTo = 'FIELD_VALUE_NOT_EQUAL_TO',
  FieldValueNotEqualToFieldValue = 'FIELD_VALUE_NOT_EQUAL_TO_FIELD_VALUE',
  FieldValueNotProvided = 'FIELD_VALUE_NOT_PROVIDED',
  FieldValueProvided = 'FIELD_VALUE_PROVIDED',
  InvokerRoleIn = 'INVOKER_ROLE_IN',
  MatchCreatedByTool = 'MATCH_CREATED_BY_TOOL',
  ObjectApproved = 'OBJECT_APPROVED',
  ObjectConsistent = 'OBJECT_CONSISTENT',
  ObjectNotConsistent = 'OBJECT_NOT_CONSISTENT',
  ObjectRejected = 'OBJECT_REJECTED',
  RequestedTimeEventAlias = 'REQUESTED_TIME_EVENT_ALIAS',
  SetContainsFieldValue = 'SET_CONTAINS_FIELD_VALUE',
  SetNotContainsFieldValue = 'SET_NOT_CONTAINS_FIELD_VALUE',
  VarValueEqualTo = 'VAR_VALUE_EQUAL_TO'
}

export type FlowMigration = {
  __typename?: 'FlowMigration';
  id_flow_type: Scalars['ID'];
  state_migrations: Array<FlowStateMigration>;
};

export type FlowMigrationInput = {
  id_flow_type: Scalars['ID'];
  state_migrations: Array<FlowStateMigrationInput>;
};

export enum FlowPurposeKind {
  ParticipantOnboarding = 'PARTICIPANT_ONBOARDING',
  SupplyOnboarding = 'SUPPLY_ONBOARDING',
  Transaction = 'TRANSACTION'
}

export type FlowState = {
  __typename?: 'FlowState';
  message?: Maybe<Scalars['String']>;
  next_steps?: Maybe<Array<NextStep>>;
  status?: Maybe<Scalars['String']>;
  status_tech_name?: Maybe<Scalars['String']>;
};

export type FlowStateMigration = {
  __typename?: 'FlowStateMigration';
  actions: Array<ActionType>;
  id_new_state: Scalars['ID'];
  id_old_state: Scalars['ID'];
};

export type FlowStateMigrationInput = {
  actions: Array<ActionTypeInput>;
  id_new_state: Scalars['ID'];
  id_old_state: Scalars['ID'];
};

export enum FlowStatusFilter {
  Draft = 'DRAFT',
  Ongoing = 'ONGOING',
  Terminated = 'TERMINATED'
}

export enum FlowStatusFilterForBo {
  Draft = 'DRAFT',
  Ongoing = 'ONGOING',
  PendingBackofficeActions = 'PENDING_BACKOFFICE_ACTIONS',
  Terminated = 'TERMINATED'
}

export type FlowType = {
  __typename?: 'FlowType';
  id: Scalars['ID'];
  id_root_type: Scalars['ID'];
  initiating_conditions: Array<FlowCondition>;
  initiating_event: RandevuEventKind;
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  purpose?: Maybe<FlowPurposeKind>;
  retired_at?: Maybe<Scalars['DateTimeISO']>;
  root_type: EventObjectTypeKind;
  states: Array<StateType>;
  transitions: Array<TransitionType>;
  var_types: Array<FlowVariableType>;
};

export type FlowVariableType = {
  __typename?: 'FlowVariableType';
  name: Scalars['String'];
  type?: Maybe<EventObjectTypeKind>;
};

export type FlowVariableTypeInput = {
  name: Scalars['String'];
  type?: InputMaybe<EventObjectTypeKind>;
};

export enum FoParticipantTypeInputControlsKind {
  Dropdown = 'DROPDOWN',
  Radio = 'RADIO'
}

export type FrontofficeConfig = {
  __typename?: 'FrontofficeConfig';
  choose_new_password_page?: Maybe<ChooseNewPasswordPageConfig>;
  connected_participant_page?: Maybe<ConnectedParticipantPageUiConfig>;
  connections_page?: Maybe<ConnectionsPageUiConfig>;
  contact_us_page?: Maybe<ContactUsPageConfig>;
  custom_domain_name?: Maybe<Scalars['String']>;
  customer_frontend_urls?: Maybe<Array<CustomerFrontendUrl>>;
  dashboard_page?: Maybe<DashboardPageUiConfig>;
  forgot_password_page?: Maybe<SecurityPageConfig>;
  global_settings?: Maybe<GlobalConfig>;
  landing_page?: Maybe<LandingPageConfig>;
  onboarding_page?: Maybe<IOnboardingPageUiConfig>;
  participant_account_page?: Maybe<ParticipantAccountPageConfig>;
  rdv_fo_enabled?: Maybe<Scalars['Boolean']>;
  reset_password_page?: Maybe<ResetPasswordPageConfig>;
  sign_in_page?: Maybe<SignInPageConfig>;
  sign_up_page?: Maybe<SignUpPageUiConfig>;
  verify_participant_account_page?: Maybe<VerifyParticipantAccountPageConfig>;
};

export type FrontofficeConfigInput = {
  choose_new_password_page?: InputMaybe<ChooseNewPasswordPageConfigInput>;
  connected_participant_page?: InputMaybe<ConnectedParticipantPageUiConfigInput>;
  connections_page?: InputMaybe<ConnectionsPageUiConfigInput>;
  contact_us_page?: InputMaybe<ContactUsPageConfigInput>;
  custom_domain_name?: InputMaybe<Scalars['String']>;
  customer_frontend_urls?: InputMaybe<Array<CustomerFrontendUrlInput>>;
  dashboard_page?: InputMaybe<DashboardPageUiConfigInput>;
  forgot_password_page?: InputMaybe<SecurityPageConfigInput>;
  global_settings?: InputMaybe<GlobalConfigInput>;
  landing_page?: InputMaybe<LandingPageConfigInput>;
  onboarding_page?: InputMaybe<IOnboardingPageUiConfigInput>;
  participant_account_page?: InputMaybe<ParticipantAccountPageConfigInput>;
  rdv_fo_enabled?: InputMaybe<Scalars['Boolean']>;
  reset_password_page?: InputMaybe<ResetPasswordPageConfigInput>;
  sign_in_page?: InputMaybe<SignInPageConfigInput>;
  sign_up_page?: InputMaybe<SignUpPageUiConfigInput>;
  verify_participant_account_page?: InputMaybe<VerifyParticipantAccountPageConfigInput>;
};

export type FrontofficeConfiguration = {
  __typename?: 'FrontofficeConfiguration';
  custom_domain_name?: Maybe<Scalars['String']>;
  customer_frontend_urls?: Maybe<Array<CustomerFrontendUrl>>;
  integration_providers: Array<IntegrationProviderLight>;
  marketplace_display_name: Scalars['String'];
  marketplace_name: Scalars['String'];
  participant_types: Array<ParticipantType>;
  public_key: Scalars['String'];
  rdv_fo_enabled?: Maybe<Scalars['Boolean']>;
  ui_config: FrontofficeUiConfig;
};

export type FrontofficeFormFieldConfig = {
  __typename?: 'FrontofficeFormFieldConfig';
  label?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
};

export type FrontofficeFormFieldConfigInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FrontofficeParticipantTypeFieldConfig = {
  __typename?: 'FrontofficeParticipantTypeFieldConfig';
  input_control?: Maybe<FoParticipantTypeInputControlsKind>;
  label?: Maybe<Scalars['String']>;
};

export type FrontofficeParticipantTypeFieldConfigInput = {
  input_control?: InputMaybe<FoParticipantTypeInputControlsKind>;
  label?: InputMaybe<Scalars['String']>;
};

export type FrontofficeRedirectToTextConfig = {
  __typename?: 'FrontofficeRedirectToTextConfig';
  link_label?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type FrontofficeRedirectToTextConfigInput = {
  link_label?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type FrontofficeUiConfig = {
  __typename?: 'FrontofficeUIConfig';
  choose_new_password_page?: Maybe<ChooseNewPasswordPageConfig>;
  connected_participant_page?: Maybe<ConnectedParticipantPageUiConfig>;
  connections_page?: Maybe<ConnectionsPageUiConfig>;
  contact_us_page?: Maybe<ContactUsPageConfig>;
  custom_domain_name?: Maybe<Scalars['String']>;
  customer_frontend_urls?: Maybe<Array<CustomerFrontendUrl>>;
  dashboard_page?: Maybe<DashboardPageUiConfig>;
  forgot_password_page?: Maybe<SecurityPageConfig>;
  global_settings?: Maybe<GlobalConfig>;
  landing_page?: Maybe<LandingPageConfig>;
  onboarding_page?: Maybe<IOnboardingPageUiConfig>;
  participant_account_page?: Maybe<ParticipantAccountPageConfig>;
  rdv_fo_enabled?: Maybe<Scalars['Boolean']>;
  reset_password_page?: Maybe<ResetPasswordPageConfig>;
  sign_in_page?: Maybe<SignInPageConfig>;
  sign_up_page?: Maybe<SignUpPageUiConfig>;
  verify_participant_account_page?: Maybe<VerifyParticipantAccountPageConfig>;
};

/** Point in geographical coordinates: latitude and longitude. */
export type GeoPoint = {
  /** Latitude ranges between -90 and 90 degrees, inclusive. Values above or below this range will be clamped to the range [-90, 90]. This means that if the value specified is less than -90, it will be set to -90. And if the value is greater than 90, it will be set to 90 */
  lat: Scalars['Latitude'];
  /** Longitude ranges between -180 and 180 degrees, inclusive. Values above or below this range will be wrapped so that they fall within the range. For example, a value of -190 will be converted to -180. A value of 190 will be converted to 180. */
  lng: Scalars['Longitude'];
};

export type GlobalConfig = {
  __typename?: 'GlobalConfig';
  external_links?: Maybe<GlobalConfigExternalLinks>;
  favicon_url?: Maybe<Scalars['String']>;
  logo_in_header?: Maybe<Scalars['Boolean']>;
  logo_url?: Maybe<Scalars['String']>;
  marketplace_name_in_header?: Maybe<Scalars['Boolean']>;
  primary_color?: Maybe<Scalars['String']>;
  primary_slogan?: Maybe<Scalars['String']>;
  secondary_color?: Maybe<Scalars['String']>;
  secondary_slogan?: Maybe<Scalars['String']>;
  sign_up_claim?: Maybe<Scalars['String']>;
};

export type GlobalConfigExternalLinks = {
  __typename?: 'GlobalConfigExternalLinks';
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  imprint?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  privacy?: Maybe<Scalars['String']>;
  support?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
};

export type GlobalConfigExternalLinksInput = {
  email?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  imprint?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  privacy?: InputMaybe<Scalars['String']>;
  support?: InputMaybe<Scalars['String']>;
  terms?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
};

export type GlobalConfigInput = {
  external_links?: InputMaybe<GlobalConfigExternalLinksInput>;
  id_file_favicon?: InputMaybe<Scalars['ID']>;
  id_file_logo?: InputMaybe<Scalars['ID']>;
  logo_in_header?: InputMaybe<Scalars['Boolean']>;
  marketplace_name_in_header?: InputMaybe<Scalars['Boolean']>;
  primary_color?: InputMaybe<Scalars['String']>;
  primary_slogan?: InputMaybe<Scalars['String']>;
  secondary_color?: InputMaybe<Scalars['String']>;
  secondary_slogan?: InputMaybe<Scalars['String']>;
  sign_up_claim?: InputMaybe<Scalars['String']>;
};

export type HeroSectionConfig = {
  __typename?: 'HeroSectionConfig';
  image_url?: Maybe<Scalars['String']>;
};

export type HeroSectionConfigInput = {
  id_file_image?: InputMaybe<Scalars['String']>;
};

export type HowItWorksSectionConfig = {
  __typename?: 'HowItWorksSectionConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  image_1_url?: Maybe<Scalars['String']>;
  image_2_url?: Maybe<Scalars['String']>;
  image_3_url?: Maybe<Scalars['String']>;
  step_1_description?: Maybe<Scalars['String']>;
  step_1_name?: Maybe<Scalars['String']>;
  step_1_title?: Maybe<Scalars['String']>;
  step_2_description?: Maybe<Scalars['String']>;
  step_2_name?: Maybe<Scalars['String']>;
  step_2_title?: Maybe<Scalars['String']>;
  step_3_description?: Maybe<Scalars['String']>;
  step_3_name?: Maybe<Scalars['String']>;
  step_3_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type HowItWorksSectionConfigInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id_file_image_1?: InputMaybe<Scalars['String']>;
  id_file_image_2?: InputMaybe<Scalars['String']>;
  id_file_image_3?: InputMaybe<Scalars['String']>;
  step_1_description?: InputMaybe<Scalars['String']>;
  step_1_name?: InputMaybe<Scalars['String']>;
  step_1_title?: InputMaybe<Scalars['String']>;
  step_2_description?: InputMaybe<Scalars['String']>;
  step_2_name?: InputMaybe<Scalars['String']>;
  step_2_title?: InputMaybe<Scalars['String']>;
  step_3_description?: InputMaybe<Scalars['String']>;
  step_3_name?: InputMaybe<Scalars['String']>;
  step_3_title?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type IOnboardingPageUiConfig = {
  __typename?: 'IOnboardingPageUiConfig';
  title?: Maybe<Scalars['String']>;
};

export type IOnboardingPageUiConfigInput = {
  title?: InputMaybe<Scalars['String']>;
};

export enum ImagePositionKind {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type ImportSupplyDataTemplate = {
  __typename?: 'ImportSupplyDataTemplate';
  created_at: Scalars['DateTimeISO'];
  data_format_option: DataFormatOption;
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  template: ConfigFile;
  version: Scalars['String'];
};

export enum InputControlKind {
  Asset = 'ASSET',
  AssetGallery = 'ASSET_GALLERY',
  AssetList = 'ASSET_LIST',
  Checkbox = 'CHECKBOX',
  DatetimePicker = 'DATETIME_PICKER',
  DatePicker = 'DATE_PICKER',
  Dropdown = 'DROPDOWN',
  Location = 'LOCATION',
  NumberEditor = 'NUMBER_EDITOR',
  Radio = 'RADIO',
  Switch = 'SWITCH',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA'
}

export enum InputKind {
  AutoincId = 'AUTOINC_ID',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  DateSet = 'DATE_SET',
  Decimal = 'DECIMAL',
  Document = 'DOCUMENT',
  DocumentSet = 'DOCUMENT_SET',
  Image = 'IMAGE',
  ImageSet = 'IMAGE_SET',
  Integer = 'INTEGER',
  Location = 'LOCATION',
  MonetaryValue = 'MONETARY_VALUE',
  MultiSelect = 'MULTI_SELECT',
  Object = 'OBJECT',
  ObjectSet = 'OBJECT_SET',
  Participant = 'PARTICIPANT',
  ParticipantSet = 'PARTICIPANT_SET',
  RichText = 'RICH_TEXT',
  Select = 'SELECT',
  SmartText = 'SMART_TEXT',
  SmartTextSet = 'SMART_TEXT_SET',
  Text = 'TEXT',
  TextSet = 'TEXT_SET'
}

/** Filter operators for an integer value. Among others, used by InputKind.Integer, InputKind.MonetaryValue, and InputKind.AutInc. */
export type IntegerFilter = {
  /** Checks if the value is equal against provided value. */
  equals?: InputMaybe<Scalars['Int']>;
  /** Checks if the value is greater than provided value */
  gt?: InputMaybe<Scalars['Int']>;
  /** Checks if the value is greater or equal than provided value */
  gte?: InputMaybe<Scalars['Int']>;
  /** Checks value against null. If value is set, then it is not null. */
  is_set?: InputMaybe<Scalars['Boolean']>;
  /** Checks if the value is less than provided value */
  lt?: InputMaybe<Scalars['Int']>;
  /** Checks if the value is less than or equal than provided value */
  lte?: InputMaybe<Scalars['Int']>;
  /** Checks if the value is not equal against provided value. */
  not?: InputMaybe<Scalars['Int']>;
};

export type IntegrationProvider = {
  __typename?: 'IntegrationProvider';
  data: Scalars['JSON'];
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  type: IntegrationProviderKind;
};

export enum IntegrationProviderKind {
  Email = 'EMAIL',
  Payment = 'PAYMENT'
}

export type IntegrationProviderLight = {
  __typename?: 'IntegrationProviderLight';
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  type: IntegrationProviderKind;
};

export enum IntegrationProviders {
  Sendinblue = 'SENDINBLUE',
  Stripe = 'STRIPE'
}

export type LandingPageConfig = {
  __typename?: 'LandingPageConfig';
  about: AboutSectionConfig;
  enabled?: Maybe<Scalars['Boolean']>;
  hero: HeroSectionConfig;
  how_it_works?: Maybe<HowItWorksSectionConfig>;
  unique_selling_points: UniqueSellingPointsSectionConfig;
};

export type LandingPageConfigInput = {
  about?: InputMaybe<AboutSectionConfigInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  hero?: InputMaybe<HeroSectionConfigInput>;
  how_it_works?: InputMaybe<HowItWorksSectionConfigInput>;
  unique_selling_points?: InputMaybe<UniqueSellingPointsSectionConfigInput>;
};

/** Filter operators for InputKind.LOCATION. */
export type LocationFilter = {
  /** Matched are all locations that fall into the circle defined by the location point and the distance measured in meters. */
  radius?: InputMaybe<RadiusFilter>;
};

export enum MarketplaceEnvironmentKind {
  Production = 'PRODUCTION',
  Sandbox = 'SANDBOX'
}

export type Match = {
  __typename?: 'Match';
  backoffice_approved_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_rejected_at?: Maybe<Scalars['DateTimeISO']>;
  canceled_at?: Maybe<Scalars['DateTimeISO']>;
  closed_at?: Maybe<Scalars['DateTimeISO']>;
  consumer: Participant;
  consumer_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_declined_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_unlocked_to_provider?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['DateTimeISO'];
  created_by?: Maybe<User>;
  end_date?: Maybe<Scalars['DateTimeISO']>;
  end_datetime?: Maybe<Scalars['DateTimeISO']>;
  fields: Array<Maybe<Field>>;
  id: Scalars['ID'];
  matching_score?: Maybe<Scalars['Int']>;
  matching_tool?: Maybe<MatchingTool>;
  my_role?: Maybe<MatchTypeRoleKind>;
  paid_at?: Maybe<Scalars['DateTimeISO']>;
  price?: Maybe<SupplyPrice>;
  price_override_unlocked?: Maybe<Scalars['Boolean']>;
  provider: Participant;
  provider_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  provider_declined_at?: Maybe<Scalars['DateTimeISO']>;
  provider_unlocked_to_consumer?: Maybe<Scalars['Boolean']>;
  /** @deprecated Not used any more. Use price.qty instead */
  quantity?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['DateTimeISO']>;
  start_datetime?: Maybe<Scalars['DateTimeISO']>;
  status: MatchStatusKind;
  supply?: Maybe<Supply>;
  supply_unlocked_to_consumer?: Maybe<Scalars['Boolean']>;
  type: MatchType;
  unlocked_consumer_fields: Array<Scalars['ID']>;
  unlocked_provider_fields: Array<Scalars['ID']>;
  unlocked_supply_fields: Array<Scalars['ID']>;
  unlocked_to_consumer: Scalars['Boolean'];
  unlocked_to_provider: Scalars['Boolean'];
  user_actions: Array<UserAction>;
};

export type MatchConfiguration = {
  __typename?: 'MatchConfiguration';
  add_ons: Array<AddOn>;
  availability_management_type: AvailabilityManagementKind;
  availability_tracking_schemes: Array<AvailabilityTrackingScheme>;
  availability_tracking_type?: Maybe<AvailabilityTrackingKind>;
  discounts: Array<Discount>;
  id: Scalars['ID'];
  match_parameter_types: Array<FieldParameterType>;
  match_restrictions: Array<MatchRestriction>;
  matching_unit_type: MatchingUnitKind;
  name: Scalars['String'];
  platform_fees: Array<PlatformFee>;
  pricing_enabled: Scalars['Boolean'];
  pricing_rules: Array<PricingRule>;
  taxes: Array<Tax>;
  tech_name: Scalars['String'];
};

export enum MatchFieldAccessClientKind {
  Consumer = 'CONSUMER',
  Provider = 'PROVIDER'
}

export type MatchFilter = {
  id_consumer?: InputMaybe<Array<Scalars['ID']>>;
  id_match_type?: InputMaybe<Array<Scalars['ID']>>;
  id_matching_tool?: InputMaybe<Scalars['ID']>;
  id_provider?: InputMaybe<Array<Scalars['ID']>>;
  id_supply?: InputMaybe<Array<Scalars['ID']>>;
  my_role?: InputMaybe<MatchTypeRoleKind>;
  status?: InputMaybe<Array<MatchStatusKind>>;
};

export type MatchRestriction = {
  __typename?: 'MatchRestriction';
  expression: Scalars['String'];
  name: Scalars['String'];
  tech_name: Scalars['String'];
};

export type MatchRestrictionInput = {
  expression: Scalars['String'];
  name: Scalars['String'];
  tech_name: Scalars['String'];
};

export enum MatchStatusKind {
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Declined = 'DECLINED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type MatchType = {
  __typename?: 'MatchType';
  allow_consumer_guests: Scalars['Boolean'];
  allow_provider_guests: Scalars['Boolean'];
  auto_backoffice_approval: Scalars['Boolean'];
  auto_consumer_acceptance: Scalars['Boolean'];
  auto_provider_acceptance: Scalars['Boolean'];
  availability_management_type: AvailabilityManagementKind;
  consumer_discovery_enabled: Scalars['Boolean'];
  consumer_type: ParticipantType;
  fields: Array<FieldType>;
  id: Scalars['ID'];
  initial_match_visibility: MatchVisibilityInfo;
  matching_tool_types: Array<MatchingToolType>;
  matching_unit_type: MatchingUnitKind;
  my_role?: Maybe<MatchTypeRoleKind>;
  name: Scalars['String'];
  provider_discovery_enabled: Scalars['Boolean'];
  provider_type: ParticipantType;
  retired_at?: Maybe<Scalars['DateTimeISO']>;
  supply_discovery_enabled: Scalars['Boolean'];
  supply_type?: Maybe<SupplyType>;
  type_visibility: MatchTypeVisibilityInfo;
};

export type MatchTypeLight = {
  __typename?: 'MatchTypeLight';
  consumer_discovery_enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  id_consumer_type: Scalars['ID'];
  id_provider_type: Scalars['ID'];
  id_supply_type?: Maybe<Scalars['ID']>;
  matching_tool_types: Array<MatchingToolType>;
  my_role?: Maybe<MatchTypeRoleKind>;
  name: Scalars['String'];
  provider_discovery_enabled: Scalars['Boolean'];
  supply_discovery_enabled: Scalars['Boolean'];
};

export enum MatchTypeRoleKind {
  Backoffice = 'BACKOFFICE',
  Consumer = 'CONSUMER',
  ConsumerOrProvider = 'CONSUMER_OR_PROVIDER',
  Guest = 'GUEST',
  Provider = 'PROVIDER'
}

export type MatchTypeVisibilityInfo = {
  __typename?: 'MatchTypeVisibilityInfo';
  consumer_fields?: Maybe<Array<Scalars['ID']>>;
  consumer_fields_out_for_guest?: Maybe<Array<Scalars['ID']>>;
  consumer_fields_out_for_provider?: Maybe<Array<Scalars['ID']>>;
  match_fields?: Maybe<Array<Scalars['ID']>>;
  provider_fields?: Maybe<Array<Scalars['ID']>>;
  provider_fields_out_for_consumer?: Maybe<Array<Scalars['ID']>>;
  provider_fields_out_for_guest?: Maybe<Array<Scalars['ID']>>;
  supply_fields?: Maybe<Array<Scalars['ID']>>;
  supply_fields_out_for_consumer?: Maybe<Array<Scalars['ID']>>;
  supply_fields_out_for_guest?: Maybe<Array<Scalars['ID']>>;
};

export type MatchTypeVisibilityInfoInput = {
  consumer_fields?: InputMaybe<Array<Scalars['ID']>>;
  consumer_fields_out_for_guest?: InputMaybe<Array<Scalars['ID']>>;
  consumer_fields_out_for_provider?: InputMaybe<Array<Scalars['ID']>>;
  match_fields?: InputMaybe<Array<Scalars['ID']>>;
  provider_fields?: InputMaybe<Array<Scalars['ID']>>;
  provider_fields_out_for_consumer?: InputMaybe<Array<Scalars['ID']>>;
  provider_fields_out_for_guest?: InputMaybe<Array<Scalars['ID']>>;
  supply_fields?: InputMaybe<Array<Scalars['ID']>>;
  supply_fields_out_for_consumer?: InputMaybe<Array<Scalars['ID']>>;
  supply_fields_out_for_guest?: InputMaybe<Array<Scalars['ID']>>;
};

export type MatchVisibilityInfo = {
  __typename?: 'MatchVisibilityInfo';
  consumer_by_provider?: Maybe<Scalars['Boolean']>;
  consumer_fields?: Maybe<Array<Scalars['ID']>>;
  match_by_consumer?: Maybe<Scalars['Boolean']>;
  match_by_provider?: Maybe<Scalars['Boolean']>;
  provider_by_consumer?: Maybe<Scalars['Boolean']>;
  provider_fields?: Maybe<Array<Scalars['ID']>>;
  supply_by_consumer?: Maybe<Scalars['Boolean']>;
  supply_fields?: Maybe<Array<Scalars['ID']>>;
};

export type MatchVisibilityInfoInput = {
  consumer_by_provider?: InputMaybe<Scalars['Boolean']>;
  consumer_fields?: InputMaybe<Array<Scalars['ID']>>;
  match_by_consumer?: InputMaybe<Scalars['Boolean']>;
  match_by_provider?: InputMaybe<Scalars['Boolean']>;
  provider_by_consumer?: InputMaybe<Scalars['Boolean']>;
  provider_fields?: InputMaybe<Array<Scalars['ID']>>;
  supply_by_consumer?: InputMaybe<Scalars['Boolean']>;
  supply_fields?: InputMaybe<Array<Scalars['ID']>>;
};

/** DEPRECATED: TO BE REMOVED */
export type MatchingTool = {
  __typename?: 'MatchingTool';
  /** @deprecated This att is not used any more. Use fields instead */
  availability?: Maybe<AvailabilityRecord>;
  consumer_filter?: Maybe<Array<FieldFilter>>;
  created_at: Scalars['DateTimeISO'];
  created_by: User;
  creator: Participant;
  fields: Array<Maybe<Field>>;
  id: Scalars['ID'];
  ids_supplies: Array<Scalars['ID']>;
  is_active: Scalars['Boolean'];
  last_execution_at?: Maybe<Scalars['DateTimeISO']>;
  matches: Array<Match>;
  name: Scalars['String'];
  provider_filter?: Maybe<Array<FieldFilter>>;
  supply_filter?: Maybe<Array<FieldFilter>>;
  type: MatchingToolType;
  user_actions: Array<UserAction>;
};

export enum MatchingToolKind {
  Auction = 'AUCTION',
  DirectMatcher = 'DIRECT_MATCHER',
  MatchingOrder = 'MATCHING_ORDER'
}

export type MatchingToolType = {
  __typename?: 'MatchingToolType';
  config?: Maybe<Scalars['JSONObject']>;
  execution_type: TaskExecutionKind;
  fields: Array<FieldType>;
  id: Scalars['ID'];
  initially_unlocked_to_consumer: Scalars['Boolean'];
  initially_unlocked_to_provider: Scalars['Boolean'];
  match_type: MatchType;
  name: Scalars['String'];
  retired_at?: Maybe<Scalars['DateTimeISO']>;
  type: MatchingToolKind;
};

export enum MatchingToolTypeRoleKind {
  Creator = 'CREATOR',
  NoCreator = 'NO_CREATOR'
}

export enum MatchingUnitKind {
  PerDay = 'PER_DAY',
  PerEvent = 'PER_EVENT',
  PerHour = 'PER_HOUR',
  PerUnit = 'PER_UNIT'
}

export type MerchantAccount = {
  __typename?: 'MerchantAccount';
  data?: Maybe<Scalars['JSONObject']>;
  id: Scalars['ID'];
  id_psp_account: Scalars['String'];
  payment_provider_kind: IntegrationProviders;
};

export type MigrationOption = {
  __typename?: 'MigrationOption';
  option: MigrationOptionKind;
  title: Scalars['String'];
};

export type MigrationOptionChoice = {
  __typename?: 'MigrationOptionChoice';
  option: MigrationOptionKind;
  payload?: Maybe<Scalars['JSONObject']>;
};

export type MigrationOptionChoiceInput = {
  option: MigrationOptionKind;
  payload?: InputMaybe<Scalars['JSONObject']>;
};

export enum MigrationOptionKind {
  CreateFieldsAndLeaveUnset = 'CREATE_FIELDS_AND_LEAVE_UNSET',
  CreateFieldsAndRequestProvidingFieldValue = 'CREATE_FIELDS_AND_REQUEST_PROVIDING_FIELD_VALUE',
  DeleteAffectedObjects = 'DELETE_AFFECTED_OBJECTS',
  RequestProvidingFieldValue = 'REQUEST_PROVIDING_FIELD_VALUE'
}

export type ModifiedFlowType = {
  __typename?: 'ModifiedFlowType';
  id_flow_type: Scalars['ID'];
  new_states: Array<StateType>;
  old_states: Array<ModifiedStateRecord>;
};

export type ModifiedStateRecord = {
  __typename?: 'ModifiedStateRecord';
  id_state: Scalars['ID'];
  is_deleted: Scalars['Boolean'];
  name_state: Scalars['String'];
};

export type MonetaryValue = {
  __typename?: 'MonetaryValue';
  amount: Scalars['Int'];
  currency: CurrencyKind;
};

export type MonetaryValueInput = {
  amount: Scalars['Int'];
  currency: CurrencyKind;
};

export type MultiDirectTransactionFilterForBo = {
  id_transaction_type: Scalars['ID'];
  status?: InputMaybe<FlowStatusFilterForBo>;
};

/** Filter operators for InputKind.MultiSelect */
export type MultiSelectFilter = {
  /** Checks if the value is equal against provided values. All values have to match and no additional value is allowed. */
  equals?: InputMaybe<Array<Scalars['String']>>;
  /** Checks if the value contains one or more of provided values. The in operator is a shorthand for multiple OR logical operators. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Checks value against null and empty array. If value is set, then it is neither null nor empty array. It is an array containing at least one value. */
  is_set?: InputMaybe<Scalars['Boolean']>;
  /** Checks if the value is not equal against provided value. None of the values should should match, but an additional value might exist. */
  not?: InputMaybe<Array<Scalars['String']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptConnection: Scalars['Boolean'];
  acceptTransactedMatch: Scalars['Boolean'];
  activateConnectionType: ConnectionType;
  activateFlowType: FlowType;
  activateImportSupplyDataTemplate: Scalars['Boolean'];
  activateIntegrationProvider: Scalars['Boolean'];
  activateNotificationType: Scalars['Boolean'];
  activateSupply: Supply;
  activateTransactionType: TransactionType;
  /** Adds a dynamic field (field which is not defined in the supply type) to the provided supply instance if supply type allows dynamic fields. To be allowed to add dynamic field which is a variant identifier (a.k.a. variant dimension) or a variant specific field, the underlying supply type has to allow both dynamic fields and dynamic variant fields. If you add a dynamic variant dimension (is_variant_identifier is set to true) to a supply which does not have variants, that supply will be upgraded with a variant group.  */
  addDynamicFieldToSupply: Supply;
  addFieldTypeTo: FieldType;
  addIntegrationProvider: IntegrationProvider;
  addNotificationType: NotificationType;
  addParticipantsToSegment: Scalars['Boolean'];
  addPlatformCurrency: Array<CurrencyKind>;
  addStateTypeToFlowType: StateType;
  addSupplyToShoppingCart: Scalars['Boolean'];
  addTransactionCostAdjustmentOnBehalfOf: CostAdjustment;
  addTransitionTypeToFlowType: TransitionType;
  addWebhookNotificationType: NotificationType;
  approveConnection: Scalars['Boolean'];
  approveMatch: Scalars['Boolean'];
  approveParticipant: Scalars['Boolean'];
  approveSupply: Supply;
  attachEnrichedImportTemplate?: Maybe<Scalars['Boolean']>;
  buildInitialPlatform: PlatformOwnerOnboardingProgress;
  cancelDeployment: Deployment;
  cancelOwnerOnboardingProgress: PlatformOwnerOnboardingProgress;
  cancelTransaction: TransactionLight;
  closeMyParticipantAccount: Scalars['Boolean'];
  createAPIUser: ApiUser;
  createCollection: Collection;
  createConnection: Connection;
  createConnectionType: ConnectionType;
  /** Creates another variant based on given variant (id_supply) that has dynamic dimensions (field which is not defined in the supply type and the attribute is_variant_identifier is set to true). New variant requires values for at least one dynamic variant dimension (is_variant_identifier is set to true) or dynamic variant specific (is_variant_specific is set to true) field. You can create a duplicate variant (same values for variant dimensions) if underlying supply type allows it. */
  createDynamicSupplyVariant: SupplyVariant;
  createEnvironmentVariable: EnvironmentVariable;
  createInvitedParticipant: User;
  createObjectType: ObjectType;
  createParticipantSegment: ParticipantSegmentForOwner;
  createParticipantSegmentOnBehalfOf: ParticipantSegmentForBo;
  createParticipantSegmentType: ParticipantSegmentType;
  createParticipantType: ParticipantType;
  createPaymentType: PaymentType;
  /** You can invite members of your team to access your randevu.tech account. You must assign at least one role to each team member before you can invite them. Roles protect your sensitive information and restrict the actions team members can perform on your account. */
  createPlatformTeamMember: BackofficeUser;
  createSharedObject: Object;
  createShoppingCart: ShoppingCart;
  createSingleDirectTransaction: SingleDirectTransactionForConsumer;
  createSupply: Supply;
  createSupplyOnBehalfOfProvider: Supply;
  createSupplyType: SupplyType;
  /** Creates another variant in the given variant group. New variant requires values for variant dimensions (is_variant_identifier is set to true) and variant specific (is_variant_specific is set to true) fields. You can create a duplicate variant (same values for variant dimensions) if underlying supply type allows it. */
  createSupplyVariant: SupplyVariant;
  createSupplyVariantOnBehalfOfProvider: SupplyVariant;
  createTransactionType: TransactionType;
  deactivateConnectionType: ConnectionType;
  deactivateFlowType: FlowType;
  deactivateImportSupplyDataTemplate: Scalars['Boolean'];
  deactivateIntegrationProvider: Scalars['Boolean'];
  deactivateNotificationType: Scalars['Boolean'];
  deactivateSupply: Supply;
  deactivateTransactionType: TransactionType;
  declineConnection: Scalars['Boolean'];
  declineTransactedMatch: Scalars['Boolean'];
  deleteEnvironmentVariable: Scalars['Boolean'];
  deleteSharedObject: Scalars['Boolean'];
  executeDeployment: Deployment;
  flagTransferAsSucceeded: TransferForBo;
  forceConnection: ConnectionForBo;
  forceTransactedMatch: Scalars['ID'];
  forceTransactionTermination: Scalars['Boolean'];
  generateSupplyDataImportTemplate: Scalars['Boolean'];
  getGuestToken: Session;
  initiateTransaction: TransactionLight;
  inviteConsumerToForwardAuction: Scalars['Boolean'];
  inviteProviderToReverseAuction: Scalars['Boolean'];
  joinForwardAuction: Scalars['Boolean'];
  loginAPIUser: Session;
  loginBackoffice: Session;
  loginParticipant: Session;
  logoutMe: Scalars['Boolean'];
  moveStateType: StateType;
  /**
   * The mutation opens a dedicated virtual bank account (VBAN) for the given participant. The VBAN is opened and managed by the payment service provider you integrate with.
   * The VBAN is a payment method used for bank transfers (e.g. SEPA Bank transfer)
   */
  openVirtualBankAccount: Account;
  overrideTransactedMatchPrice: Scalars['Boolean'];
  overrideTransactedMatchPriceOnBehalfOfProvider: Scalars['Boolean'];
  prepareAndInitiateForwardAuctionTransaction: TransactionLight;
  prepareAndInitiateReverseAuctionTransaction: TransactionLight;
  /** @deprecated This service is deprecated. Please use createSingleDirectTransaction/startSingleDirectTransaction instead */
  prepareAndInitiateSingleDirectTransaction: TransactionLight;
  prepareConfigFileUpload: FileUpload;
  prepareDeployment: Deployment;
  prepareFileUpload: FileUpload;
  prepareTransaction: TransactionLight;
  prepareTransactionPayment: Payment;
  prepareTransactionPaymentOnBehalfOfConsumer: PaymentForBo;
  /** Hard deletes transactional data from the Sandbox environments */
  purgeSandboxData: Scalars['Boolean'];
  referParticipantUser: Referral;
  registerMarketplaceOwner: Scalars['Boolean'];
  registerParticipant: Scalars['Boolean'];
  rejectConnection: Scalars['Boolean'];
  rejectMatch: Scalars['Boolean'];
  rejectParticipant: Scalars['Boolean'];
  rejectSupply: Supply;
  removeFieldTypeFrom: Scalars['Boolean'];
  removeIntegrationProvider: Scalars['Boolean'];
  removeNotificationType: Scalars['Boolean'];
  removeParticipantsFromSegment: Scalars['Boolean'];
  removeShoppingCartItem: Scalars['Boolean'];
  removeStateType: Scalars['Boolean'];
  removeTransitionType: Scalars['Boolean'];
  reorderFieldTypesIn: Scalars['Boolean'];
  requestBackofficePasswordReset: Scalars['Boolean'];
  /** The mutation prepares platform account at payment service provider to await bank transfer. */
  requestBankTransferCheckout: Payment;
  requestMerchantAccountConnection: PaymentProviderConnection;
  requestParticipantPasswordReset: Scalars['Boolean'];
  requestPaymentCheckoutConnection: PaymentCheckoutConnection;
  requestPayout: Scalars['Boolean'];
  requestPayoutOnBehalfOf: Scalars['Boolean'];
  requestStripeDashboardSignInLink: Scalars['String'];
  requestSupplyDataImportJob: DataImportJobStatusReport;
  requestSupplyDataImportJobOnBehalfOf: DataImportJobStatusReport;
  resendUserVerificationEMail: Scalars['Boolean'];
  resetBackofficePassword: Scalars['Boolean'];
  resetParticipantPassword: Scalars['Boolean'];
  root?: Maybe<Scalars['String']>;
  sendTransfer: TransferForBo;
  setDefaultTransactionType: Scalars['Boolean'];
  startSingleDirectTransaction: SingleDirectTransactionForConsumer;
  triggerManualParticipantOnboardingTransition: Scalars['Boolean'];
  triggerManualSupplyOnboardingTransition: Scalars['Boolean'];
  triggerManualTransactionTransition: Scalars['Boolean'];
  unlockMatch: Scalars['Boolean'];
  updateCollection: Collection;
  updateCollectionOnBehalfOf: Collection;
  updateConnection: Connection;
  updateConnectionFieldAccess: Scalars['Boolean'];
  updateConnectionOnBehalfOf: ConnectionForBo;
  updateConnectionType: ConnectionType;
  updateDeployment: Deployment;
  updateEnvironmentVariable: EnvironmentVariable;
  updateFieldAccess: Scalars['Boolean'];
  updateFieldType: FieldType;
  updateFlowType: FlowType;
  updateFrontofficeConfiguration: FrontofficeConfig;
  updateIntegrationProvider: Scalars['Boolean'];
  updateMatch: Scalars['Boolean'];
  updateMatchConfigurationForSupplyType: MatchConfiguration;
  updateMatchFieldAccess: Scalars['Boolean'];
  updateMatchType: MatchType;
  updateMatchingTool: Scalars['Boolean'];
  updateMatchingToolType: MatchingToolType;
  updateMyEmail: Scalars['Boolean'];
  updateMyPassword: Scalars['Boolean'];
  updateMySimpleStockSupplyAvailability: SimpleStockAvailabilityData;
  updateNotificationType: NotificationType;
  updateObjectType: ObjectType;
  updateOwnerOnboardingProgress: PlatformOwnerOnboardingProgress;
  updateParticipant: Scalars['Boolean'];
  /** Allows BO user to modify access for the participants, so they access their own Participant object. Note that the access can be later on modified automatically via participant onboarding flow if the flow contains the logic of modifying field access. */
  updateParticipantFieldAccess: Scalars['Boolean'];
  updateParticipantSegment: ParticipantSegmentForOwner;
  updateParticipantSegmentOnBehalfOf: ParticipantSegmentForBo;
  updateParticipantSegmentType: ParticipantSegmentType;
  updateParticipantType: ParticipantType;
  updatePaymentServiceProvider: PaymentServiceProvider;
  updatePaymentType: PaymentType;
  updatePlatformAccount: PlatformAccount;
  updateRequestFieldAccess: Scalars['Boolean'];
  updateSharedObject: Scalars['Boolean'];
  updateShoppingCart: ShoppingCart;
  updateShoppingCartItem: Scalars['Boolean'];
  updateSimpleStockSupplyAvailabilityOnBehalfOfProvider: SimpleStockAvailabilityData;
  updateSingleDirectMatch: SingleDirectTransactionForConsumer;
  updateSingleDirectMatchForProvider: SingleDirectTransactionForProvider;
  updateSingleDirectMatchOnBehalfOf: SingleDirectTransactionForBo;
  updateSingleDirectTransaction: SingleDirectTransactionForConsumer;
  updateSingleDirectTransactionOnBehalfOf: SingleDirectTransactionForBo;
  updateStateType: StateType;
  updateSupply: Scalars['Boolean'];
  /** Allows BO user to modify access for the participants, so they access their own Supply object. Note that the access can be later on modified automatically via supply onboarding flow if the flow contains the logic of modifying field access. */
  updateSupplyFieldAccess: Scalars['Boolean'];
  updateSupplyType: SupplyType;
  updateTransactedMatch: Scalars['Boolean'];
  /** Allows BO user to modify access to Transactions and all involved objects within transaction context. Note that the access can be later on modified automatically via flow if the flow contains the logic of modifying field access. */
  updateTransactionFieldAccess: Scalars['Boolean'];
  updateTransactionOrder: Scalars['Boolean'];
  updateTransactionType: TransactionType;
  updateTransitionType: TransitionType;
  updateUser: Scalars['Boolean'];
  updateUserEmailOnBehalfOf: Scalars['Boolean'];
  verifyBackofficeUser: Scalars['Boolean'];
  verifyParticipant: Scalars['Boolean'];
};


export type MutationAcceptConnectionArgs = {
  id: Scalars['ID'];
};


export type MutationAcceptTransactedMatchArgs = {
  id_transaction: Scalars['ID'];
};


export type MutationActivateConnectionTypeArgs = {
  id: Scalars['ID'];
};


export type MutationActivateFlowTypeArgs = {
  id: Scalars['ID'];
};


export type MutationActivateImportSupplyDataTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationActivateIntegrationProviderArgs = {
  id: Scalars['ID'];
};


export type MutationActivateNotificationTypeArgs = {
  id: Scalars['ID'];
};


export type MutationActivateSupplyArgs = {
  id: Scalars['ID'];
};


export type MutationActivateTransactionTypeArgs = {
  id: Scalars['ID'];
};


export type MutationAddDynamicFieldToSupplyArgs = {
  dynamic_field: DynamicFieldTypeInput;
  id_supply: Scalars['ID'];
  is_variant_identifier?: Scalars['Boolean'];
  is_variant_specific?: Scalars['Boolean'];
};


export type MutationAddFieldTypeToArgs = {
  category?: InputMaybe<FieldCategoryKind>;
  conditions?: InputMaybe<Array<ConditionInput>>;
  id_parent: Scalars['ID'];
  initial_accesses?: InputMaybe<FieldAccessInfoInput>;
  initial_value?: InputMaybe<Scalars['JSON']>;
  input_options?: InputMaybe<Scalars['JSONObject']>;
  input_type: InputKind;
  is_nullable?: InputMaybe<Scalars['Boolean']>;
  is_required?: InputMaybe<Scalars['Boolean']>;
  is_variant_identifier?: InputMaybe<Scalars['Boolean']>;
  is_variant_specific?: InputMaybe<Scalars['Boolean']>;
  matching_weight?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  parent_type: FieldTypeContainerKind;
  tech_name?: InputMaybe<Scalars['String']>;
  ui_config?: InputMaybe<FieldTypeUiConfigInput>;
  visible_to_guests?: InputMaybe<Scalars['Boolean']>;
  visible_to_participants?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAddIntegrationProviderArgs = {
  data: Scalars['JSONObject'];
  name: Scalars['String'];
  provider: IntegrationProviders;
  type: IntegrationProviderKind;
};


export type MutationAddNotificationTypeArgs = {
  channel: NotificationChannelKind;
  data?: InputMaybe<Scalars['JSONObject']>;
  description?: InputMaybe<Scalars['String']>;
  id_integration_provider?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  parameters?: Array<NotificationParameterInput>;
  recipients: Array<EMailNotificationRecipientInput>;
  where_event: Array<EventSelectorRecordInput>;
};


export type MutationAddParticipantsToSegmentArgs = {
  id_participant_segment: Scalars['ID'];
  ids_participants: Array<Scalars['ID']>;
};


export type MutationAddPlatformCurrencyArgs = {
  currency: CurrencyKind;
};


export type MutationAddStateTypeToFlowTypeArgs = {
  id_flow_type: Scalars['ID'];
  name: Scalars['String'];
  tech_name?: InputMaybe<Scalars['String']>;
  ui_config: Scalars['JSONObject'];
};


export type MutationAddSupplyToShoppingCartArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id_cart: Scalars['ID'];
  id_supply: Scalars['ID'];
};


export type MutationAddTransactionCostAdjustmentOnBehalfOfArgs = {
  adjustment: CostAdjustmentInput;
  id_consumer?: InputMaybe<Scalars['ID']>;
  id_provider?: InputMaybe<Scalars['ID']>;
  id_supply?: InputMaybe<Scalars['ID']>;
  id_transaction: Scalars['ID'];
};


export type MutationAddTransitionTypeToFlowTypeArgs = {
  id_flow_type: Scalars['ID'];
  id_from: Scalars['ID'];
  id_to: Scalars['ID'];
  name: Scalars['String'];
  tech_name?: InputMaybe<Scalars['String']>;
  triggering_conditions?: InputMaybe<Array<FlowConditionInput>>;
  triggering_event: RandevuEventKind;
};


export type MutationAddWebhookNotificationTypeArgs = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  target_url: Scalars['HttpURL'];
  where_event: Array<EventSelectorRecordInput>;
};


export type MutationApproveConnectionArgs = {
  id: Scalars['ID'];
};


export type MutationApproveMatchArgs = {
  id: Scalars['ID'];
};


export type MutationApproveParticipantArgs = {
  id: Scalars['ID'];
};


export type MutationApproveSupplyArgs = {
  id: Scalars['ID'];
};


export type MutationAttachEnrichedImportTemplateArgs = {
  id_data_import_template: Scalars['ID'];
  id_file: Scalars['ID'];
  id_supply_type: Scalars['ID'];
};


export type MutationBuildInitialPlatformArgs = {
  id_user: Scalars['ID'];
};


export type MutationCancelDeploymentArgs = {
  id: Scalars['ID'];
};


export type MutationCancelOwnerOnboardingProgressArgs = {
  id_user: Scalars['ID'];
};


export type MutationCancelTransactionArgs = {
  id_transaction: Scalars['ID'];
};


export type MutationCloseMyParticipantAccountArgs = {
  current_password: Scalars['String'];
  id_participant: Scalars['ID'];
};


export type MutationCreateApiUserArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationCreateCollectionArgs = {
  name: Scalars['String'];
};


export type MutationCreateConnectionArgs = {
  connection_tech_name: Scalars['String'];
  fields?: InputMaybe<Array<FieldInput>>;
  id_participant: Scalars['ID'];
};


export type MutationCreateConnectionTypeArgs = {
  end1_name?: InputMaybe<Scalars['String']>;
  end2_name?: InputMaybe<Scalars['String']>;
  id_end1_participant_type: Scalars['ID'];
  id_end2_participant_type: Scalars['ID'];
  name: Scalars['String'];
  tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateDynamicSupplyVariantArgs = {
  dynamic_variant_fields: Array<FieldInput>;
  id_supply: Scalars['ID'];
};


export type MutationCreateEnvironmentVariableArgs = {
  name: Scalars['String'];
  value: Scalars['String'];
};


export type MutationCreateInvitedParticipantArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};


export type MutationCreateObjectTypeArgs = {
  name: Scalars['String'];
  tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateParticipantSegmentArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  name: Scalars['String'];
  participant_segment_tech_name: Scalars['String'];
};


export type MutationCreateParticipantSegmentOnBehalfOfArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id_owner_participant: Scalars['ID'];
  id_participant_segment_type: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateParticipantSegmentTypeArgs = {
  id_item_participant_type: Scalars['ID'];
  id_owner_participant_type: Scalars['ID'];
  name: Scalars['String'];
  tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateParticipantTypeArgs = {
  account_type?: InputMaybe<AccountKind>;
  auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
  inviting_enabled?: InputMaybe<Scalars['Boolean']>;
  is_master_type?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationCreatePaymentTypeArgs = {
  name: Scalars['String'];
  tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationCreatePlatformTeamMemberArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  role: PlatformTeamRole;
};


export type MutationCreateSharedObjectArgs = {
  is_public: Scalars['Boolean'];
  tech_name: Scalars['String'];
};


export type MutationCreateShoppingCartArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  transaction_tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateSingleDirectTransactionArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id_supply?: InputMaybe<Scalars['ID']>;
  transaction_tech_name: Scalars['String'];
};


export type MutationCreateSupplyArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  match_conf_tech_name?: InputMaybe<Scalars['String']>;
  tech_name: Scalars['String'];
};


export type MutationCreateSupplyOnBehalfOfProviderArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id_provider: Scalars['ID'];
  match_conf_tech_name?: InputMaybe<Scalars['String']>;
  tech_name: Scalars['String'];
};


export type MutationCreateSupplyTypeArgs = {
  auto_activation?: InputMaybe<Scalars['Boolean']>;
  auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
  id_provider_type: Scalars['String'];
  matching_unit_type?: InputMaybe<MatchingUnitKind>;
  name: Scalars['String'];
  tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateSupplyVariantArgs = {
  tech_name: Scalars['String'];
  variant_fields?: InputMaybe<Array<FieldInput>>;
  variant_group: Scalars['String'];
};


export type MutationCreateSupplyVariantOnBehalfOfProviderArgs = {
  id_provider: Scalars['ID'];
  tech_name: Scalars['String'];
  variant_fields?: InputMaybe<Array<FieldInput>>;
  variant_group: Scalars['String'];
};


export type MutationCreateTransactionTypeArgs = {
  id_consumer_type: Scalars['ID'];
  id_supply_type: Scalars['ID'];
  name: Scalars['String'];
  tech_name: Scalars['String'];
  type: TransactionKind;
};


export type MutationDeactivateConnectionTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateFlowTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateImportSupplyDataTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateIntegrationProviderArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateNotificationTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateSupplyArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateTransactionTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeclineConnectionArgs = {
  id: Scalars['ID'];
};


export type MutationDeclineTransactedMatchArgs = {
  id_transaction: Scalars['ID'];
};


export type MutationDeleteEnvironmentVariableArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSharedObjectArgs = {
  id: Scalars['ID'];
};


export type MutationExecuteDeploymentArgs = {
  id: Scalars['ID'];
};


export type MutationFlagTransferAsSucceededArgs = {
  id: Scalars['ID'];
};


export type MutationForceConnectionArgs = {
  connection_tech_name: Scalars['String'];
  fields?: InputMaybe<Array<FieldInput>>;
  id_end1_participant: Scalars['ID'];
  id_end2_participant: Scalars['ID'];
};


export type MutationForceTransactedMatchArgs = {
  id_consumer: Scalars['ID'];
  id_provider: Scalars['ID'];
  id_supply: Scalars['ID'];
  id_transaction: Scalars['ID'];
  match_params: Array<FieldInput>;
};


export type MutationForceTransactionTerminationArgs = {
  id_transaction: Scalars['ID'];
};


export type MutationGenerateSupplyDataImportTemplateArgs = {
  data_format_option: DataFormatOptionInput;
  id_supply_type: Scalars['ID'];
  ids_field_type: Array<Scalars['ID']>;
  max_dynamic_fields?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
};


export type MutationInitiateTransactionArgs = {
  id_transaction: Scalars['ID'];
};


export type MutationInviteConsumerToForwardAuctionArgs = {
  id_consumer: Scalars['ID'];
  id_transaction: Scalars['ID'];
  match_params?: InputMaybe<Array<FieldInput>>;
};


export type MutationInviteProviderToReverseAuctionArgs = {
  id_provider: Scalars['ID'];
  id_supply?: InputMaybe<Scalars['ID']>;
  id_transaction: Scalars['ID'];
  match_params?: InputMaybe<Array<FieldInput>>;
};


export type MutationJoinForwardAuctionArgs = {
  id_supply: Scalars['ID'];
  id_transaction: Scalars['ID'];
  match_params?: InputMaybe<Array<FieldInput>>;
};


export type MutationLoginApiUserArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationLoginBackofficeArgs = {
  email: Scalars['String'];
  marketplace_name: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginParticipantArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationMoveStateTypeArgs = {
  id: Scalars['ID'];
  id_flow_type: Scalars['ID'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};


export type MutationOpenVirtualBankAccountArgs = {
  country_code: Scalars['CountryCode'];
  currency: CurrencyKind;
  id_integration_provider: Scalars['ID'];
  id_participant: Scalars['ID'];
};


export type MutationOverrideTransactedMatchPriceArgs = {
  id_transaction: Scalars['ID'];
  price: Scalars['JSONObject'];
};


export type MutationOverrideTransactedMatchPriceOnBehalfOfProviderArgs = {
  id_transaction: Scalars['ID'];
  price: Scalars['JSONObject'];
};


export type MutationPrepareAndInitiateForwardAuctionTransactionArgs = {
  consumer_filter?: InputMaybe<Array<FieldFilterInput>>;
  fields?: InputMaybe<Array<FieldInput>>;
  ids_supplies: Array<Scalars['ID']>;
  transaction_tech_name: Scalars['String'];
};


export type MutationPrepareAndInitiateReverseAuctionTransactionArgs = {
  consumer_filter?: InputMaybe<Array<FieldFilterInput>>;
  fields?: InputMaybe<Array<FieldInput>>;
  provider_filter?: InputMaybe<Array<FieldFilterInput>>;
  supply_filter?: InputMaybe<Array<FieldFilterInput>>;
  transaction_tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationPrepareAndInitiateSingleDirectTransactionArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id_supply: Scalars['ID'];
  transaction_tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationPrepareConfigFileUploadArgs = {
  description?: InputMaybe<Scalars['String']>;
  extension: Scalars['String'];
  file_name: Scalars['String'];
  is_public?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  type: FileKind;
};


export type MutationPrepareDeploymentArgs = {
  description: Scalars['String'];
  flow_migrations: Array<FlowMigrationInput>;
};


export type MutationPrepareFileUploadArgs = {
  description?: InputMaybe<Scalars['String']>;
  extension: Scalars['String'];
  file_name: Scalars['String'];
  is_public?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  type: FileKind;
};


export type MutationPrepareTransactionArgs = {
  transaction_tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationPrepareTransactionPaymentArgs = {
  id_transaction: Scalars['ID'];
  tech_name: Scalars['String'];
};


export type MutationPrepareTransactionPaymentOnBehalfOfConsumerArgs = {
  id_transaction: Scalars['ID'];
  tech_name: Scalars['String'];
};


export type MutationPurgeSandboxDataArgs = {
  keep_participants?: InputMaybe<Scalars['Boolean']>;
  keep_supplies?: InputMaybe<Scalars['Boolean']>;
};


export type MutationReferParticipantUserArgs = {
  email: Scalars['String'];
  fields?: InputMaybe<Array<FieldInput>>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  participant_tech_name: Scalars['String'];
};


export type MutationRegisterMarketplaceOwnerArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  marketplace_name: Scalars['String'];
};


export type MutationRegisterParticipantArgs = {
  email: Scalars['String'];
  fields?: InputMaybe<Array<FieldInput>>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  participant_tech_name: Scalars['String'];
};


export type MutationRejectConnectionArgs = {
  id: Scalars['ID'];
};


export type MutationRejectMatchArgs = {
  id: Scalars['ID'];
};


export type MutationRejectParticipantArgs = {
  id: Scalars['ID'];
};


export type MutationRejectSupplyArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveFieldTypeFromArgs = {
  id_field_type: Scalars['ID'];
  id_parent: Scalars['ID'];
  parent_type: FieldTypeContainerKind;
};


export type MutationRemoveIntegrationProviderArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveNotificationTypeArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveParticipantsFromSegmentArgs = {
  id_participant_segment: Scalars['ID'];
  ids_participants: Array<Scalars['ID']>;
};


export type MutationRemoveShoppingCartItemArgs = {
  id_cart: Scalars['ID'];
  id_item: Scalars['ID'];
};


export type MutationRemoveStateTypeArgs = {
  id: Scalars['ID'];
  id_flow_type: Scalars['ID'];
};


export type MutationRemoveTransitionTypeArgs = {
  id: Scalars['ID'];
  id_flow_type: Scalars['ID'];
};


export type MutationReorderFieldTypesInArgs = {
  id_parent: Scalars['ID'];
  parent_type: FieldTypeContainerKind;
  sorted_field_type_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationRequestBackofficePasswordResetArgs = {
  email: Scalars['String'];
  marketplace_name: Scalars['String'];
};


export type MutationRequestBankTransferCheckoutArgs = {
  id_payment: Scalars['ID'];
};


export type MutationRequestMerchantAccountConnectionArgs = {
  id_integration_provider: Scalars['String'];
  refresh_url?: InputMaybe<Scalars['String']>;
  return_url?: InputMaybe<Scalars['String']>;
};


export type MutationRequestParticipantPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationRequestPaymentCheckoutConnectionArgs = {
  cancel_url?: InputMaybe<Scalars['String']>;
  id_payment: Scalars['ID'];
  success_url?: InputMaybe<Scalars['String']>;
};


export type MutationRequestPayoutArgs = {
  id_merchant_account: Scalars['ID'];
};


export type MutationRequestPayoutOnBehalfOfArgs = {
  id_merchant_account: Scalars['ID'];
};


export type MutationRequestStripeDashboardSignInLinkArgs = {
  id_merchant_account: Scalars['ID'];
};


export type MutationRequestSupplyDataImportJobArgs = {
  auto_activate_supplies: Scalars['Boolean'];
  id_data_file: Scalars['ID'];
  id_template: Scalars['ID'];
  supply_tech_name: Scalars['String'];
};


export type MutationRequestSupplyDataImportJobOnBehalfOfArgs = {
  auto_activate_supplies: Scalars['Boolean'];
  id_data_file: Scalars['ID'];
  id_provider: Scalars['ID'];
  id_template: Scalars['ID'];
  tech_name: Scalars['String'];
};


export type MutationResendUserVerificationEMailArgs = {
  id_user: Scalars['ID'];
};


export type MutationResetBackofficePasswordArgs = {
  marketplace_name: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetParticipantPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSendTransferArgs = {
  id: Scalars['ID'];
};


export type MutationSetDefaultTransactionTypeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationStartSingleDirectTransactionArgs = {
  id: Scalars['ID'];
};


export type MutationTriggerManualParticipantOnboardingTransitionArgs = {
  id_participant: Scalars['ID'];
  transition_tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationTriggerManualSupplyOnboardingTransitionArgs = {
  id_supply: Scalars['ID'];
  transition_tech_name: Scalars['String'];
};


export type MutationTriggerManualTransactionTransitionArgs = {
  id_transaction: Scalars['ID'];
  transition_tech_name: Scalars['String'];
};


export type MutationUnlockMatchArgs = {
  id: Scalars['ID'];
  to: MatchTypeRoleKind;
};


export type MutationUpdateCollectionArgs = {
  id: Scalars['ID'];
  ids_supply?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCollectionOnBehalfOfArgs = {
  id: Scalars['ID'];
  ids_supply?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateConnectionArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id: Scalars['ID'];
};


export type MutationUpdateConnectionFieldAccessArgs = {
  client_type: ConnectionFieldAccessClientKind;
  field_tech_name: Scalars['String'];
  id_connection: Scalars['ID'];
  new_access: FieldAccessKind;
};


export type MutationUpdateConnectionOnBehalfOfArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id: Scalars['ID'];
};


export type MutationUpdateConnectionTypeArgs = {
  auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
  auto_end1_acceptance?: InputMaybe<Scalars['Boolean']>;
  auto_end2_acceptance?: InputMaybe<Scalars['Boolean']>;
  end1_discovery_enabled?: InputMaybe<Scalars['Boolean']>;
  end1_name?: InputMaybe<Scalars['String']>;
  end2_discovery_enabled?: InputMaybe<Scalars['Boolean']>;
  end2_name?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  initial_connection_visibility?: InputMaybe<ConnectionVisibilityInfoInput>;
  name?: InputMaybe<Scalars['String']>;
  tech_name?: InputMaybe<Scalars['String']>;
  type_visibility?: InputMaybe<ConnectionTypeVisibilityInfoInput>;
};


export type MutationUpdateDeploymentArgs = {
  description?: InputMaybe<Scalars['String']>;
  flow_migrations: Array<FlowMigrationInput>;
  id: Scalars['ID'];
};


export type MutationUpdateEnvironmentVariableArgs = {
  id: Scalars['ID'];
  value: Scalars['String'];
};


export type MutationUpdateFieldAccessArgs = {
  client_type: FieldAccessClientKind;
  field_tech_name: Scalars['String'];
  id_match_context?: InputMaybe<Scalars['ID']>;
  id_parent: Scalars['ID'];
  new_access: FieldAccessKind;
  parent_type: FieldContainerKind;
};


export type MutationUpdateFieldTypeArgs = {
  conditions?: InputMaybe<Array<ConditionInput>>;
  id_field_type: Scalars['ID'];
  id_parent: Scalars['ID'];
  initial_accesses?: InputMaybe<FieldAccessInfoInput>;
  initial_value?: InputMaybe<Scalars['JSON']>;
  input_options?: InputMaybe<Scalars['JSONObject']>;
  is_nullable?: InputMaybe<Scalars['Boolean']>;
  is_required?: InputMaybe<Scalars['Boolean']>;
  is_variant_identifier?: InputMaybe<Scalars['Boolean']>;
  is_variant_specific?: InputMaybe<Scalars['Boolean']>;
  matching_weight?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent_type: FieldTypeContainerKind;
  tech_name?: InputMaybe<Scalars['String']>;
  ui_config?: InputMaybe<FieldTypeUiConfigInput>;
  visible_to_guests?: InputMaybe<Scalars['Boolean']>;
  visible_to_participants?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateFlowTypeArgs = {
  id: Scalars['ID'];
  initiating_conditions?: InputMaybe<Array<FlowConditionInput>>;
  name?: InputMaybe<Scalars['String']>;
  var_types?: InputMaybe<Array<FlowVariableTypeInput>>;
};


export type MutationUpdateFrontofficeConfigurationArgs = {
  config_update: FrontofficeConfigInput;
};


export type MutationUpdateIntegrationProviderArgs = {
  data: Scalars['JSONObject'];
  id: Scalars['ID'];
};


export type MutationUpdateMatchArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id: Scalars['ID'];
};


export type MutationUpdateMatchConfigurationForSupplyTypeArgs = {
  add_ons?: InputMaybe<Array<AddOnInput>>;
  discounts?: InputMaybe<Array<DiscountInput>>;
  id: Scalars['ID'];
  id_supply_type: Scalars['ID'];
  match_parameter_types?: InputMaybe<Array<FieldParameterTypeInput>>;
  match_restrictions?: InputMaybe<Array<MatchRestrictionInput>>;
  name?: InputMaybe<Scalars['String']>;
  platform_fees?: InputMaybe<Array<PlatformFeeInput>>;
  pricing_enabled?: InputMaybe<Scalars['Boolean']>;
  pricing_rules?: InputMaybe<Array<PricingRuleInput>>;
  taxes?: InputMaybe<Array<TaxInput>>;
  tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMatchFieldAccessArgs = {
  client_type: MatchFieldAccessClientKind;
  field_tech_name: Scalars['String'];
  id_match: Scalars['ID'];
  new_access: FieldAccessKind;
};


export type MutationUpdateMatchTypeArgs = {
  allow_consumer_guests?: InputMaybe<Scalars['Boolean']>;
  allow_provider_guests?: InputMaybe<Scalars['Boolean']>;
  auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
  auto_consumer_acceptance?: InputMaybe<Scalars['Boolean']>;
  auto_provider_acceptance?: InputMaybe<Scalars['Boolean']>;
  availability_management_type?: InputMaybe<AvailabilityManagementKind>;
  consumer_discovery_enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  initial_match_visibility?: InputMaybe<MatchVisibilityInfoInput>;
  matching_unit_type?: InputMaybe<MatchingUnitKind>;
  name?: InputMaybe<Scalars['String']>;
  provider_discovery_enabled?: InputMaybe<Scalars['Boolean']>;
  supply_discovery_enabled?: InputMaybe<Scalars['Boolean']>;
  type_visibility?: InputMaybe<MatchTypeVisibilityInfoInput>;
  ui_config?: InputMaybe<Scalars['JSONObject']>;
};


export type MutationUpdateMatchingToolArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id: Scalars['ID'];
};


export type MutationUpdateMatchingToolTypeArgs = {
  config?: InputMaybe<Scalars['JSONObject']>;
  execution_type?: InputMaybe<TaskExecutionKind>;
  id: Scalars['ID'];
  initially_unlocked_to_consumer?: InputMaybe<Scalars['Boolean']>;
  initially_unlocked_to_provider?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<MatchingToolKind>;
};


export type MutationUpdateMyEmailArgs = {
  current_password: Scalars['String'];
  new_email: Scalars['String'];
};


export type MutationUpdateMyPasswordArgs = {
  current_password: Scalars['String'];
  new_password: Scalars['String'];
};


export type MutationUpdateMySimpleStockSupplyAvailabilityArgs = {
  id_supply: Scalars['ID'];
  remaining_qty: Scalars['Int'];
};


export type MutationUpdateNotificationTypeArgs = {
  data?: InputMaybe<Scalars['JSONObject']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  id_integration_provider?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  parameters?: InputMaybe<Array<NotificationParameterInput>>;
  recipients?: InputMaybe<Array<EMailNotificationRecipientInput>>;
  target_url?: InputMaybe<Scalars['HttpURL']>;
  where_event?: InputMaybe<Array<EventSelectorRecordInput>>;
};


export type MutationUpdateObjectTypeArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  shared_instances_enabled?: InputMaybe<Scalars['Boolean']>;
  tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateOwnerOnboardingProgressArgs = {
  answer: Scalars['JSON'];
  id_user: Scalars['ID'];
  step: Scalars['Int'];
};


export type MutationUpdateParticipantArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id: Scalars['ID'];
};


export type MutationUpdateParticipantFieldAccessArgs = {
  field_tech_name: Scalars['String'];
  id_participant: Scalars['ID'];
  new_access: FieldAccessKind;
};


export type MutationUpdateParticipantSegmentArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateParticipantSegmentOnBehalfOfArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateParticipantSegmentTypeArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateParticipantTypeArgs = {
  account_type?: InputMaybe<AccountKind>;
  auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inviting_enabled?: InputMaybe<Scalars['Boolean']>;
  is_master_type?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  tech_name?: InputMaybe<Scalars['String']>;
  ui_config?: InputMaybe<Scalars['JSONObject']>;
};


export type MutationUpdatePaymentServiceProviderArgs = {
  data: Scalars['JSONObject'];
  type: IntegrationProviders;
};


export type MutationUpdatePaymentTypeArgs = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  payment_methods?: InputMaybe<Array<PaymentMethodInput>>;
  tech_name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePlatformAccountArgs = {
  account_update: PlatformAccountInput;
};


export type MutationUpdateRequestFieldAccessArgs = {
  client_type: RequestFieldAccessClientKind;
  field_tech_name: Scalars['String'];
  id_transaction: Scalars['ID'];
  new_access: FieldAccessKind;
};


export type MutationUpdateSharedObjectArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id: Scalars['ID'];
};


export type MutationUpdateShoppingCartArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id_cart: Scalars['ID'];
};


export type MutationUpdateShoppingCartItemArgs = {
  fields: Array<FieldInput>;
  id_cart: Scalars['ID'];
  id_item: Scalars['ID'];
};


export type MutationUpdateSimpleStockSupplyAvailabilityOnBehalfOfProviderArgs = {
  id_supply: Scalars['ID'];
  remaining_qty: Scalars['Int'];
};


export type MutationUpdateSingleDirectMatchArgs = {
  id: Scalars['ID'];
  match_fields?: InputMaybe<Array<FieldInput>>;
};


export type MutationUpdateSingleDirectMatchForProviderArgs = {
  id: Scalars['ID'];
  match_fields?: InputMaybe<Array<FieldInput>>;
};


export type MutationUpdateSingleDirectMatchOnBehalfOfArgs = {
  id: Scalars['ID'];
  match_fields?: InputMaybe<Array<FieldInput>>;
};


export type MutationUpdateSingleDirectTransactionArgs = {
  id: Scalars['ID'];
  id_supply?: InputMaybe<Scalars['ID']>;
  request_fields?: InputMaybe<Array<FieldInput>>;
};


export type MutationUpdateSingleDirectTransactionOnBehalfOfArgs = {
  id: Scalars['ID'];
  id_supply?: InputMaybe<Scalars['ID']>;
  request_fields?: InputMaybe<Array<FieldInput>>;
};


export type MutationUpdateStateTypeArgs = {
  actions?: InputMaybe<Array<ActionTypeInput>>;
  id: Scalars['ID'];
  id_flow_type: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  tech_name?: InputMaybe<Scalars['String']>;
  ui_config?: InputMaybe<Scalars['JSONObject']>;
};


export type MutationUpdateSupplyArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id: Scalars['ID'];
};


export type MutationUpdateSupplyFieldAccessArgs = {
  field_tech_name: Scalars['String'];
  id_supply: Scalars['ID'];
  new_access: FieldAccessKind;
};


export type MutationUpdateSupplyTypeArgs = {
  allow_variant_duplicates?: InputMaybe<Scalars['Boolean']>;
  auto_activation?: InputMaybe<Scalars['Boolean']>;
  auto_backoffice_approval?: InputMaybe<Scalars['Boolean']>;
  auto_variants_onboarding?: InputMaybe<Scalars['Boolean']>;
  bulk_import_enabled?: InputMaybe<Scalars['Boolean']>;
  dynamic_fields_enabled?: InputMaybe<Scalars['Boolean']>;
  dynamic_variants_enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  tech_name?: InputMaybe<Scalars['String']>;
  ui_config?: InputMaybe<Scalars['JSONObject']>;
};


export type MutationUpdateTransactedMatchArgs = {
  fields?: InputMaybe<Array<FieldInput>>;
  id_transaction: Scalars['ID'];
};


export type MutationUpdateTransactionFieldAccessArgs = {
  client_type: FieldAccessClientKind;
  field_tech_name: Scalars['String'];
  id_transaction: Scalars['ID'];
  new_access: FieldAccessKind;
  parent_type: FieldContainerKind;
};


export type MutationUpdateTransactionOrderArgs = {
  consumer_filter?: InputMaybe<Array<FieldFilterInput>>;
  fields?: InputMaybe<Array<FieldInput>>;
  id_supply?: InputMaybe<Scalars['ID']>;
  id_transaction: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  provider_filter?: InputMaybe<Array<FieldFilterInput>>;
  supply_filter?: InputMaybe<Array<FieldFilterInput>>;
};


export type MutationUpdateTransactionTypeArgs = {
  cascading_termination?: InputMaybe<Scalars['Boolean']>;
  connection_scoped_discovery?: InputMaybe<Array<Scalars['ID']>>;
  forced_termination_enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ids_payment_types?: InputMaybe<Array<Scalars['ID']>>;
  is_multi_provider_enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order_configuration?: InputMaybe<OrderConfigurationInput>;
  tech_name?: InputMaybe<Scalars['String']>;
  ui_config?: InputMaybe<Scalars['JSONObject']>;
};


export type MutationUpdateTransitionTypeArgs = {
  actions?: InputMaybe<Array<ActionTypeInput>>;
  id: Scalars['ID'];
  id_flow_type: Scalars['ID'];
  id_from?: InputMaybe<Scalars['ID']>;
  id_to?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  tech_name?: InputMaybe<Scalars['String']>;
  triggering_conditions?: InputMaybe<Array<FlowConditionInput>>;
  triggering_event?: InputMaybe<RandevuEventKind>;
  ui_config?: InputMaybe<Scalars['JSONObject']>;
};


export type MutationUpdateUserArgs = {
  first_name?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  last_name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserEmailOnBehalfOfArgs = {
  id_user: Scalars['ID'];
  new_email: Scalars['String'];
};


export type MutationVerifyBackofficeUserArgs = {
  marketplace_name: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationVerifyParticipantArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MySupplyFilter = {
  id_collection?: InputMaybe<Scalars['ID']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<SupplyStatusKind>>;
  supply_tech_name?: InputMaybe<Scalars['String']>;
};

/** Filter to be applied to a field value */
export type NestedFieldFilter = {
  /** Tech name of a field to be used for filtering. */
  tech_name: Scalars['String'];
  /** Filter operator to be executed against the field. */
  value: FieldFilterOperator;
};

/** Action that is possible from the current flow state. */
export type NextStep = {
  __typename?: 'NextStep';
  action_message?: Maybe<Scalars['String']>;
  config: Scalars['JSONObject'];
  type: NextStepKind;
};

export enum NextStepKind {
  ManualTrigger = 'MANUAL_TRIGGER',
  ObjectLifecycle = 'OBJECT_LIFECYCLE',
  ObjectUpdate = 'OBJECT_UPDATE'
}

export type NoOnboardingProgress = {
  __typename?: 'NoOnboardingProgress';
  dummy?: Maybe<Scalars['Int']>;
};

export enum NotificationChannelKind {
  Email = 'EMAIL',
  Frontend = 'FRONTEND',
  PushNotification = 'PUSH_NOTIFICATION',
  Webhook = 'WEBHOOK'
}

export enum NotificationFieldParentKind {
  Connection = 'CONNECTION',
  ConnectionEnd_1 = 'CONNECTION_END_1',
  ConnectionEnd_2 = 'CONNECTION_END_2',
  Consumer = 'CONSUMER',
  Creator = 'CREATOR',
  Match = 'MATCH',
  MatchingTool = 'MATCHING_TOOL',
  NoCreator = 'NO_CREATOR',
  Owner = 'OWNER',
  Provider = 'PROVIDER',
  Referrer = 'REFERRER',
  Supply = 'SUPPLY',
  Transaction = 'TRANSACTION'
}

export type NotificationLight = {
  __typename?: 'NotificationLight';
  event_code: RandevuEventKind;
  id: Scalars['ID'];
  is_success?: Maybe<Scalars['Boolean']>;
  replied_at?: Maybe<Scalars['DateTimeISO']>;
  sent_at: Scalars['DateTimeISO'];
};

export type NotificationParameter = {
  __typename?: 'NotificationParameter';
  data?: Maybe<Scalars['JSONObject']>;
  name: Scalars['String'];
  parent_type?: Maybe<NotificationFieldParentKind>;
  source_type: NotificationParameterSourceKind;
};

export type NotificationParameterInput = {
  data?: InputMaybe<Scalars['JSONObject']>;
  name: Scalars['String'];
  parent_type?: InputMaybe<NotificationFieldParentKind>;
  source_type: NotificationParameterSourceKind;
};

export enum NotificationParameterSourceKind {
  BoUserResetPassRequest = 'BO_USER_RESET_PASS_REQUEST',
  ConnectionId = 'CONNECTION_ID',
  Const = 'CONST',
  Email = 'EMAIL',
  EncodedChangePassToken = 'ENCODED_CHANGE_PASS_TOKEN',
  EncodedVerificationToken = 'ENCODED_VERIFICATION_TOKEN',
  EnvVar = 'ENV_VAR',
  Event = 'EVENT',
  Field = 'FIELD',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  MatchId = 'MATCH_ID',
  MobileNr = 'MOBILE_NR',
  NewEmail = 'NEW_EMAIL',
  OldEmail = 'OLD_EMAIL',
  ParentTransactionId = 'PARENT_TRANSACTION_ID',
  ParticipantId = 'PARTICIPANT_ID',
  ParticipantResetPassRequest = 'PARTICIPANT_RESET_PASS_REQUEST',
  PlatformName = 'PLATFORM_NAME',
  Role = 'ROLE',
  StateName = 'STATE_NAME',
  SupplyId = 'SUPPLY_ID',
  TransactionId = 'TRANSACTION_ID',
  UpdatedByBackoffice = 'UPDATED_BY_BACKOFFICE',
  UserId = 'USER_ID',
  VerifyBoUserEmail = 'VERIFY_BO_USER_EMAIL',
  VerifyParticipantEmail = 'VERIFY_PARTICIPANT_EMAIL'
}

export enum NotificationRecipientRoleKind {
  Bo = 'BO',
  ConnectionEnd_1 = 'CONNECTION_END_1',
  ConnectionEnd_2 = 'CONNECTION_END_2',
  Const = 'CONST',
  Consumer = 'CONSUMER',
  Creator = 'CREATOR',
  Field = 'FIELD',
  NewEmail = 'NEW_EMAIL',
  NoCreator = 'NO_CREATOR',
  OldEmail = 'OLD_EMAIL',
  Owner = 'OWNER',
  Provider = 'PROVIDER',
  Referrer = 'REFERRER'
}

export type NotificationType = {
  __typename?: 'NotificationType';
  channel: NotificationChannelKind;
  data?: Maybe<Scalars['JSONObject']>;
  description?: Maybe<Scalars['String']>;
  event_filter: Array<EventSelectorRecord>;
  id: Scalars['ID'];
  integration_provider?: Maybe<IntegrationProvider>;
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  parameters: Array<NotificationParameter>;
  recipients: Array<EMailNotificationRecipient>;
  target_url?: Maybe<Scalars['HttpURL']>;
};

export type NotificationTypeFilterInput = {
  channel: NotificationChannelKind;
  event_code?: InputMaybe<RandevuEventKind>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_system?: InputMaybe<Scalars['Boolean']>;
};

export type Object = {
  __typename?: 'Object';
  fields?: Maybe<Array<Field>>;
  id: Scalars['ID'];
  id_object_type: Scalars['ID'];
};

export type ObjectField = {
  __typename?: 'ObjectField';
  current_accesses: FieldAccessInfo;
  field_type: FieldType;
  id: Scalars['ID'];
  my_access?: Maybe<FieldAccessKind>;
  object_type: ObjectType;
  value?: Maybe<ObjectFieldValue>;
};

export type ObjectFieldValue = {
  __typename?: 'ObjectFieldValue';
  fields: Array<Field>;
  id_object: Scalars['ID'];
  id_object_type: Scalars['ID'];
};

export type ObjectSetField = {
  __typename?: 'ObjectSetField';
  current_accesses: FieldAccessInfo;
  field_type: FieldType;
  id: Scalars['ID'];
  my_access?: Maybe<FieldAccessKind>;
  object_type: ObjectType;
  value?: Maybe<Array<ObjectFieldValue>>;
};

export type ObjectType = {
  __typename?: 'ObjectType';
  fields: Array<FieldType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  shared_instances_enabled: Scalars['Boolean'];
  tech_name: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  currency: CurrencyKind;
  gross_value: Scalars['Int'];
  id: Scalars['ID'];
  net_value: Scalars['Int'];
  order_items: Array<OrderItem>;
  provider: Participant;
  total_before_tax: Scalars['Int'];
  total_provider_fees: Scalars['Int'];
  total_provider_taxes: Scalars['Int'];
  total_taxes: Scalars['Int'];
};

export type OrderConfiguration = {
  __typename?: 'OrderConfiguration';
  add_ons: Array<AddOn>;
  discounts: Array<Discount>;
  order_restrictions: Array<MatchRestriction>;
  platform_fees: Array<PlatformFee>;
  taxes: Array<Tax>;
};

export type OrderConfigurationInput = {
  add_ons?: InputMaybe<Array<AddOnInput>>;
  discounts?: InputMaybe<Array<DiscountInput>>;
  order_restrictions?: InputMaybe<Array<MatchRestrictionInput>>;
  platform_fees?: InputMaybe<Array<PlatformFeeInput>>;
  taxes?: InputMaybe<Array<TaxInput>>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  data: Scalars['JSONObject'];
  description: Scalars['String'];
  is_adjustment: Scalars['Boolean'];
  type: CostItemKind;
  value: Scalars['Int'];
};

export type OwnerScopeObject = {
  id_arg: Scalars['String'];
  owner_arg: Scalars['String'];
  type: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  end_cursor?: Maybe<Scalars['String']>;
  has_next_page: Scalars['Boolean'];
  has_previous_page: Scalars['Boolean'];
  start_cursor?: Maybe<Scalars['String']>;
};

export type PageTitleUiConfig = {
  __typename?: 'PageTitleUiConfig';
  ids_participant_type: Array<Maybe<Scalars['String']>>;
  label: Scalars['String'];
};

export type PageTitleUiConfigInput = {
  ids_participant_type: Array<InputMaybe<Scalars['String']>>;
  label: Scalars['String'];
};

export type Participant = {
  __typename?: 'Participant';
  account: Account;
  approved_at?: Maybe<Scalars['DateTimeISO']>;
  closed_at?: Maybe<Scalars['DateTimeISO']>;
  collections: Array<Collection>;
  created_at?: Maybe<Scalars['DateTimeISO']>;
  fields: Array<Field>;
  id: Scalars['ID'];
  id_onboarding_flow?: Maybe<Scalars['ID']>;
  onboarded_at?: Maybe<Scalars['DateTimeISO']>;
  onboarding_progress?: Maybe<ParticipantOnboardingProgress>;
  provided_supplies?: Maybe<Array<Supply>>;
  rejected_at?: Maybe<Scalars['DateTimeISO']>;
  status: ParticipantStatusKind;
  type: ParticipantType;
  user_actions: Array<UserAction>;
  users: Array<User>;
};

export type ParticipantAccountPageConfig = {
  __typename?: 'ParticipantAccountPageConfig';
  description?: Maybe<Scalars['String']>;
  payment_section?: Maybe<ParticipantAccountPaymentSectionConfig>;
  profile_section?: Maybe<ParticipantAccountProfileSectionConfig>;
  title?: Maybe<Scalars['String']>;
};

export type ParticipantAccountPageConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  payment_section?: InputMaybe<ParticipantAccountPaymentSectionConfigInput>;
  profile_section?: InputMaybe<ParticipantAccountProfileSectionConfigInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type ParticipantAccountPaymentSectionConfig = {
  __typename?: 'ParticipantAccountPaymentSectionConfig';
  tab_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ParticipantAccountPaymentSectionConfigInput = {
  tab_name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ParticipantAccountProfileSectionConfig = {
  __typename?: 'ParticipantAccountProfileSectionConfig';
  button_save?: Maybe<FrontofficeFormFieldConfig>;
  tab_name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ParticipantAccountProfileSectionConfigInput = {
  button_save?: InputMaybe<FrontofficeFormFieldConfigInput>;
  tab_name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Filter operators for InputKind.Participant */
export type ParticipantFieldFilter = {
  /** Checks if the value is equal against provided value. */
  equals?: InputMaybe<Scalars['ID']>;
  /** Checks value against null. If value is set, then it is not null. */
  is_set?: InputMaybe<Scalars['Boolean']>;
  /** Checks if the value is not equal against provided value. */
  not?: InputMaybe<Scalars['ID']>;
};

export type ParticipantFilter = {
  ids_participant_types?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  pending_backoffice_actions?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<ParticipantStatusKind>>;
};

export type ParticipantOnboardingProgress = {
  __typename?: 'ParticipantOnboardingProgress';
  message?: Maybe<Scalars['String']>;
  next_steps: Array<NextStep>;
  status?: Maybe<Scalars['String']>;
  status_tech_name?: Maybe<Scalars['String']>;
};

export type ParticipantPublicProfile = {
  __typename?: 'ParticipantPublicProfile';
  fields: Array<Field>;
  id_participant: Scalars['ID'];
  id_participant_type: Scalars['ID'];
};

export type ParticipantSegmentFilter = {
  participant_segment_tech_name: Scalars['String'];
};

export type ParticipantSegmentFilterForBo = {
  id_participant_segment_type: Scalars['ID'];
  ids_owner_participants?: InputMaybe<Array<Scalars['ID']>>;
};

export type ParticipantSegmentForBo = {
  __typename?: 'ParticipantSegmentForBO';
  created_at: Scalars['DateTimeISO'];
  fields: Array<Field>;
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  owner: Participant;
  participants: Array<Participant>;
  type: ParticipantSegmentType;
};

export type ParticipantSegmentForOwner = {
  __typename?: 'ParticipantSegmentForOwner';
  created_at: Scalars['DateTimeISO'];
  fields: Array<Field>;
  id: Scalars['ID'];
  ids_participants: Array<Scalars['ID']>;
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  type: ParticipantSegmentType;
};

export type ParticipantSegmentType = {
  __typename?: 'ParticipantSegmentType';
  fields: Array<FieldType>;
  id: Scalars['ID'];
  item_participant_type: ParticipantType;
  name: Scalars['String'];
  owner_participant_type: ParticipantType;
  tech_name: Scalars['String'];
};

/** Filter operators for InputKind.ParticipantSet */
export type ParticipantSetFieldFilter = {
  /** Checks if the value is equal against provided values. All values have to match and no additional value is allowed. */
  equals?: InputMaybe<Array<Scalars['ID']>>;
  /** Checks if the value contains one or more of provided values. The in operator is a shorthand for multiple OR logical operators. */
  in?: InputMaybe<Array<Scalars['ID']>>;
  /** Checks value against null and empty array. If value is set, then it is neither null nor empty array. It is an array containing at least one value. */
  is_set?: InputMaybe<Scalars['Boolean']>;
  /** Checks if the value is not equal against provided value. None of the values should should match, but an additional value might exist. */
  not?: InputMaybe<Array<Scalars['ID']>>;
};

export enum ParticipantStatusKind {
  Closed = 'CLOSED',
  Onboarded = 'ONBOARDED',
  Onboarding = 'ONBOARDING',
  PendingOnboarding = 'PENDING_ONBOARDING',
  Rejected = 'REJECTED'
}

export type ParticipantType = {
  __typename?: 'ParticipantType';
  account_type?: Maybe<AccountKind>;
  auto_backoffice_approval: Scalars['Boolean'];
  fields: Array<FieldType>;
  flow_type: FlowType;
  id: Scalars['ID'];
  inviting_enabled: Scalars['Boolean'];
  is_master_type: Scalars['Boolean'];
  name: Scalars['String'];
  tech_name: Scalars['String'];
  test_user_email?: Maybe<Scalars['String']>;
  ui_config: Scalars['JSONObject'];
};

/** Payment entity from the platform participant's perspective */
export type Payment = {
  __typename?: 'Payment';
  /** Amount to due */
  amount: MonetaryValue;
  authorized_at?: Maybe<Scalars['DateTimeISO']>;
  captured_at?: Maybe<Scalars['DateTimeISO']>;
  checkout_requested_at?: Maybe<Scalars['DateTimeISO']>;
  completed_at?: Maybe<Scalars['DateTimeISO']>;
  created_at: Scalars['DateTimeISO'];
  /** Payment error as reported by payment service provider. */
  error?: Maybe<PaymentError>;
  failed_at?: Maybe<Scalars['DateTimeISO']>;
  /** ID of the payment */
  id: Scalars['ID'];
  /** ID of the transaction associated with the payment */
  id_transaction?: Maybe<Scalars['ID']>;
  payer: Participant;
  /** Payment method used for the payment. E.g. card, bank transfer, etc. */
  payment_method?: Maybe<PaymentMethodKind>;
  /** Details of the payment method used for the payment. E.g. last 4 digits of a card, bank account number in case of bank transfers, etc. */
  payment_method_details: Scalars['JSONObject'];
  prepared_at: Scalars['DateTimeISO'];
  prepared_by: User;
  status: PaymentStatusKind;
  updated_at?: Maybe<Scalars['DateTimeISO']>;
  user: User;
};

export type PaymentCheckoutConnection = {
  __typename?: 'PaymentCheckoutConnection';
  id_payment: Scalars['ID'];
  url: Scalars['String'];
};

export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  edges: Array<PaymentEdge>;
  page_info: PageInfo;
  total_count: Scalars['Int'];
};

export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  cursor: Scalars['String'];
  node: Payment;
};

export type PaymentError = {
  __typename?: 'PaymentError';
  error_code?: Maybe<Scalars['String']>;
  error_message?: Maybe<Scalars['String']>;
};

export type PaymentFilter = {
  status?: InputMaybe<PaymentStatusKind>;
};

export type PaymentFilterForBo = {
  status?: InputMaybe<PaymentStatusKind>;
};

/** Payment entity from the platform operator's perspective */
export type PaymentForBo = {
  __typename?: 'PaymentForBo';
  /** Amount to due */
  amount: MonetaryValue;
  /** Timestamps that indicates that platform got authorization on a payment method to capture the funds. Mostly, cards as payment methods have the authorization. */
  authorized_at?: Maybe<Scalars['DateTimeISO']>;
  /** Timestamps that indicates when the funds got captured */
  captured_at?: Maybe<Scalars['DateTimeISO']>;
  /** Timestamps that indicates when a participant requested checkout. */
  checkout_requested_at?: Maybe<Scalars['DateTimeISO']>;
  /** Timestamps that indicates when the payment is completed, i.e. when all associated transfers are successfully sent to merchant accounts or flagged as successfully sent. */
  completed_at?: Maybe<Scalars['DateTimeISO']>;
  /** Timestamps that indicates when the payment entity is created. */
  created_at: Scalars['DateTimeISO'];
  /** Payment error as reported by payment service provider. */
  error?: Maybe<PaymentError>;
  failed_at?: Maybe<Scalars['DateTimeISO']>;
  /** ID of the payment */
  id: Scalars['ID'];
  /** ID of the payment on the side of the payment service provider. */
  id_psp_transaction?: Maybe<Scalars['String']>;
  /** ID of the transaction associated with the payment */
  id_transaction?: Maybe<Scalars['ID']>;
  /** ID of the transaction type associated with the payment */
  id_transaction_type?: Maybe<Scalars['ID']>;
  /** Participant that acts as payer */
  payer: Participant;
  /** Payment method used for the payment. E.g. card, bank transfer, etc. */
  payment_method?: Maybe<PaymentMethodKind>;
  /** Details of the payment method used for the payment. E.g. last 4 digits of a card, bank account number in case of bank transfers, etc. */
  payment_method_details: Scalars['JSONObject'];
  /** Revenue that platform made out of the payment. */
  platform_revenue: PlatformRevenue;
  prepared_at: Scalars['DateTimeISO'];
  /** User within participant that created/prepared payment */
  prepared_by: User;
  /** Cost of payment issued by payment service provider. */
  psp_fees?: Maybe<Scalars['JSONObject']>;
  /** Name of payment service provider used to process the payment. */
  psp_name?: Maybe<Scalars['String']>;
  /** Status of the payment */
  status: PaymentStatusKind;
  /** Transfers that got created out of payment. Transfers are created after the payment is captured. */
  transfers: Array<TransferForBo>;
  /** Timestamps that indicates when the last associated transfer is successfully sent to a merchant account or flagged as successfully sent. */
  transfers_completed_at?: Maybe<Scalars['DateTimeISO']>;
  /** Timestamps that indicates when the payment is lastly updated. */
  updated_at?: Maybe<Scalars['DateTimeISO']>;
  /** User within participant that acts as payer */
  user: User;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  accepted_methods: Array<PaymentMethodKind>;
  integration_provider: IntegrationProvider;
  not_accepted_methods: Array<PaymentMethodKind>;
};

export type PaymentMethodInput = {
  accepted_methods: Array<PaymentMethodKind>;
  id_integration_provider: Scalars['ID'];
  not_accepted_methods: Array<PaymentMethodKind>;
};

export enum PaymentMethodKind {
  Card = 'CARD',
  Other = 'OTHER',
  SepaTransfer = 'SEPA_TRANSFER'
}

export type PaymentProviderConnection = {
  __typename?: 'PaymentProviderConnection';
  url: Scalars['String'];
};

export type PaymentServiceProvider = {
  __typename?: 'PaymentServiceProvider';
  data: Scalars['JSONObject'];
  type: IntegrationProviders;
};

export enum PaymentStatusKind {
  Authorized = 'AUTHORIZED',
  Captured = 'CAPTURED',
  CheckoutRequested = 'CHECKOUT_REQUESTED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Prepared = 'PREPARED'
}

export type PaymentType = {
  __typename?: 'PaymentType';
  id: Scalars['ID'];
  name: Scalars['String'];
  payment_methods: Array<PaymentMethod>;
  tech_name: Scalars['String'];
};

export type PlaceHolderOrder = {
  __typename?: 'PlaceHolderOrder';
  delete_this_type: Scalars['Boolean'];
};

/** Your randevu platform account. */
export type PlatformAccount = {
  __typename?: 'PlatformAccount';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  contact_phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** ID of user that owns the randevu account. */
  id_owner: Scalars['ID'];
  legal_business_name?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  support_email?: Maybe<Scalars['String']>;
  support_phone?: Maybe<Scalars['String']>;
};

export type PlatformAccountInput = {
  address_line_1?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  contact_phone?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  data?: InputMaybe<Scalars['JSONObject']>;
  domain_name?: InputMaybe<Scalars['String']>;
  legal_business_name?: InputMaybe<Scalars['String']>;
  place?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  support_email?: InputMaybe<Scalars['String']>;
  support_phone?: InputMaybe<Scalars['String']>;
};

export type PlatformFee = {
  __typename?: 'PlatformFee';
  description: Scalars['String'];
  guard_expression: Scalars['String'];
  is_consumer_fee?: Maybe<Scalars['Boolean']>;
  is_merchant_level?: Maybe<Scalars['Boolean']>;
  is_provider_fee?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  value_expression: Scalars['String'];
};

export type PlatformFeeInput = {
  description: Scalars['String'];
  guard_expression: Scalars['String'];
  is_consumer_fee?: InputMaybe<Scalars['Boolean']>;
  is_merchant_level?: InputMaybe<Scalars['Boolean']>;
  is_provider_fee?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  value_expression: Scalars['String'];
};

export type PlatformFeeItem = {
  __typename?: 'PlatformFeeItem';
  description: Scalars['String'];
  is_consumer_fee?: Maybe<Scalars['Boolean']>;
  is_provider_fee?: Maybe<Scalars['Boolean']>;
  value: Scalars['Int'];
};

export type PlatformOwnerOnboardingProgress = {
  __typename?: 'PlatformOwnerOnboardingProgress';
  answers?: Maybe<Array<PlatformOwnerOnboardingStep>>;
  status?: Maybe<Scalars['String']>;
};

export enum PlatformOwnerOnboardingStatusKind {
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  InitialPlatformCreated = 'INITIAL_PLATFORM_CREATED',
  NotStarted = 'NOT_STARTED',
  Ongoing = 'ONGOING',
  Rejected = 'REJECTED',
  SurveyDone = 'SURVEY_DONE'
}

export type PlatformOwnerOnboardingStep = {
  __typename?: 'PlatformOwnerOnboardingStep';
  answer: Scalars['JSON'];
  step: Scalars['Int'];
};

export type PlatformRevenue = {
  __typename?: 'PlatformRevenue';
  consumer: Array<PlatformRevenueItem>;
  providers: Array<PlatformRevenueItem>;
  total_revenue: Scalars['Int'];
};

export type PlatformRevenueItem = {
  __typename?: 'PlatformRevenueItem';
  /** A human-readable description of the revenue item (e.g., 'Transaction fee for order above 10,000€'). */
  description: Scalars['String'];
  /**
   * The ID of the order associated with the revenue item, if it originates from an order-level transaction
   * (e.g., a fee of 5€ for orders exceeding 10,000€).
   */
  id_order?: Maybe<Scalars['ID']>;
  /** The participant (provider or consumer) from whom the platform is generating revenue. */
  id_participant: Scalars['ID'];
  /**
   * The ID of the supply item associated with the revenue item, if it originates from a supply-level transaction
   * (e.g., a fee of 1€ per item for high CO2 emissions).
   */
  id_supply?: Maybe<Scalars['ID']>;
  /** Indicates if the revenue is generated from the consumer's side. True for consumer-facing fees. */
  is_consumer_fee: Scalars['Boolean'];
  /** Indicates if the revenue is generated from the provider's side. True for provider-facing fees. */
  is_provider_fee: Scalars['Boolean'];
  /** Indicates whether the revenue item represents taxes withheld by the platform from the provider. */
  is_provider_tax: Scalars['Boolean'];
  /** The revenue amount generated by the platform, represented in thousandths (e.g., 1000 = 1 unit of currency). */
  value: Scalars['Int'];
};

export enum PlatformTeamRole {
  MarketplaceAdmin = 'MARKETPLACE_ADMIN',
  MarketplaceDeveloper = 'MARKETPLACE_DEVELOPER',
  MarketplaceOperator = 'MARKETPLACE_OPERATOR',
  MarketplaceOwner = 'MARKETPLACE_OWNER'
}

export type PricingRule = {
  __typename?: 'PricingRule';
  description: Scalars['String'];
  guard_expression?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  value_expression: Scalars['String'];
};

export type PricingRuleInput = {
  description: Scalars['String'];
  guard_expression?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  value_expression: Scalars['String'];
};

export type Provider = {
  __typename?: 'Provider';
  collections: Array<SupplyCollection>;
  fields: Array<Field>;
  id_participant: Scalars['ID'];
  type: ParticipantType;
};

export type ProviderConnection = {
  __typename?: 'ProviderConnection';
  edges: Array<ProviderEdge>;
  page_info: PageInfo;
  total_count: Scalars['Int'];
};

/** DEPRECATED */
export type ProviderDiscoverFilter = {
  fields?: InputMaybe<Array<FieldFilterInput>>;
  providers?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProviderDiscoveryFilter = {
  fields?: InputMaybe<FieldFilters>;
  ids_providers?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProviderEdge = {
  __typename?: 'ProviderEdge';
  cursor: Scalars['String'];
  node: Provider;
};

export type PublicKeyRecord = {
  __typename?: 'PublicKeyRecord';
  environment: MarketplaceEnvironmentKind;
  id_marketplace: Scalars['ID'];
  marketplace_name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  calculateSupplyPrice: SupplyPrice;
  calculateTransactionChargeable: Chargeable;
  discoverConnectionParticipantDetails: ConnectedParticipant;
  discoverConnectionParticipants?: Maybe<Array<ConnectedParticipant>>;
  /** @deprecated This service is deprecated. Use discoverTransactionProviderDetails instead */
  discoverProviderDetails: Provider;
  /** @deprecated This service is deprecated. Use discoverTransactionSupplies instead */
  discoverSupply?: Maybe<Array<Supply>>;
  /** @deprecated This service is deprecated. Use discoverTransactionSupplyDetails instead */
  discoverSupplyDetails: Supply;
  discoverTransactionConsumerDetails: DiscoveredConsumer;
  discoverTransactionConsumers: Array<DiscoveredConsumer>;
  discoverTransactionProviderDetails: Provider;
  discoverTransactionProviders: ProviderConnection;
  discoverTransactionSupplies: Array<DiscoveredSupply>;
  discoverTransactionSuppliesNEW: DiscoveredSupplyConnection;
  discoverTransactionSupplyDetails: DiscoveredSupply;
  /** Every user belongs to exactly one account. Multiple users can be members of the same account. */
  getAccount: Account;
  getApiKeys: Array<ApiKey>;
  getApiUsers: Array<ApiUser>;
  /** Retrieves platform-wide transactional assets (images, documents), i.e. assets uploaded by participants or on their behalf. */
  getAssets: AssetConnection;
  getCollection?: Maybe<Collection>;
  getCollections: Array<Collection>;
  getConnection: ConnectionForBo;
  getConnectionType: ConnectionType;
  getConnectionTypes: Array<ConnectionType>;
  getConnections: Array<ConnectionForBo>;
  getDataImportJobReports: Array<DataImportJobStatusReportForBo>;
  getDeployment: Deployment;
  getDeployments: Array<Deployment>;
  getEmailLogs: Array<Maybe<EmailNotificationLog>>;
  getEnvironmentVariables: Array<EnvironmentVariable>;
  getEventLogs: Array<RandevuEvent>;
  getFlowType: FlowType;
  getFlowTypes?: Maybe<Array<FlowType>>;
  getFrontofficeConfiguration: FrontofficeConfiguration;
  getIntegrationProviderCatalog: Array<AvailableIntegrationProvider>;
  getIntegrationProviders: Array<IntegrationProvider>;
  getMatch?: Maybe<Match>;
  getMatchType?: Maybe<MatchType>;
  getMatchTypes?: Maybe<Array<MatchType>>;
  getMatches?: Maybe<Array<Match>>;
  getMatchingToolType: MatchingToolType;
  getModifiedFlowTypes: Array<ModifiedFlowType>;
  getNotificationType: NotificationType;
  getNotificationTypeSecret: Scalars['String'];
  getNotificationTypes: Array<NotificationType>;
  getObjectType: ObjectType;
  getObjectTypes: Array<ObjectType>;
  getParticipant: Participant;
  getParticipantPublicProfile: ParticipantPublicProfile;
  getParticipantSegment: ParticipantSegmentForBo;
  getParticipantSegmentTypes: Array<ParticipantSegmentType>;
  getParticipantSegments: Array<ParticipantSegmentForBo>;
  getParticipantType?: Maybe<ParticipantType>;
  getParticipantTypes: Array<ParticipantType>;
  getParticipants: Array<Participant>;
  getPayment: PaymentForBo;
  getPaymentServiceProvider: PaymentServiceProvider;
  getPaymentServiceProviders: Array<Maybe<IntegrationProvider>>;
  getPaymentTypes: Array<PaymentType>;
  getPayments?: Maybe<Array<PaymentForBo>>;
  getPlatformAccount: PlatformAccount;
  getPlatformCurrencies: Array<CurrencyKind>;
  getPlatformEnvironmentsDifferences: Array<Scalars['String']>;
  getPlatformTeam: Array<BackofficeUser>;
  getReferrals?: Maybe<Array<ReferralForBo>>;
  /** Retrieves platform-wide scheduled events. */
  getScheduledEvents: ScheduledEventsConnection;
  getSharedObject: Object;
  getSharedObjects?: Maybe<Array<Object>>;
  getShoppingCart: ShoppingCartForBo;
  getShoppingCarts: Array<ShoppingCartLight>;
  getSingleDirectTransaction: SingleDirectTransactionForBo;
  getSingleDirectTransactions: SingleDirectTransactionForBoConnection;
  getSupplies: SupplyConnection;
  getSupply: Supply;
  getSupplyType?: Maybe<SupplyType>;
  getSupplyTypes?: Maybe<Array<SupplyType>>;
  getTransaction: Transaction;
  getTransactionType: TransactionType;
  getTransactionTypes?: Maybe<Array<TransactionType>>;
  getTransactions: Array<TransactionLight>;
  getTransfers: Array<Maybe<Transfer>>;
  /** Retrieves (participant) user. */
  getUser: User;
  getWebhookLog: Webhook;
  getWebhookLogs: Array<NotificationLight>;
  isSupplyAvailable: Scalars['Boolean'];
  me: User;
  myActiveShoppingCart?: Maybe<ShoppingCart>;
  myCollection?: Maybe<Collection>;
  myCollections: Array<Collection>;
  myConnection: Connection;
  myConnectionType: ConnectionType;
  myConnectionTypes: Array<ConnectionType>;
  myConnections: Array<Connection>;
  myDataImportJobReports: Array<DataImportJobStatusReport>;
  myParticipant: Participant;
  myParticipantSegment: ParticipantSegmentForOwner;
  myParticipantSegmentTypes: Array<ParticipantSegmentType>;
  myParticipantSegments: Array<ParticipantSegmentForOwner>;
  myParticipants: Array<Participant>;
  myPayment: Payment;
  myPayments: PaymentConnection;
  myReferrals?: Maybe<Array<Referral>>;
  mySharedObject: Object;
  mySharedObjectTypes?: Maybe<Array<ObjectType>>;
  mySharedObjects?: Maybe<Array<Object>>;
  myShoppingCart: ShoppingCart;
  myShoppingCartOrder: ShoppingCartOrder;
  myShoppingCartOrders: Array<ShoppingCartOrderLight>;
  myShoppingCarts?: Maybe<Array<ShoppingCartLight>>;
  mySingleDirectTransaction: SingleDirectTransactionForConsumer;
  mySingleDirectTransactionForProvider: SingleDirectTransactionForProvider;
  mySingleDirectTransactions: SingleDirectTransactionForConsumerConnection;
  mySingleDirectTransactionsForProvider: SingleDirectTransactionForProviderConnection;
  mySupplies?: Maybe<Array<Supply>>;
  mySupply?: Maybe<Supply>;
  mySupplyTypes?: Maybe<Array<SupplyType>>;
  myTransaction: Transaction;
  myTransactionTypes?: Maybe<Array<TransactionType>>;
  myTransactions?: Maybe<Array<TransactionLight>>;
  myTransfers: TransferConnection;
  myself: BackofficeUser;
  root?: Maybe<Scalars['String']>;
};


export type QueryCalculateSupplyPriceArgs = {
  id_supply: Scalars['ID'];
  match_parameters: Array<FieldParameterInput>;
};


export type QueryCalculateTransactionChargeableArgs = {
  id: Scalars['ID'];
};


export type QueryDiscoverConnectionParticipantDetailsArgs = {
  connection_tech_name: Scalars['String'];
  id_participant: Scalars['ID'];
};


export type QueryDiscoverConnectionParticipantsArgs = {
  connection_tech_name: Scalars['String'];
  where: ConnectionDiscoveryFilter;
};


export type QueryDiscoverProviderDetailsArgs = {
  id_participant: Scalars['ID'];
  transaction_tech_name: Scalars['String'];
};


export type QueryDiscoverSupplyArgs = {
  availability_params?: InputMaybe<Array<FieldParameterInput>>;
  available_supply_only?: InputMaybe<Scalars['Boolean']>;
  transaction_tech_name?: InputMaybe<Scalars['String']>;
  where: SupplyDiscoveryFilter;
};


export type QueryDiscoverSupplyDetailsArgs = {
  id_supply: Scalars['ID'];
  transaction_tech_name?: InputMaybe<Scalars['String']>;
};


export type QueryDiscoverTransactionConsumerDetailsArgs = {
  id_participant: Scalars['ID'];
  transaction_tech_name: Scalars['String'];
};


export type QueryDiscoverTransactionConsumersArgs = {
  transaction_tech_name: Scalars['String'];
  where: Array<FieldFilterInput>;
};


export type QueryDiscoverTransactionProviderDetailsArgs = {
  id_participant: Scalars['ID'];
  transaction_tech_name: Scalars['String'];
};


export type QueryDiscoverTransactionProvidersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  transaction_tech_name: Scalars['String'];
  where: ProviderDiscoveryFilter;
};


export type QueryDiscoverTransactionSuppliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  availability_params?: InputMaybe<Array<FieldParameterInput>>;
  available_supply_only?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  transaction_tech_name: Scalars['String'];
  where: SupplyDiscoveryFilter;
};


export type QueryDiscoverTransactionSuppliesNewArgs = {
  after?: InputMaybe<Scalars['String']>;
  availability_params?: InputMaybe<Array<FieldParameterInput>>;
  available_supply_only?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  transaction_tech_name: Scalars['String'];
  where: SupplyDiscoverFilter;
};


export type QueryDiscoverTransactionSupplyDetailsArgs = {
  id_supply: Scalars['ID'];
  transaction_tech_name: Scalars['String'];
};


export type QueryGetAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetsFilterForBo>;
};


export type QueryGetCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryGetConnectionArgs = {
  id: Scalars['ID'];
};


export type QueryGetConnectionTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetConnectionsArgs = {
  where: ConnectionFilterForBo;
};


export type QueryGetDeploymentArgs = {
  id: Scalars['ID'];
};


export type QueryGetEventLogsArgs = {
  where?: InputMaybe<RandevuEventsFilter>;
};


export type QueryGetFlowTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetFrontofficeConfigurationArgs = {
  domain_name?: InputMaybe<Scalars['String']>;
  environment: MarketplaceEnvironmentKind;
  marketplace_name?: InputMaybe<Scalars['String']>;
};


export type QueryGetMatchArgs = {
  id: Scalars['ID'];
};


export type QueryGetMatchTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetMatchesArgs = {
  filter?: InputMaybe<MatchFilter>;
};


export type QueryGetMatchingToolTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetNotificationTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetNotificationTypeSecretArgs = {
  id: Scalars['ID'];
};


export type QueryGetNotificationTypesArgs = {
  where?: InputMaybe<NotificationTypeFilterInput>;
};


export type QueryGetObjectTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetParticipantArgs = {
  id: Scalars['ID'];
};


export type QueryGetParticipantPublicProfileArgs = {
  id_participant: Scalars['ID'];
  participant_tech_name: Scalars['String'];
};


export type QueryGetParticipantSegmentArgs = {
  id: Scalars['ID'];
};


export type QueryGetParticipantSegmentsArgs = {
  where: ParticipantSegmentFilterForBo;
};


export type QueryGetParticipantTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetParticipantsArgs = {
  where?: InputMaybe<ParticipantFilter>;
};


export type QueryGetPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryGetPaymentServiceProviderArgs = {
  type: IntegrationProviders;
};


export type QueryGetPaymentsArgs = {
  where?: InputMaybe<PaymentFilterForBo>;
};


export type QueryGetPlatformTeamArgs = {
  where?: InputMaybe<BackofficeUserFilter>;
};


export type QueryGetScheduledEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledEventsFilter>;
};


export type QueryGetSharedObjectArgs = {
  id: Scalars['ID'];
};


export type QueryGetSharedObjectsArgs = {
  where?: InputMaybe<SharedObjectFilter>;
};


export type QueryGetShoppingCartArgs = {
  id_cart: Scalars['ID'];
};


export type QueryGetShoppingCartsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where: MultiDirectTransactionFilterForBo;
};


export type QueryGetSingleDirectTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryGetSingleDirectTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where: SingleDirectTransactionFilterForBo;
};


export type QueryGetSuppliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SupplyFilter>;
};


export type QueryGetSupplyArgs = {
  id: Scalars['ID'];
};


export type QueryGetSupplyTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryGetTransactionTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetTransactionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionFilter>;
};


export type QueryGetTransfersArgs = {
  id_payment: Scalars['ID'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetWebhookLogArgs = {
  id: Scalars['ID'];
};


export type QueryGetWebhookLogsArgs = {
  where?: InputMaybe<WebhookFilter>;
};


export type QueryIsSupplyAvailableArgs = {
  availability_params: Array<FieldParameterInput>;
  id_supply: Scalars['ID'];
};


export type QueryMyCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryMyConnectionArgs = {
  id: Scalars['ID'];
};


export type QueryMyConnectionTypeArgs = {
  tech_name: Scalars['String'];
};


export type QueryMyConnectionsArgs = {
  where: ConnectionFilter;
};


export type QueryMyParticipantArgs = {
  id: Scalars['ID'];
};


export type QueryMyParticipantSegmentArgs = {
  id: Scalars['ID'];
};


export type QueryMyParticipantSegmentsArgs = {
  where: ParticipantSegmentFilter;
};


export type QueryMyPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryMyPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentFilter>;
};


export type QueryMySharedObjectArgs = {
  id: Scalars['ID'];
};


export type QueryMySharedObjectsArgs = {
  where?: InputMaybe<SharedObjectFilter>;
};


export type QueryMyShoppingCartArgs = {
  id_cart: Scalars['ID'];
};


export type QueryMyShoppingCartOrderArgs = {
  id_cart_order: Scalars['ID'];
};


export type QueryMyShoppingCartOrdersArgs = {
  where: ShoppingCartOrderFilter;
};


export type QueryMyShoppingCartsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  where: ShoppingCartFilter;
};


export type QueryMySingleDirectTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryMySingleDirectTransactionForProviderArgs = {
  id: Scalars['ID'];
};


export type QueryMySingleDirectTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where: SingleDirectTransactionFilter;
};


export type QueryMySingleDirectTransactionsForProviderArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where: SingleDirectTransactionFilter;
};


export type QueryMySuppliesArgs = {
  where?: InputMaybe<MySupplyFilter>;
};


export type QueryMySupplyArgs = {
  id: Scalars['ID'];
};


export type QueryMyTransactionArgs = {
  id: Scalars['ID'];
};


export type QueryMyTransfersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransferFilter>;
};

export type RadiusFilter = {
  /** Point in geographical coordinates: latitude and longitude. */
  point: GeoPoint;
  /** The radius in meters on the Earth's surface. */
  radius: Scalars['Int'];
};

export type RandevuEvent = {
  __typename?: 'RandevuEvent';
  code: RandevuEventKind;
  id: Scalars['ID'];
  payload: Scalars['JSONObject'];
  triggered_at: Scalars['DateTimeISO'];
};

export enum RandevuEventKind {
  ConnectionAccepted = 'CONNECTION_ACCEPTED',
  ConnectionApproved = 'CONNECTION_APPROVED',
  ConnectionCanceled = 'CONNECTION_CANCELED',
  ConnectionCreated = 'CONNECTION_CREATED',
  ConnectionDeclined = 'CONNECTION_DECLINED',
  ConnectionEstablished = 'CONNECTION_ESTABLISHED',
  ConnectionRejected = 'CONNECTION_REJECTED',
  ConnectionUpdated = 'CONNECTION_UPDATED',
  DataImportFailed = 'DATA_IMPORT_FAILED',
  DataImportFinished = 'DATA_IMPORT_FINISHED',
  DataImportRequested = 'DATA_IMPORT_REQUESTED',
  DataImportStarted = 'DATA_IMPORT_STARTED',
  FlowInitiated = 'FLOW_INITIATED',
  FlowTerminated = 'FLOW_TERMINATED',
  ManualTransitionTriggered = 'MANUAL_TRANSITION_TRIGGERED',
  MatchingToolCreated = 'MATCHING_TOOL_CREATED',
  MatchingToolToDeactivate = 'MATCHING_TOOL_TO_DEACTIVATE',
  MatchingToolToExecute = 'MATCHING_TOOL_TO_EXECUTE',
  MatchingToolUpdated = 'MATCHING_TOOL_UPDATED',
  MatchAccepted = 'MATCH_ACCEPTED',
  MatchApproved = 'MATCH_APPROVED',
  MatchCanceled = 'MATCH_CANCELED',
  MatchClosed = 'MATCH_CLOSED',
  MatchCreated = 'MATCH_CREATED',
  MatchDeclined = 'MATCH_DECLINED',
  MatchPaid = 'MATCH_PAID',
  MatchPriceOverridden = 'MATCH_PRICE_OVERRIDDEN',
  MatchRejected = 'MATCH_REJECTED',
  MatchUpdated = 'MATCH_UPDATED',
  ParticipantApproved = 'PARTICIPANT_APPROVED',
  ParticipantClosed = 'PARTICIPANT_CLOSED',
  ParticipantCreated = 'PARTICIPANT_CREATED',
  ParticipantOnboarded = 'PARTICIPANT_ONBOARDED',
  ParticipantOnboardingRequestedTimeEvent = 'PARTICIPANT_ONBOARDING_REQUESTED_TIME_EVENT',
  ParticipantOnboardingStateReached = 'PARTICIPANT_ONBOARDING_STATE_REACHED',
  ParticipantOnboardingTransitionExecuted = 'PARTICIPANT_ONBOARDING_TRANSITION_EXECUTED',
  ParticipantRejected = 'PARTICIPANT_REJECTED',
  ParticipantUpdated = 'PARTICIPANT_UPDATED',
  PaymentAuthorized = 'PAYMENT_AUTHORIZED',
  PaymentCaptured = 'PAYMENT_CAPTURED',
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentPrepared = 'PAYMENT_PREPARED',
  RequestedForcedTransition = 'REQUESTED_FORCED_TRANSITION',
  RequestedTimeEvent = 'REQUESTED_TIME_EVENT',
  StateReached = 'STATE_REACHED',
  SupplyApproved = 'SUPPLY_APPROVED',
  SupplyCreated = 'SUPPLY_CREATED',
  SupplyOnboarded = 'SUPPLY_ONBOARDED',
  SupplyOnboardingRequestedTimeEvent = 'SUPPLY_ONBOARDING_REQUESTED_TIME_EVENT',
  SupplyOnboardingStateReached = 'SUPPLY_ONBOARDING_STATE_REACHED',
  SupplyOnboardingTransitionExecuted = 'SUPPLY_ONBOARDING_TRANSITION_EXECUTED',
  SupplyRejected = 'SUPPLY_REJECTED',
  SupplyUpdated = 'SUPPLY_UPDATED',
  TransactionForcedlyTerminated = 'TRANSACTION_FORCEDLY_TERMINATED',
  TransactionInitiated = 'TRANSACTION_INITIATED',
  TransactionPrepared = 'TRANSACTION_PREPARED',
  TransactionRequestedTimeEvent = 'TRANSACTION_REQUESTED_TIME_EVENT',
  TransactionStateReached = 'TRANSACTION_STATE_REACHED',
  TransactionTerminated = 'TRANSACTION_TERMINATED',
  TransactionTransitionExecuted = 'TRANSACTION_TRANSITION_EXECUTED',
  TransferCompleted = 'TRANSFER_COMPLETED',
  TransferCreated = 'TRANSFER_CREATED',
  TransferFailed = 'TRANSFER_FAILED',
  TransitionExecuted = 'TRANSITION_EXECUTED',
  UserClosed = 'USER_CLOSED',
  UserCreated = 'USER_CREATED',
  UserEmailUpdated = 'USER_EMAIL_UPDATED',
  UserEmailVerificationRequested = 'USER_EMAIL_VERIFICATION_REQUESTED',
  UserEmailVerified = 'USER_EMAIL_VERIFIED',
  UserParticipantReferred = 'USER_PARTICIPANT_REFERRED',
  UserPasswordResetRequested = 'USER_PASSWORD_RESET_REQUESTED',
  UserPasswordUpdated = 'USER_PASSWORD_UPDATED',
  WhStripeAccountUpdated = 'WH_STRIPE_ACCOUNT_UPDATED',
  WhStripeChargeSucceeded = 'WH_STRIPE_CHARGE_SUCCEEDED',
  WhStripePaymentFailed = 'WH_STRIPE_PAYMENT_FAILED',
  WhStripePaymentSucceeded = 'WH_STRIPE_PAYMENT_SUCCEEDED'
}

export type RandevuEventsFilter = {
  code?: InputMaybe<Array<RandevuEventKind>>;
};

export type Referral = {
  __typename?: 'Referral';
  created_at: Scalars['DateTimeISO'];
  referee: ReferredParticipant;
  status: ReferralStatusKind;
};

export type ReferralForBo = {
  __typename?: 'ReferralForBo';
  created_at: Scalars['DateTimeISO'];
  referee: Participant;
  referrer: Participant;
  status: ReferralStatusKind;
};

export enum ReferralStatusKind {
  Accepted = 'ACCEPTED',
  Activated = 'ACTIVATED',
  Pending = 'PENDING'
}

export type ReferredParticipant = {
  __typename?: 'ReferredParticipant';
  id: Scalars['ID'];
  type: ParticipantType;
  user: User;
};

export enum RequestFieldAccessClientKind {
  Creator = 'CREATOR',
  NoCreator = 'NO_CREATOR'
}

export type ResetPasswordPageConfig = {
  __typename?: 'ResetPasswordPageConfig';
  description?: Maybe<Scalars['String']>;
  form?: Maybe<ResetPasswordPageFormConfig>;
  form_submitted_view?: Maybe<ResetPasswordPageFormSubmittedView>;
  image_position?: Maybe<ImagePositionKind>;
  image_url?: Maybe<Scalars['String']>;
  style?: Maybe<SecurityPageStyleKind>;
  title?: Maybe<Scalars['String']>;
};

export type ResetPasswordPageConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<ResetPasswordPageFormConfigInput>;
  form_submitted_view?: InputMaybe<ResetPasswordPageFormSubmittedViewInput>;
  id_file_image?: InputMaybe<Scalars['String']>;
  image_position?: InputMaybe<ImagePositionKind>;
  style?: InputMaybe<SecurityPageStyleKind>;
  title?: InputMaybe<Scalars['String']>;
};

export type ResetPasswordPageFormConfig = {
  __typename?: 'ResetPasswordPageFormConfig';
  button_submit?: Maybe<FrontofficeFormFieldConfig>;
  field_email?: Maybe<FrontofficeFormFieldConfig>;
  redirect_to_sign_in?: Maybe<FrontofficeRedirectToTextConfig>;
};

export type ResetPasswordPageFormConfigInput = {
  button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_email?: InputMaybe<FrontofficeFormFieldConfigInput>;
  redirect_to_sign_in?: InputMaybe<FrontofficeRedirectToTextConfigInput>;
};

export type ResetPasswordPageFormSubmittedView = {
  __typename?: 'ResetPasswordPageFormSubmittedView';
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ResetPasswordPageFormSubmittedViewInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ScheduledEvent = {
  __typename?: 'ScheduledEvent';
  created_at: Scalars['DateTimeISO'];
  event?: Maybe<RandevuEventKind>;
  event_payload?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  triggered_at?: Maybe<Scalars['DateTimeISO']>;
};

export type ScheduledEventEdge = {
  __typename?: 'ScheduledEventEdge';
  cursor: Scalars['String'];
  node: ScheduledEvent;
};

export type ScheduledEventsConnection = {
  __typename?: 'ScheduledEventsConnection';
  edges: Array<ScheduledEventEdge>;
  page_info: PageInfo;
  total_count: Scalars['Int'];
};

/** Filter for scheduled events. */
export type ScheduledEventsFilter = {
  /** If true, returns events that have been already triggered. Otherwise, events are returned that are scheduled for execution. */
  is_triggered?: InputMaybe<Scalars['Boolean']>;
};

export type SecurityPageConfig = {
  __typename?: 'SecurityPageConfig';
  image_position?: Maybe<ImagePositionKind>;
  image_url?: Maybe<Scalars['String']>;
  style: SecurityPageStyleKind;
};

export type SecurityPageConfigInput = {
  id_file_image?: InputMaybe<Scalars['String']>;
  image_position?: InputMaybe<ImagePositionKind>;
  style?: InputMaybe<SecurityPageStyleKind>;
};

export enum SecurityPageStyleKind {
  Cover = 'COVER',
  Simple = 'SIMPLE'
}

/** Filter operators for InputKind.Select */
export type SelectFilter = {
  /** Checks if the value is equal against provided value. */
  equals?: InputMaybe<Scalars['String']>;
  /** Checks value against null and empty array. If value is set, then it is neither null nor empty array. It is an array containing a value. */
  is_set?: InputMaybe<Scalars['Boolean']>;
  /** Checks if the value is not equal against provided value. */
  not?: InputMaybe<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  pk_production?: Maybe<Scalars['String']>;
  pk_sandbox?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type SharedObjectFilter = {
  tech_name?: InputMaybe<Scalars['String']>;
};

export type ShoppingCart = {
  __typename?: 'ShoppingCart';
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  chargeable?: Maybe<Chargeable>;
  created_at: Scalars['DateTimeISO'];
  /** @deprecated Not used any more, use request -> fields instead */
  fields: Array<Field>;
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  items: Array<ShoppingCartItemForConsumer>;
  prepared_at?: Maybe<Scalars['DateTimeISO']>;
  request: ShoppingCartRequest;
  state?: Maybe<TransactionState>;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
  type: TransactionType;
};

export type ShoppingCartFilter = {
  transaction_tech_name: Scalars['String'];
};

export type ShoppingCartForBo = {
  __typename?: 'ShoppingCartForBO';
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  chargeable?: Maybe<Chargeable>;
  created_at: Scalars['DateTimeISO'];
  /** @deprecated Not used any more, use request -> requester_participant  instead */
  creator: Participant;
  /** @deprecated Not used any more, use request -> fields instead */
  fields: Array<Field>;
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  items: Array<ShoppingCartItemForBo>;
  orders: Array<Order>;
  prepared_at?: Maybe<Scalars['DateTimeISO']>;
  request: ShoppingCartRequest;
  state?: Maybe<TransactionState>;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
  type: TransactionType;
};

export type ShoppingCartItemForBo = {
  __typename?: 'ShoppingCartItemForBO';
  backoffice_approved_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_rejected_at?: Maybe<Scalars['DateTimeISO']>;
  canceled_at?: Maybe<Scalars['DateTimeISO']>;
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  closed_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_declined_at?: Maybe<Scalars['DateTimeISO']>;
  created_at: Scalars['DateTimeISO'];
  fields: Array<Field>;
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  id_transaction?: Maybe<Scalars['ID']>;
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  paid_at?: Maybe<Scalars['DateTimeISO']>;
  price?: Maybe<SupplyPrice>;
  provider: Participant;
  provider_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  provider_declined_at?: Maybe<Scalars['DateTimeISO']>;
  state?: Maybe<TransactionState>;
  status: MatchStatusKind;
  supply: Supply;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
};

export type ShoppingCartItemForConsumer = {
  __typename?: 'ShoppingCartItemForConsumer';
  backoffice_approved_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_rejected_at?: Maybe<Scalars['DateTimeISO']>;
  canceled_at?: Maybe<Scalars['DateTimeISO']>;
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  closed_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_declined_at?: Maybe<Scalars['DateTimeISO']>;
  created_at: Scalars['DateTimeISO'];
  fields: Array<Field>;
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  id_provider: Scalars['ID'];
  id_supply: Scalars['ID'];
  id_transaction?: Maybe<Scalars['ID']>;
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  paid_at?: Maybe<Scalars['DateTimeISO']>;
  price?: Maybe<SupplyPrice>;
  provider_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  provider_declined_at?: Maybe<Scalars['DateTimeISO']>;
  provider_fields: Array<Field>;
  state?: Maybe<TransactionState>;
  status: MatchStatusKind;
  supply_fields: Array<Field>;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
};

export type ShoppingCartItemForProvider = {
  __typename?: 'ShoppingCartItemForProvider';
  backoffice_approved_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_rejected_at?: Maybe<Scalars['DateTimeISO']>;
  canceled_at?: Maybe<Scalars['DateTimeISO']>;
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  closed_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_declined_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_fields: Array<Field>;
  created_at: Scalars['DateTimeISO'];
  fields: Array<Field>;
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  id_transaction?: Maybe<Scalars['ID']>;
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  paid_at?: Maybe<Scalars['DateTimeISO']>;
  price?: Maybe<SupplyPrice>;
  provider_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  provider_declined_at?: Maybe<Scalars['DateTimeISO']>;
  state?: Maybe<TransactionState>;
  status: MatchStatusKind;
  supply_fields: Array<Field>;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
};

export type ShoppingCartLight = {
  __typename?: 'ShoppingCartLight';
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  chargeable?: Maybe<Chargeable>;
  created_at: Scalars['DateTimeISO'];
  /** @deprecated Not used any more, use request -> fields instead */
  fields: Array<Field>;
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  /** @deprecated Not used any more, use request -> requester_participant -> id instead */
  id_creator: Scalars['ID'];
  id_transaction_type: Scalars['ID'];
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  prepared_at?: Maybe<Scalars['DateTimeISO']>;
  request: ShoppingCartRequest;
  state?: Maybe<TransactionStateLight>;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
  transaction_tech_name: Scalars['String'];
};

export type ShoppingCartOrder = {
  __typename?: 'ShoppingCartOrder';
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  created_at: Scalars['DateTimeISO'];
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  items: Array<ShoppingCartItemForProvider>;
  order?: Maybe<Order>;
  prepared_at?: Maybe<Scalars['DateTimeISO']>;
  request: ShoppingCartRequestForProvider;
  state?: Maybe<TransactionState>;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
  type: TransactionType;
};

export type ShoppingCartOrderFilter = {
  transaction_tech_name: Scalars['String'];
};

export type ShoppingCartOrderLight = {
  __typename?: 'ShoppingCartOrderLight';
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  created_at: Scalars['DateTimeISO'];
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  id_transaction_type: Scalars['ID'];
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  order?: Maybe<Order>;
  prepared_at?: Maybe<Scalars['DateTimeISO']>;
  request: ShoppingCartRequestForProvider;
  state?: Maybe<TransactionStateLight>;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
  transaction_tech_name: Scalars['String'];
};

export type ShoppingCartRequest = {
  __typename?: 'ShoppingCartRequest';
  fields: Array<Field>;
  requester: User;
  requester_participant: Participant;
};

export type ShoppingCartRequestForProvider = {
  __typename?: 'ShoppingCartRequestForProvider';
  fields: Array<Field>;
  requester: User;
  requester_participant: Participant;
};

export type SignInPageConfig = {
  __typename?: 'SignInPageConfig';
  description?: Maybe<Scalars['String']>;
  eyebrow?: Maybe<Scalars['String']>;
  form?: Maybe<SignInPageFormConfig>;
  image_position?: Maybe<ImagePositionKind>;
  image_url?: Maybe<Scalars['String']>;
  style?: Maybe<SecurityPageStyleKind>;
  title?: Maybe<Scalars['String']>;
};

export type SignInPageConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  eyebrow?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<SignInPageFormConfigInput>;
  id_file_image?: InputMaybe<Scalars['String']>;
  image_position?: InputMaybe<ImagePositionKind>;
  style?: InputMaybe<SecurityPageStyleKind>;
  title?: InputMaybe<Scalars['String']>;
};

export type SignInPageFormConfig = {
  __typename?: 'SignInPageFormConfig';
  button_submit?: Maybe<FrontofficeFormFieldConfig>;
  field_email?: Maybe<FrontofficeFormFieldConfig>;
  field_password?: Maybe<FrontofficeFormFieldConfig>;
  redirect_to_sign_up?: Maybe<FrontofficeRedirectToTextConfig>;
};

export type SignInPageFormConfigInput = {
  button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_email?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_password?: InputMaybe<FrontofficeFormFieldConfigInput>;
  redirect_to_sign_up?: InputMaybe<FrontofficeRedirectToTextConfigInput>;
};

export type SignUpFormConfig = {
  __typename?: 'SignUpFormConfig';
  button_submit?: Maybe<FrontofficeFormFieldConfig>;
  field_email?: Maybe<FrontofficeFormFieldConfig>;
  field_first_name?: Maybe<FrontofficeFormFieldConfig>;
  field_last_name?: Maybe<FrontofficeFormFieldConfig>;
  field_participant_type?: Maybe<FrontofficeParticipantTypeFieldConfig>;
  redirect_to_sign_in?: Maybe<FrontofficeRedirectToTextConfig>;
};

export type SignUpFormConfigInput = {
  button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_email?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_first_name?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_last_name?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_participant_type?: InputMaybe<FrontofficeParticipantTypeFieldConfigInput>;
  redirect_to_sign_in?: InputMaybe<FrontofficeRedirectToTextConfigInput>;
};

export type SignUpFormSubmittedView = {
  __typename?: 'SignUpFormSubmittedView';
  description?: Maybe<Scalars['String']>;
  eyebrow?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SignUpFormSubmittedViewInput = {
  description?: InputMaybe<Scalars['String']>;
  eyebrow?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SignUpPageUiConfig = {
  __typename?: 'SignUpPageUIConfig';
  description?: Maybe<Scalars['String']>;
  eyebrow?: Maybe<Scalars['String']>;
  form?: Maybe<SignUpFormConfig>;
  form_submitted_view?: Maybe<SignUpFormSubmittedView>;
  image_position?: Maybe<ImagePositionKind>;
  image_url?: Maybe<Scalars['String']>;
  style?: Maybe<SecurityPageStyleKind>;
  title?: Maybe<Scalars['String']>;
};

export type SignUpPageUiConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  eyebrow?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<SignUpFormConfigInput>;
  form_submitted_view?: InputMaybe<SignUpFormSubmittedViewInput>;
  id_file_image?: InputMaybe<Scalars['String']>;
  image_position?: InputMaybe<ImagePositionKind>;
  style?: InputMaybe<SecurityPageStyleKind>;
  title?: InputMaybe<Scalars['String']>;
};

export type SimpleField = {
  __typename?: 'SimpleField';
  current_accesses: FieldAccessInfo;
  field_type: FieldType;
  id: Scalars['ID'];
  my_access?: Maybe<FieldAccessKind>;
  value?: Maybe<Scalars['JSON']>;
};

export type SimpleStockAvailabilityData = {
  __typename?: 'SimpleStockAvailabilityData';
  remaining_qty: Scalars['Int'];
};

export type SingleDirectTransactionFilter = {
  state_tech_names?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<FlowStatusFilter>;
  transaction_tech_name: Scalars['String'];
};

export type SingleDirectTransactionFilterForBo = {
  id_transaction_type: Scalars['ID'];
  status?: InputMaybe<FlowStatusFilterForBo>;
};

export type SingleDirectTransactionForBo = {
  __typename?: 'SingleDirectTransactionForBO';
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  created_at: Scalars['DateTimeISO'];
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  match?: Maybe<DirectMatchForBo>;
  prepared_at?: Maybe<Scalars['DateTimeISO']>;
  request: DirectMatchRequest;
  state: FlowState;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
  type: TransactionType;
};

export type SingleDirectTransactionForBoConnection = {
  __typename?: 'SingleDirectTransactionForBOConnection';
  edges: Array<SingleDirectTransactionForBoEdge>;
  page_info: PageInfo;
  total_count: Scalars['Int'];
};

export type SingleDirectTransactionForBoEdge = {
  __typename?: 'SingleDirectTransactionForBOEdge';
  cursor: Scalars['String'];
  node: SingleDirectTransactionForBo;
};

export type SingleDirectTransactionForConsumer = {
  __typename?: 'SingleDirectTransactionForConsumer';
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  chargeable?: Maybe<Chargeable>;
  created_at: Scalars['DateTimeISO'];
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  match?: Maybe<DirectMatchForConsumer>;
  prepared_at?: Maybe<Scalars['DateTimeISO']>;
  request: DirectMatchRequest;
  state: FlowState;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
  type: TransactionType;
};

export type SingleDirectTransactionForConsumerConnection = {
  __typename?: 'SingleDirectTransactionForConsumerConnection';
  edges: Array<SingleDirectTransactionForConsumerEdge>;
  page_info: PageInfo;
  total_count: Scalars['Int'];
};

export type SingleDirectTransactionForConsumerEdge = {
  __typename?: 'SingleDirectTransactionForConsumerEdge';
  cursor: Scalars['String'];
  node: SingleDirectTransactionForConsumer;
};

export type SingleDirectTransactionForProvider = {
  __typename?: 'SingleDirectTransactionForProvider';
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  created_at: Scalars['DateTimeISO'];
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  match: DirectMatchForProvider;
  prepared_at?: Maybe<Scalars['DateTimeISO']>;
  request: DirectMatchRequestForProvider;
  state: FlowState;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
  type: TransactionType;
};

export type SingleDirectTransactionForProviderConnection = {
  __typename?: 'SingleDirectTransactionForProviderConnection';
  edges: Array<SingleDirectTransactionForProviderEdge>;
  page_info: PageInfo;
  total_count: Scalars['Int'];
};

export type SingleDirectTransactionForProviderEdge = {
  __typename?: 'SingleDirectTransactionForProviderEdge';
  cursor: Scalars['String'];
  node: SingleDirectTransactionForProvider;
};

export type StateType = {
  __typename?: 'StateType';
  actions: Array<ActionType>;
  id: Scalars['ID'];
  is_initial: Scalars['Boolean'];
  name: Scalars['String'];
  tech_name: Scalars['String'];
  ui_config: Scalars['JSONObject'];
};

export type Supply = {
  __typename?: 'Supply';
  approved_at?: Maybe<Scalars['DateTimeISO']>;
  availability?: Maybe<Scalars['JSONObject']>;
  collections: Array<Scalars['ID']>;
  created_at: Scalars['DateTimeISO'];
  created_by?: Maybe<User>;
  fields: Array<Field>;
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  onboarded_at?: Maybe<Scalars['DateTimeISO']>;
  onboarding_progress?: Maybe<FlowState>;
  provider: Participant;
  rejected_at?: Maybe<Scalars['DateTimeISO']>;
  status: SupplyStatusKind;
  type: SupplyType;
  user_actions: Array<UserAction>;
  variant_group?: Maybe<Scalars['String']>;
  variants?: Maybe<Array<SupplyVariant>>;
};

export type SupplyCollection = {
  __typename?: 'SupplyCollection';
  created_at: Scalars['DateTimeISO'];
  id: Scalars['ID'];
  name: Scalars['String'];
  supplies_count: Scalars['Int'];
};

export type SupplyConnection = {
  __typename?: 'SupplyConnection';
  edges: Array<SupplyEdge>;
  page_info: PageInfo;
  total_count: Scalars['Int'];
};

export type SupplyDiscoverFilter = {
  fields?: InputMaybe<FieldFilters>;
  ids_collections?: InputMaybe<Array<Scalars['ID']>>;
  ids_providers?: InputMaybe<Array<Scalars['ID']>>;
  provider_fields?: InputMaybe<FieldFilters>;
};

/** DEPRECATED */
export type SupplyDiscoveryFilter = {
  collections?: InputMaybe<Array<Scalars['ID']>>;
  fields?: InputMaybe<Array<FieldFilterInput>>;
  provider_fields?: InputMaybe<Array<FieldFilterInput>>;
  providers?: InputMaybe<Array<Scalars['ID']>>;
};

export type SupplyEdge = {
  __typename?: 'SupplyEdge';
  cursor: Scalars['String'];
  node: Supply;
};

export type SupplyFilter = {
  ids_collections?: InputMaybe<Array<Scalars['ID']>>;
  ids_provider?: InputMaybe<Array<Scalars['ID']>>;
  ids_supply_type?: InputMaybe<Array<Scalars['ID']>>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  pending_backoffice_actions?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<SupplyStatusKind>>;
};

export type SupplyPrice = {
  __typename?: 'SupplyPrice';
  addon_items: Array<AddonItem>;
  currency: CurrencyKind;
  discount_items: Array<DiscountItem>;
  platform_fee_items: Array<PlatformFeeItem>;
  qty: Scalars['Int'];
  tax_items: Array<TaxItem>;
  total_before_tax: Scalars['Int'];
  total_consumer_fees: Scalars['Int'];
  total_fees: Scalars['Int'];
  total_provider_fees: Scalars['Int'];
  total_taxes: Scalars['Int'];
  unit_price: Scalars['Int'];
  unit_price_description?: Maybe<Scalars['String']>;
};

export enum SupplyStatusKind {
  Draft = 'DRAFT',
  Onboarded = 'ONBOARDED',
  Onboarding = 'ONBOARDING',
  Rejected = 'REJECTED'
}

export type SupplyType = {
  __typename?: 'SupplyType';
  allow_variant_duplicates: Scalars['Boolean'];
  auto_activation: Scalars['Boolean'];
  auto_backoffice_approval?: Maybe<Scalars['Boolean']>;
  auto_variants_onboarding: Scalars['Boolean'];
  bulk_import_enabled: Scalars['Boolean'];
  data_import_templates: Array<ImportSupplyDataTemplate>;
  dynamic_fields_enabled: Scalars['Boolean'];
  dynamic_variants_enabled: Scalars['Boolean'];
  fields: Array<FieldType>;
  flow_type: FlowType;
  id: Scalars['ID'];
  match_configurations: Array<MatchConfiguration>;
  name: Scalars['String'];
  provider_type: ParticipantType;
  tech_name: Scalars['String'];
  ui_config: Scalars['JSONObject'];
};

export type SupplyVariant = {
  __typename?: 'SupplyVariant';
  approved_at?: Maybe<Scalars['DateTimeISO']>;
  availability?: Maybe<Scalars['JSONObject']>;
  created_at: Scalars['DateTimeISO'];
  created_by?: Maybe<User>;
  fields: Array<Field>;
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  onboarded_at?: Maybe<Scalars['DateTimeISO']>;
  onboarding_progress?: Maybe<FlowState>;
  rejected_at?: Maybe<Scalars['DateTimeISO']>;
  status: SupplyStatusKind;
  user_actions: Array<UserAction>;
  variant_group?: Maybe<Scalars['String']>;
};

export type TargetScopeObject = {
  id_argument: Scalars['String'];
  type: Scalars['String'];
};

export enum TaskExecutionKind {
  Default = 'DEFAULT',
  External = 'EXTERNAL'
}

export type Tax = {
  __typename?: 'Tax';
  description: Scalars['String'];
  guard_expression: Scalars['String'];
  is_merchant_level?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  value_expression: Scalars['String'];
};

export type TaxInput = {
  description: Scalars['String'];
  guard_expression: Scalars['String'];
  is_merchant_level?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tech_name: Scalars['String'];
  value_expression: Scalars['String'];
};

export type TaxItem = {
  __typename?: 'TaxItem';
  description: Scalars['String'];
  value: Scalars['Int'];
};

/** Filter operators for a string value. Among others, used by InputKind.Text and InputKind.SmartText. */
export type TextFilter = {
  /** Checks if the value contains is greater than or equal. */
  contains?: InputMaybe<Scalars['String']>;
  /** Checks if the value ends with. */
  ends_with?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  /** Checks value against null. If value is set, then it is not null. */
  is_set?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<Scalars['String']>;
  /** Checks if the value starts with. */
  starts_with?: InputMaybe<Scalars['String']>;
};

/** Filter operators for a set of string values. Among others, used by InputKind.TextSet and InputKind.SmartTextSet. */
export type TextSetFilter = {
  /** Checks value against null and empty array. If value is set, then it is neither null nor empty array. It is an array containing at least one value. */
  is_set?: InputMaybe<Scalars['Boolean']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  chargeable?: Maybe<Chargeable>;
  created_at: Scalars['DateTimeISO'];
  forcedly_terminated: Scalars['Boolean'];
  id: Scalars['ID'];
  id_parent_transaction?: Maybe<Scalars['ID']>;
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  matches?: Maybe<Array<TransactionMatch>>;
  my_role: MatchTypeRoleKind;
  order: TransactionOrder;
  orders: Array<Order>;
  payments: Array<Payment>;
  prepared_at?: Maybe<Scalars['DateTimeISO']>;
  /** @deprecated This prop will be removed. Use order and match instead */
  root_object: TransactionRootObject;
  state?: Maybe<TransactionState>;
  sub_transactions: Array<TransactionLight>;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
  type: TransactionType;
  unlocked_for: Array<User>;
};

export type TransactionFilter = {
  id_transaction_type?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TransactionStatusFilter>;
};

export enum TransactionKind {
  ForwardAuction = 'FORWARD_AUCTION',
  MultipleDirect = 'MULTIPLE_DIRECT',
  ReverseAuction = 'REVERSE_AUCTION',
  SingleDirect = 'SINGLE_DIRECT',
  SubTransaction = 'SUB_TRANSACTION'
}

export type TransactionLight = {
  __typename?: 'TransactionLight';
  cancelled_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_fields?: Maybe<Array<Field>>;
  created_at: Scalars['DateTimeISO'];
  creator: Participant;
  forcedly_terminated?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  id_parent_transaction?: Maybe<Scalars['ID']>;
  initiated_at?: Maybe<Scalars['DateTimeISO']>;
  match_fields?: Maybe<Array<Field>>;
  my_role: MatchTypeRoleKind;
  order_fields?: Maybe<Array<Field>>;
  prepared_at?: Maybe<Scalars['DateTimeISO']>;
  provider_fields?: Maybe<Array<Field>>;
  state?: Maybe<TransactionStateLight>;
  sub_transactions_count: Scalars['Int'];
  supply_fields?: Maybe<Array<Field>>;
  terminated_at?: Maybe<Scalars['DateTimeISO']>;
  type: TransactionType;
};

export type TransactionMatch = {
  __typename?: 'TransactionMatch';
  /** @deprecated This att is not used any more. Use fields instead */
  availability?: Maybe<AvailabilityRecord>;
  backoffice_approved_at?: Maybe<Scalars['DateTimeISO']>;
  backoffice_rejected_at?: Maybe<Scalars['DateTimeISO']>;
  closed_at?: Maybe<Scalars['DateTimeISO']>;
  consumer: Participant;
  consumer_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_declined_at?: Maybe<Scalars['DateTimeISO']>;
  consumer_fields: Array<Field>;
  consumer_unlocked_to_provider?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['DateTimeISO'];
  created_by?: Maybe<User>;
  fields: Array<Field>;
  id: Scalars['ID'];
  matching_score?: Maybe<Scalars['Int']>;
  price?: Maybe<SupplyPrice>;
  price_override_unlocked?: Maybe<Scalars['Boolean']>;
  provider: Participant;
  provider_accepted_at?: Maybe<Scalars['DateTimeISO']>;
  provider_declined_at?: Maybe<Scalars['DateTimeISO']>;
  provider_fields: Array<Field>;
  provider_unlocked_to_consumer?: Maybe<Scalars['Boolean']>;
  status: MatchStatusKind;
  supply: Supply;
  supply_fields: Array<Field>;
  supply_unlocked_to_consumer?: Maybe<Scalars['Boolean']>;
  type: MatchType;
  unlocked_consumer_fields: Array<Scalars['ID']>;
  unlocked_provider_fields: Array<Scalars['ID']>;
  unlocked_supply_fields: Array<Scalars['ID']>;
  unlocked_to_consumer: Scalars['Boolean'];
  unlocked_to_provider: Scalars['Boolean'];
};

/** DEPRECATED */
export type TransactionOrder = {
  __typename?: 'TransactionOrder';
  auctioned_supply: Array<Supply>;
  /** @deprecated This att is not used any more. Use fields instead */
  availability?: Maybe<AvailabilityRecord>;
  consumer_filter?: Maybe<Array<FieldFilter>>;
  creator: Participant;
  fields: Array<Maybe<Field>>;
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  last_execution_at?: Maybe<Scalars['DateTimeISO']>;
  provider_filter?: Maybe<Array<FieldFilter>>;
  supply_filter?: Maybe<Array<FieldFilter>>;
  type: MatchingToolType;
};

export type TransactionRootObject = Match | MatchingTool;

export type TransactionRootObjectType = MatchType | MatchingToolType;

export type TransactionState = {
  __typename?: 'TransactionState';
  message?: Maybe<Scalars['String']>;
  next_steps?: Maybe<Array<NextStep>>;
  status?: Maybe<Scalars['String']>;
  status_tech_name?: Maybe<Scalars['String']>;
};

export type TransactionStateLight = {
  __typename?: 'TransactionStateLight';
  status?: Maybe<Scalars['String']>;
  status_tech_name?: Maybe<Scalars['String']>;
};

export enum TransactionStatusFilter {
  Draft = 'DRAFT',
  Ongoing = 'ONGOING',
  PendingBackofficeActions = 'PENDING_BACKOFFICE_ACTIONS',
  Terminated = 'TERMINATED'
}

export enum TransactionStatusKind {
  Checkout = 'CHECKOUT',
  Declined = 'DECLINED',
  Failed = 'FAILED',
  Ongoing = 'ONGOING',
  Paid = 'PAID',
  Rejected = 'REJECTED'
}

export type TransactionType = {
  __typename?: 'TransactionType';
  cascading_termination?: Maybe<Scalars['Boolean']>;
  connection_scoped_discovery: Array<Scalars['ID']>;
  flow_type?: Maybe<FlowType>;
  forced_termination_enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  id_parent_transaction_type?: Maybe<Scalars['ID']>;
  is_active: Scalars['Boolean'];
  is_default: Scalars['Boolean'];
  is_multi_provider_enabled: Scalars['Boolean'];
  match_configuration?: Maybe<MatchConfiguration>;
  my_role: MatchTypeRoleKind;
  name: Scalars['String'];
  order_configuration: OrderConfiguration;
  payment_types: Array<PaymentType>;
  retired_at?: Maybe<Scalars['DateTimeISO']>;
  root_type: TransactionRootObjectType;
  sub_flow_type?: Maybe<FlowType>;
  tech_name: Scalars['String'];
  type: TransactionKind;
  ui_config: Scalars['JSONObject'];
};

export type Transfer = {
  __typename?: 'Transfer';
  amount: MonetaryValue;
  created_at: Scalars['DateTimeISO'];
  destination?: Maybe<Scalars['JSON']>;
  error?: Maybe<Scalars['JSON']>;
  failed_at?: Maybe<Scalars['DateTimeISO']>;
  flagged_as_succeeded: Scalars['Boolean'];
  id: Scalars['ID'];
  id_transfer_origin?: Maybe<Scalars['ID']>;
  order: Order;
  status: TransferStatusKind;
  succeeded_at?: Maybe<Scalars['DateTimeISO']>;
};

export type TransferConnection = {
  __typename?: 'TransferConnection';
  edges: Array<TransferEdge>;
  page_info: PageInfo;
  total_count: Scalars['Int'];
};

export type TransferEdge = {
  __typename?: 'TransferEdge';
  cursor: Scalars['String'];
  node: Transfer;
};

export type TransferFilter = {
  status?: InputMaybe<TransferStatusKind>;
};

export type TransferForBo = {
  __typename?: 'TransferForBo';
  amount: MonetaryValue;
  created_at: Scalars['DateTimeISO'];
  destination?: Maybe<Scalars['JSON']>;
  error?: Maybe<Scalars['JSON']>;
  failed_at?: Maybe<Scalars['DateTimeISO']>;
  flagged_as_succeeded: Scalars['Boolean'];
  flagged_as_succeeded_by?: Maybe<BackofficeUser>;
  id: Scalars['ID'];
  id_payment: Scalars['ID'];
  id_transfer_origin?: Maybe<Scalars['ID']>;
  order: Order;
  recipient_participant: Participant;
  status: TransferStatusKind;
  succeeded_at?: Maybe<Scalars['DateTimeISO']>;
};

export enum TransferStatusKind {
  Created = 'CREATED',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED'
}

export type TransitionType = {
  __typename?: 'TransitionType';
  actions?: Maybe<Array<ActionType>>;
  from?: Maybe<StateType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  tech_name: Scalars['String'];
  to?: Maybe<StateType>;
  triggering_conditions?: Maybe<Array<FlowCondition>>;
  triggering_event: RandevuEventKind;
  ui_config: Scalars['JSONObject'];
};

export type UniqueSellingPointsSectionConfig = {
  __typename?: 'UniqueSellingPointsSectionConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  image_1_url?: Maybe<Scalars['String']>;
  image_2_url?: Maybe<Scalars['String']>;
  image_3_url?: Maybe<Scalars['String']>;
  text_1_description?: Maybe<Scalars['String']>;
  text_1_subtitle?: Maybe<Scalars['String']>;
  text_1_title?: Maybe<Scalars['String']>;
  text_2_description?: Maybe<Scalars['String']>;
  text_2_subtitle?: Maybe<Scalars['String']>;
  text_2_title?: Maybe<Scalars['String']>;
  text_3_description?: Maybe<Scalars['String']>;
  text_3_subtitle?: Maybe<Scalars['String']>;
  text_3_title?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UniqueSellingPointsSectionConfigInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id_file_image_1?: InputMaybe<Scalars['String']>;
  id_file_image_2?: InputMaybe<Scalars['String']>;
  id_file_image_3?: InputMaybe<Scalars['String']>;
  text_1_description?: InputMaybe<Scalars['String']>;
  text_1_subtitle?: InputMaybe<Scalars['String']>;
  text_1_title?: InputMaybe<Scalars['String']>;
  text_2_description?: InputMaybe<Scalars['String']>;
  text_2_subtitle?: InputMaybe<Scalars['String']>;
  text_2_title?: InputMaybe<Scalars['String']>;
  text_3_description?: InputMaybe<Scalars['String']>;
  text_3_subtitle?: InputMaybe<Scalars['String']>;
  text_3_title?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Users that belong to a participant (participant user), i.e. users that are customers of your platform business. */
export type User = {
  __typename?: 'User';
  closed_at?: Maybe<Scalars['DateTimeISO']>;
  created_at: Scalars['DateTimeISO'];
  email: Scalars['String'];
  email_verified_at?: Maybe<Scalars['DateTimeISO']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invited_by?: Maybe<Scalars['ID']>;
  is_test_user: Scalars['Boolean'];
  last_logged_in_at?: Maybe<Scalars['DateTimeISO']>;
  last_name?: Maybe<Scalars['String']>;
  password_updated_at?: Maybe<Scalars['DateTimeISO']>;
};

export type UserAction = {
  __typename?: 'UserAction';
  payload?: Maybe<Scalars['JSONObject']>;
  reason: Scalars['String'];
  type: UserActionKind;
};

export enum UserActionKind {
  FieldValueRequired = 'FIELD_VALUE_REQUIRED'
}

export type UserFilter = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
};

export enum UserRoleKind {
  ApiUser = 'API_USER',
  MarketplaceAdmin = 'MARKETPLACE_ADMIN',
  MarketplaceOperator = 'MARKETPLACE_OPERATOR',
  MarketplaceOwner = 'MARKETPLACE_OWNER',
  MarketplaceUser = 'MARKETPLACE_USER'
}

export type VerifyParticipantAccountPageConfig = {
  __typename?: 'VerifyParticipantAccountPageConfig';
  description?: Maybe<Scalars['String']>;
  form?: Maybe<VerifyParticipantAccountPageFormConfig>;
  image_position?: Maybe<ImagePositionKind>;
  image_url?: Maybe<Scalars['String']>;
  style?: Maybe<SecurityPageStyleKind>;
  title?: Maybe<Scalars['String']>;
};

export type VerifyParticipantAccountPageConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<VerifyParticipantAccountPageFormConfigInput>;
  id_file_image?: InputMaybe<Scalars['String']>;
  image_position?: InputMaybe<ImagePositionKind>;
  style?: InputMaybe<SecurityPageStyleKind>;
  title?: InputMaybe<Scalars['String']>;
};

export type VerifyParticipantAccountPageFormConfig = {
  __typename?: 'VerifyParticipantAccountPageFormConfig';
  button_submit?: Maybe<FrontofficeFormFieldConfig>;
  field_password?: Maybe<FrontofficeFormFieldConfig>;
};

export type VerifyParticipantAccountPageFormConfigInput = {
  button_submit?: InputMaybe<FrontofficeFormFieldConfigInput>;
  field_password?: InputMaybe<FrontofficeFormFieldConfigInput>;
};

export type Webhook = {
  __typename?: 'Webhook';
  event: Event;
  id: Scalars['ID'];
  is_success?: Maybe<Scalars['Boolean']>;
  replied_at?: Maybe<Scalars['DateTimeISO']>;
  request: Scalars['JSONObject'];
  response?: Maybe<Scalars['JSONObject']>;
  sent_at: Scalars['DateTimeISO'];
  type: NotificationType;
};

export type WebhookFilter = {
  event_code?: InputMaybe<RandevuEventKind>;
  id_notification_type?: InputMaybe<Scalars['ID']>;
  is_success?: InputMaybe<Scalars['Boolean']>;
};
